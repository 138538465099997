import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Flowbite } from 'flowbite-react';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Dashboard from './components/dashboard/Dashboard';
import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import Logout from './components/auth/Logout';
import MainContent from './components/MainContent';
import ToolTemplate from './components/dashboard/tools/toolTemplate';
import TermsAndConditions from './components/common/TermsAndConditions';
import DataPrivacy from './components/common/DataPrivacy';
import Workflows from './components/dashboard/Workflows';
import Tools from './components/dashboard/Tools';
import Marketplace from './components/dashboard/Marketplace';
import Profile from './components/dashboard/Profile';
import Settings from './components/dashboard/Settings';
import Overview from './components/dashboard/Overview';
import AboutUsPage from './components/AboutUsPage'; // Import the new AboutUsPage component
import { ToolsProvider } from './context/ToolsContext';
import { WorkflowsProvider } from './context/WorkflowsContext';
import { ContextsProvider } from './context/ContextsContext';
import { SessionLogsProvider } from './context/SessionLogsContext';
import FeedbackButton from './components/common/FeedbackButton';
import { customTheme } from './customTheme';
import BlogRoutes from './components/blog/BlogRoutes';
import { BlogProvider } from './context/BlogContext';
import UsageHistoryDashboard from './components/dashboard/UsageHistoryDashboard';


const FeedbackWrapper = ({ children }) => {
  const location = useLocation();
  const showFeedback = location.pathname !== '/';

  return (
    <>
      {children}
      {showFeedback && <FeedbackButton />}
    </>
  );
};

function App() {
  return (
    <Router>
       <SessionLogsProvider>
      <BlogProvider>
        <Flowbite theme={customTheme}>
          <ContextsProvider>
            <ToolsProvider>
              <WorkflowsProvider>
                <FeedbackWrapper>
                  <div className="flex flex-col min-h-screen w-full">
                    <Header />
                    <div className="flex-grow mt-16 w-full">
                      <Routes>
                        <Route path="/" element={<MainContent />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="/data-privacy" element={<DataPrivacy />} />
                        <Route path="/about" element={<AboutUsPage />} /> {/* New route for About Us page */}
                        <Route path="/dashboard/tools/:toolName" element={<ToolTemplate />} />
                        
                        <Route path="/dashboard" element={<Dashboard />}>
                          <Route path="usage-history" element={<UsageHistoryDashboard />} />
                          <Route path="overview" element={<Overview />} />
                          <Route path="tools" element={<Tools />} />
                          <Route path="tools/:toolName" element={<ToolTemplate />} />
                          <Route path="workflows" element={<Workflows />} />
                          <Route path="marketplace" element={<Marketplace />} />
                          <Route path="profile" element={<Profile />} />
                          <Route path="settings" element={<Settings />} />
                        </Route>
                        <Route path="/logout" element={<Logout />} />
                        <Route path="*" element={<Navigate to="/" />} />
                        <Route path="/blog/*" element={<BlogRoutes />} />
                      </Routes>
                    </div>
                    <Footer />
                  </div>
                </FeedbackWrapper>
              </WorkflowsProvider>
            </ToolsProvider>
          </ContextsProvider>
        </Flowbite>
      </BlogProvider>
      </SessionLogsProvider>
    </Router>
  );
}

export default App;