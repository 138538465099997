import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useBlogContext } from '../../context/BlogContext';
import { FaCalendar, FaEye, FaHeart, FaTags } from 'react-icons/fa';

const BlogList = () => {
  const { posts, loading, error } = useBlogContext();

  if (loading) return <div className="container mx-auto px-4 py-8">Loading...</div>;
  if (error) return <div className="container mx-auto px-4 py-8">Error: {error}</div>;

  // Handle the case where posts is an empty array
  if (posts.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-center">LLMazing Blog</h1>
        <p className="text-center text-gray-600">No blog posts available at the moment. Check back soon!</p>
      </div>
    );
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const featuredPost = posts.length > 0 ? posts[0] : null;
  const regularPosts = posts.slice(1);

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Blog | LLMazing</title>
        <meta name="description" content="Explore the latest insights, tips, and news on AI, productivity, and technology on the LLMazing blog." />
      </Helmet>

      <h1 className="text-4xl font-bold mb-8 text-center">LLMazing Blog</h1>

      {featuredPost && (
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Featured Post</h2>
          <Link to={`/blog/${featuredPost.slug}`} className="block">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
              {featuredPost.image && (
                <img src={featuredPost.image} alt={featuredPost.title} className="w-full h-64 object-cover" />
              )}
              <div className="p-6">
                <h3 className="text-2xl font-bold mb-2">{featuredPost.title}</h3>
                <p className="text-gray-600 mb-4">{featuredPost.excerpt}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <FaCalendar className="mr-2" />
                  <span className="mr-4">{formatDate(featuredPost.created_at)}</span>
                  <FaEye className="mr-2" />
                  <span className="mr-4">{featuredPost.views} views</span>
                  <FaHeart className="mr-2" />
                  <span>{featuredPost.likes} likes</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      )}

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Latest Posts</h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {regularPosts.map((post) => (
            <Link 
              key={post.post_id} 
              to={`/blog/${post.slug}`} 
              className="block group"
            >
              <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 group-hover:shadow-xl">
                {post.image && (
                  <div className="relative overflow-hidden">
                    <img 
                      src={post.image} 
                      alt={post.title} 
                      className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110" 
                    />
                    {post.category && (
                      <span className="absolute top-2 right-2 bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded">
                        {post.category}
                      </span>
                    )}
                  </div>
                )}
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-600 transition-colors duration-300">{post.title}</h3>
                  <p className="text-gray-600 mb-4">{post.excerpt}</p>
                  <div className="flex items-center text-sm text-gray-500">
                    <FaCalendar className="mr-2" />
                    <span className="mr-4">{formatDate(post.created_at)}</span>
                    <FaEye className="mr-2" />
                    <span>{post.views} views</span>
                  </div>
                  {post.tags && post.tags.length > 0 && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <FaTags className="mr-2" />
                      {post.tags.slice(0, 3).map((tag, index) => (
                        <Link
                          key={index}
                          to={`/blog/tag/${tag}`}
                          className="mr-2 bg-gray-200 px-2 py-1 rounded text-xs hover:bg-gray-300"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {tag}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {posts.length === 0 && (
        <p className="text-center text-gray-600">No blog posts available at the moment. Check back soon!</p>
      )}
    </div>
  );
};

export default BlogList;