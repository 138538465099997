import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Progress, Avatar, Badge, Dropdown } from 'flowbite-react';
import { HiLightningBolt, HiClock, HiChartPie, HiTrendingUp, HiStar, HiCog, HiClipboardList, HiCalendar, HiMail, HiLightBulb, HiPlus } from 'react-icons/hi';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const Overview = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [productivityScore, setProductivityScore] = useState(0);
  const [focusArea, setFocusArea] = useState({});
  const [productivityTrend, setProductivityTrend] = useState([]);
  const [topPriorities, setTopPriorities] = useState([]);
  const [quickInsights, setQuickInsights] = useState([]);
  const [timeBlockingSchedule, setTimeBlockingSchedule] = useState([]);

  useEffect(() => {
    const userInfo = JSON.parse(Cookies.get('user') || '{}');
    setUser(userInfo);
    if (userInfo.email === 'arsen.apostolov@gmail.com') {
      fetchUserData(userInfo.email);
    }
  }, []);

  const fetchUserData = async (email) => {
    // Placeholder: Replace with actual API calls
    setProductivityScore(85);
    setFocusArea({ title: "Complete Project Proposal", progress: 60 });
    setProductivityTrend(generateProductivityTrend());
    setTopPriorities(await fetchTopPriorities(email));
    setQuickInsights(await generateQuickInsights(email));
    setTimeBlockingSchedule(await fetchTimeBlockingSchedule(email));
  };

  const generateProductivityTrend = () => {
    // Placeholder: Generate mock data
    return Array.from({length: 7}, (_, i) => ({
      date: new Date(Date.now() - (6-i) * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { weekday: 'short' }),
      score: 70 + Math.random() * 20
    }));
  };

  const fetchTopPriorities = async (email) => {
    // Placeholder: Replace with actual API call
    return [
      { id: 1, title: "Review project proposal", category: "work" },
      { id: 2, title: "Team sync-up", category: "meeting" },
      { id: 3, title: "30 min workout", category: "personal" },
    ];
  };

  const generateQuickInsights = async (email) => {
    // Placeholder: Replace with actual AI-generated insights
    return [
      "Your focus peaks between 10 AM and 12 PM",
      "You've completed 80% of your weekly goals",
      "Consider taking a break in the next 30 minutes",
    ];
  };

  const fetchTimeBlockingSchedule = async (email) => {
    // Placeholder: Replace with actual API call
    return [
      { id: 1, title: "Deep Work", start: "09:00", end: "11:00", category: "focus" },
      { id: 2, title: "Team Meeting", start: "11:00", end: "12:00", category: "meeting" },
      { id: 3, title: "Lunch Break", start: "12:00", end: "13:00", category: "break" },
    ];
  };


  // ... (keep the other helper functions as they are)

  if (user.email !== 'arsen.apostolov@gmail.com') {
    return (
      // <div className="p-4 bg-gradient-to-br from-purple-900 via-blue-900 to-cyan-700 min-h-screen text-white">
      <div className="p-4 min-h-screen text-white">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <h1 className="text-4xl font-bold mb-6">Welcome to LLMazing UseCases</h1>
          <p className="text-xl mb-8">Get started with our powerful tools and workflows!</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <Button size="xl" color="light" onClick={() => navigate('/dashboard/workflows')}>
              <HiLightningBolt className="mr-2" /> Explore Tools
            </Button>
            <Button size="xl" color="light" onClick={() => navigate('/dashboard/marketplace')}>
              <HiCog className="mr-2" /> Discover Workflows
            </Button>
          </div>
          
          {/* <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
            <h2 className="text-2xl font-bold mb-4">How to Use LLMazing UseCases</h2>
            <div className="aspect-w-16 aspect-h-9">
              <iframe
                src="https://www.youtube.com/embed/3P5c1dgg2T0?si=w1vOjeati4mni1e9"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </Card> */}
        </motion.div>
      </div>
    );
  }

  return (
    // <div className="p-4 bg-gradient-to-br from-purple-900 via-blue-900 to-cyan-700 min-h-screen text-white">
    <div className="p-4 min-h-screen text-white">
      <div className="grid grid-cols-12 gap-4 h-full">
        {/* Header */}
        <div className="col-span-12 flex justify-between items-center mb-4">
          <div>
            <h1 className="text-2xl font-bold">Hello, {user.name}</h1>
            <p className="text-sm text-gray-300">Let's make progress today!</p>
          </div>
          <Avatar img={user.picture} rounded size="lg" />
        </div>

        {/* Productivity Score and Focus Area */}
        <div className="col-span-12 md:col-span-4">
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg h-full">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-semibold">Productivity</h2>
              <Badge color={productivityScore > 80 ? "success" : "warning"}>{productivityScore}</Badge>
            </div>
            <Progress progress={productivityScore} size="lg" color="blue" />
            <div className="mt-4">
              <h3 className="text-sm font-medium">Focus Area</h3>
              <p className="text-xs mb-1">{focusArea.title}</p>
              <Progress progress={focusArea.progress} size="sm" color="green" />
            </div>
          </Card>
        </div>

        {/* Productivity Trend */}
        <div className="col-span-12 md:col-span-5">
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg h-full">
            <h2 className="text-lg font-semibold mb-2">Weekly Trend</h2>
            <ResponsiveContainer width="100%" height={120}>
              <LineChart data={productivityTrend}>
                <XAxis dataKey="date" tick={{fontSize: 10, fill: 'white'}} />
                <YAxis hide={true} />
                <Tooltip contentStyle={{backgroundColor: 'rgba(255, 255, 255, 0.1)', borderColor: 'white'}} />
                <Line type="monotone" dataKey="score" stroke="#3B82F6" strokeWidth={2} dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </div>

        {/* Quick Insights */}
        <div className="col-span-12 md:col-span-3">
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg h-full">
            <h2 className="text-lg font-semibold mb-2">Quick Insights</h2>
            <ul className="space-y-2">
              {quickInsights.map((insight, index) => (
                <li key={index} className="flex items-start">
                  <HiLightBulb className="mt-1 mr-2 text-yellow-500 flex-shrink-0" />
                  <span className="text-xs">{insight}</span>
                </li>
              ))}
            </ul>
          </Card>
        </div>

        {/* Top Priorities */}
        <div className="col-span-12 md:col-span-4">
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg h-full">
            <h2 className="text-lg font-semibold mb-2">Top Priorities</h2>
            <ul className="space-y-2">
              {topPriorities.map(priority => (
                <li key={priority.id} className="flex items-center justify-between">
                  <span className="text-sm">{priority.title}</span>
                  <Badge color={priority.category === "work" ? "blue" : priority.category === "meeting" ? "purple" : "green"}>
                    {priority.category}
                  </Badge>
                </li>
              ))}
            </ul>
            <Button size="xs" className="mt-2">
              <HiPlus className="mr-1" /> Add Priority
            </Button>
          </Card>
        </div>

        {/* Time Blocking Schedule */}
        <div className="col-span-12 md:col-span-5">
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg h-full">
            <h2 className="text-lg font-semibold mb-2">Today's Schedule</h2>
            <ul className="space-y-2">
              {timeBlockingSchedule.map(block => (
                <li key={block.id} className="flex items-center justify-between">
                  <div>
                    <span className="text-sm font-medium">{block.title}</span>
                    <span className="text-xs text-gray-300 ml-2">{block.start} - {block.end}</span>
                  </div>
                  <Badge color={block.category === "focus" ? "blue" : block.category === "meeting" ? "purple" : "green"}>
                    {block.category}
                  </Badge>
                </li>
              ))}
            </ul>
          </Card>
        </div>

        {/* Quick Actions */}
        <div className="col-span-12 md:col-span-3">
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg h-full">
            <h2 className="text-lg font-semibold mb-2">Quick Actions</h2>
            <div className="grid grid-cols-2 gap-2">
              <Button size="sm" color="blue" onClick={() => navigate('/dashboard/tools')}>
                <HiLightningBolt className="mr-1" /> Tools
              </Button>
              <Button size="sm" color="green" onClick={() => navigate('/dashboard/workflows')}>
                <HiCog className="mr-1" /> Workflows
              </Button>
              <Button size="sm" color="purple" onClick={() => navigate('/dashboard/analytics')}>
                <HiChartPie className="mr-1" /> Analytics
              </Button>
              <Button size="sm" color="yellow" onClick={() => navigate('/dashboard/learning')}>
                <HiTrendingUp className="mr-1" /> Learn
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Overview;