import React, { createContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

export const ToolsContext = createContext();

export const ToolsProvider = ({ children }) => {
  const [tools, setTools] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const CACHE_DURATION = 900000; // 15 minutes in milliseconds

  const getCachedData = (key) => {
    const cachedData = sessionStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedData = (key, data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    sessionStorage.setItem(key, JSON.stringify(cacheData));
  };

  const debouncedFetchTools = useRef(
    debounce(async (force = false) => {
      if (dataLoaded && !force) return;

      try {
        setLoading(true);
        const cachedTools = getCachedData('tools');
        const cachedCategories = getCachedData('categories');

        if (!force && cachedTools && cachedCategories) {
          setTools(cachedTools);
          setCategories(cachedCategories);
        } else {
          const response = await axios.get(process.env.REACT_APP_GET_USECASES_URL, {
            auth: {
              username: process.env.REACT_APP_API_USER,
              password: process.env.REACT_APP_API_PASS,
            },
          });
          const sortedTools = response.data.sort((a, b) => b.nb_usages - a.nb_usages);
          const uniqueCategories = [...new Set(sortedTools.map(tool => tool.category))];
          
          setTools(sortedTools);
          setCategories(uniqueCategories);
          
          setCachedData('tools', sortedTools);
          setCachedData('categories', uniqueCategories);
        }
      } catch (error) {
        console.error('Error fetching tools:', error);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (!dataLoaded) {
      debouncedFetchTools();
    }
  }, [dataLoaded, debouncedFetchTools]);

  useEffect(() => {
    const intervalId = setInterval(() => debouncedFetchTools(true), CACHE_DURATION);
    return () => clearInterval(intervalId);
  }, [debouncedFetchTools]);

  useEffect(() => {
    const handleFocus = () => {
      const cachedData = sessionStorage.getItem('tools');
      if (cachedData) {
        const { timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp >= CACHE_DURATION) {
          debouncedFetchTools(true);
        }
      }
    };
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [debouncedFetchTools]);

  const refreshTools = () => {
    setDataLoaded(false);
    debouncedFetchTools(true);
  };

  return (
    <ToolsContext.Provider value={{ tools, categories, loading, refreshTools }}>
      {children}
    </ToolsContext.Provider>
  );
};
