import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const verifyToken = (token) => {
  const decodedToken = jwtDecode(token);
  // console.log('Decoded Token:', decodedToken);

  // Log all keys and values in the decoded token
  for (const key in decodedToken) {
    if (decodedToken.hasOwnProperty(key)) {
      // console.log(`${key}: ${decodedToken[key]}`);
    }
  }

  return {
    email: decodedToken.email,
    name: decodedToken.name,
    picture: decodedToken.picture,
    accessToken: token // Include the access token in the returned object
  };
};

export default verifyToken;