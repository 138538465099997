import React from 'react';
import { Modal, Button } from 'flowbite-react';
import { HiExclamation } from 'react-icons/hi';

const StyleButt = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-bold rounded-md hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
  >
    {children}
  </button>
);

const ValidationModal = ({ isOpen, onClose, issues }) => (
  <Modal
    show={isOpen}
    onClose={onClose}
    size="md"
    className="fixed inset-0 z-50 overflow-y-auto"
  >
    <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Workflow Validation Issues</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">The following issues were found in your workflow:</p>
                <ul className="list-disc pl-5 mt-2 space-y-1">
                  {issues.map((issue, index) => (
                    <li key={index} className="text-sm text-gray-600">{issue}</li>
                  ))}
                </ul>
                <p className="mt-2 text-sm text-gray-500">Please address these issues before executing the workflow.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <StyleButt onClick={onClose}>
            Acknowledge
          </StyleButt>
        </div>
      </div>
    </div>
  </Modal>
);

export default ValidationModal;