import React, { createContext, useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import useCookie from './useCookie';

const SessionLogsContext = createContext();

export const useSessionLogsContext = () => useContext(SessionLogsContext);

export const SessionLogsProvider = ({ children }) => {
  const [sessionLogs, setSessionLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const CACHE_DURATION = 300000; // 5 minutes in milliseconds

  const userInfo = useCookie('user');
  const user = userInfo ? JSON.parse(userInfo) : null;
  const { email } = user || {};

  console.log('SessionLogsProvider initialized. User email:', email);

  const getCachedData = (key) => {
    const cachedData = sessionStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        console.log('Returning cached data for key:', key);
        return data;
      }
    }
    console.log('No valid cached data for key:', key);
    return null;
  };

  const setCachedData = (key, data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    sessionStorage.setItem(key, JSON.stringify(cacheData));
    console.log('Cached data set for key:', key);
  };

  const fetchSessionLogs = useCallback(async (force = false) => {
    console.log('Fetching session logs. Force:', force);
    if (!email) {
      console.log('No user email found. Aborting fetch.');
      setError('User not logged in');
      setLoading(false);
      return;
    }

    if (!force) {
      const cachedLogs = getCachedData('sessionLogs');
      if (cachedLogs) {
        console.log('Using cached session logs');
        setSessionLogs(cachedLogs);
        setLoading(false);
        setDataLoaded(true);
        return;
      }
    }

    try {
      console.log('Making API call to fetch session logs');
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_GET_SESSION_LOGS,
        { email },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PASS,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('API response received:', response.data);
      setSessionLogs(response.data);
      setCachedData('sessionLogs', response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching session logs:', error);
      setError('Failed to fetch session logs');
    } finally {
      setLoading(false);
      setDataLoaded(true);
    }
  }, [email]);

  const debouncedFetchSessionLogs = useRef(debounce(fetchSessionLogs, 300)).current;

  useEffect(() => {
    console.log('SessionLogsProvider useEffect triggered');
    if (!dataLoaded && email) {
      console.log('Initiating fetch of session logs');
      debouncedFetchSessionLogs();
    }
  }, [dataLoaded, debouncedFetchSessionLogs, email]);

  useEffect(() => {
    console.log('Setting up interval for session log refresh');
    const intervalId = setInterval(() => {
      if (dataLoaded) {
        console.log('Refreshing session logs');
        fetchSessionLogs(true);
      }
    }, CACHE_DURATION);
    return () => {
      console.log('Clearing session log refresh interval');
      clearInterval(intervalId);
    };
  }, [fetchSessionLogs, dataLoaded]);

  useEffect(() => {
    console.log('Setting up focus event listener for session log refresh');
    const handleFocus = () => {
      const cachedData = sessionStorage.getItem('sessionLogs');
      if (cachedData) {
        const { timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp >= CACHE_DURATION) {
          console.log('Refreshing session logs on focus');
          fetchSessionLogs(true);
        }
      }
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      console.log('Removing focus event listener');
      window.removeEventListener('focus', handleFocus);
    };
  }, [fetchSessionLogs]);

  const refreshSessionLogs = () => {
    console.log('Manual refresh of session logs triggered');
    fetchSessionLogs(true);
  };

  return (
    <SessionLogsContext.Provider value={{ sessionLogs, loading, error, refreshSessionLogs }}>
      {children}
    </SessionLogsContext.Provider>
  );
};