import React, { useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TextInput, Select } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { WorkflowsContext } from '../../context/WorkflowsContext';
import { ToolsContext } from '../../context/ToolsContext';
import { HiSearch, HiLink } from 'react-icons/hi';

const Marketplace = () => {
  const { workflows, loading } = useContext(WorkflowsContext);
  const { tools: contextTools } = useContext(ToolsContext);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('newest');
  const navigate = useNavigate();

  useEffect(() => {
    if (workflows) {
      let filtered = [...workflows];
      if (searchTerm) {
        filtered = filtered.filter(workflow => 
          workflow.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          workflow.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      switch (sortOption) {
        case 'alphabetical':
          filtered.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'newest':
          filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          break;
        default:
          break;
      }
      setFilteredWorkflows(filtered);
    }
  }, [workflows, searchTerm, sortOption]);

  const handleViewWorkflow = (workflow) => {
    if (!workflow || !workflow.configuration) {
      console.error('Invalid workflow structure:', workflow);
      return;
    }

    const preparedWorkflowTools = workflow.configuration.steps.map(step => {
      const matchingTool = contextTools.find(tool => tool.name === step.tool.name);
      if (!matchingTool) {
        console.warn(`Tool not found: ${step.tool.name}`);
        return null;
      }
      return {
        ...matchingTool,
        settings: Object.keys(matchingTool.settings || {}).reduce((acc, key) => {
          acc[key] = {
            ...matchingTool.settings[key],
            value: matchingTool.settings[key]?.default || ''
          };
          return acc;
        }, {}),
        model: 'openai/gpt-4o-mini'
      };
    }).filter(Boolean);

    navigate('/dashboard/workflows', { 
      state: { 
        createNew: true,
        suggestedTools: preparedWorkflowTools,
        workflow: {
          name: workflow.name,
          description: workflow.description,
          configuration: {
            steps: preparedWorkflowTools.map(tool => ({
              tool: {
                name: tool.name,
                description: tool.description,
                settings: tool.settings
              }
            }))
          }
        }
      } 
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!workflows) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500 text-xl">Error loading workflows.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold mb-8">Workflow Marketplace</h1>
        <div className="flex flex-col md:flex-row gap-4 mb-8">
          <TextInput
            type="text"
            placeholder="Search workflows..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={HiSearch}
            className="flex-grow"
          />
          <Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="w-full md:w-48"
          >
            <option value="newest">Newest</option>
            <option value="alphabetical">Alphabetical</option>
          </Select>
        </div>
      </motion.div>
      <AnimatePresence>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {filteredWorkflows.map(workflow => (
            <motion.div
              key={workflow.id}
              variants={{
                hidden: { y: 20, opacity: 0 },
                visible: { y: 0, opacity: 1 }
              }}
              onClick={() => handleViewWorkflow(workflow)}
              className="cursor-pointer"
            >
              <div className="bg-white bg-opacity-10 p-6 rounded-xl backdrop-filter backdrop-blur-lg hover:scale-105 transition duration-300">
                <h3 className="text-xl font-semibold mb-2">{workflow.name}</h3>
                <p className="text-gray-300 mb-4">{workflow.description}</p>
                <div className="flex items-center space-x-2">
                  <HiLink className="text-blue-400" />
                  <span className="text-sm">
                    {workflow.configuration && workflow.configuration.steps
                      ? workflow.configuration.steps.map(step => step.tool.name).join(' → ')
                      : 'No steps defined'}
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Marketplace;