import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useBlogContext } from '../../context/BlogContext';
import { FaCalendar, FaEye, FaHeart, FaFacebookF, FaLinkedinIn, FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import axios from 'axios';

const CustomLightbox = ({ images, currentIndex, onClose, onPrev, onNext }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
      if (e.key === 'ArrowLeft') onPrev();
      if (e.key === 'ArrowRight') onNext();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onPrev, onNext]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <button onClick={onClose} className="absolute top-4 right-4 text-white">
        <FaTimes size={24} />
      </button>
      <button onClick={onPrev} className="absolute left-4 text-white">
        <FaChevronLeft size={24} />
      </button>
      <button onClick={onNext} className="absolute right-4 text-white">
        <FaChevronRight size={24} />
      </button>
      <img src={images[currentIndex]} alt="Lightbox" className="max-h-90vh max-w-90vw object-contain" />
    </div>
  );
};

const BlogPost = () => {
  const { slug } = useParams();
  const { posts, loading } = useBlogContext();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [liked, setLiked] = useState(false);
  const [likeText, setLikeText] = useState('Like this post');
  const [post, setPost] = useState(null);
  const [viewLogged, setViewLogged] = useState(false);

  const logPostInteraction = useCallback(async (view, like) => {
    if (!post) return; // Ensure post exists before logging
    try {
      await axios.post(process.env.REACT_APP_UPDATE_BLOG, 
        { slug: post.slug, view, like },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PASS,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error logging post interaction:', error);
    }
  }, [post]);

  useEffect(() => {
    if (posts && posts.length > 0) {
      const foundPost = posts.find(p => p.slug === slug);
      setPost(foundPost);
    }
  }, [posts, slug]);

  useEffect(() => {
    if (post && !viewLogged) {
      logPostInteraction(1, 0); // Log view when post is loaded
      setViewLogged(true);
    }
  }, [post, viewLogged, logPostInteraction]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const shareUrl = window.location.href;

  const getImageSrc = (src) => {
    if (src.startsWith('http://') || src.startsWith('https://')) {
      return src;
    } else {
      return `${process.env.PUBLIC_URL}${src}`;
    }
  };

  const handleLikeClick = () => {
    if (!liked) {
      logPostInteraction(0, 1); // Log like when button is clicked
      setLiked(true);
      setLikeText('Thank you!');
    }
  };

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  if (loading) return <div className="container mx-auto px-4 py-8">Loading...</div>;
  if (!posts || posts.length === 0) return <div className="container mx-auto px-4 py-8">No posts available</div>;
  if (!post) return <div className="container mx-auto px-4 py-8">Post not found</div>;

  const imageRegex = /!\[.*?\]\((.*?)\)/g;
  const images = [];
  let match;
  while ((match = imageRegex.exec(post.content)) !== null) {
    images.push(getImageSrc(match[1]));
  }

  return (
    <article className="container mx-auto px-4 py-8 max-w-4xl">
      <Helmet>
        <title>{`${post.title} - LLMazing Online`}</title>
        <meta name="description" content={post.excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={getImageSrc(post.image)} />
        <meta property="og:url" content={shareUrl} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <header className="mb-8">
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        <div className="flex flex-wrap items-center text-gray-600 mb-4">
          <span className="flex items-center mr-4">
            <FaCalendar className="mr-2" />
            {formatDate(post.published_at || post.created_at)}
          </span>
          <span className="flex items-center mr-4">
            <FaEye className="mr-2" />
            {post.views} views
          </span>
          <span className="flex items-center">
            <FaHeart className="mr-2" />
            {post.likes} likes
          </span>
        </div>
        {post.category && (
          <Link to={`/blog/category/${post.category}`} className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
            {post.category}
          </Link>
        )}
        {post.tags && post.tags.map(tag => (
          <Link 
            key={tag} 
            to={`/blog/tag/${tag}`} 
            className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded hover:bg-gray-200"
          >
            {tag}
          </Link>
        ))}
      </header>

      {post.image && (
        <img src={getImageSrc(post.image)} alt={post.title} className="w-full h-auto object-cover mb-8 rounded-lg shadow-lg" />
      )}

      <div className="prose prose-lg max-w-none mb-8">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={{
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  style={tomorrow}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            },
            img: ({node, ...props}) => (
              <img
                {...props}
                src={getImageSrc(props.src)}
                className="w-full h-auto object-cover rounded-lg shadow-md cursor-pointer"
                onClick={() => {
                  openLightbox(images.indexOf(getImageSrc(props.src)));
                }}
                loading="lazy"
              />
            ),
            a: ({node, ...props}) => (
              <a
                {...props}
                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
              />
            )
          }}
        >
          {post.content}
        </ReactMarkdown>
      </div>

      {lightboxOpen && (
        <CustomLightbox
          images={images}
          currentIndex={lightboxIndex}
          onClose={closeLightbox}
          onPrev={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
          onNext={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
        />
      )}

      <footer className="border-t pt-4">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-semibold mb-2">Share this post:</h3>
            <div className="flex space-x-4">
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                <FaFacebookF size={24} />
              </a>
              <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${encodeURIComponent(post.title)}`} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900">
                <FaLinkedinIn size={24} />
              </a>
            </div>
          </div>
          <button 
            className={`${
              liked ? 'bg-red-600' : 'bg-red-500 hover:bg-red-600'
            } text-white font-bold py-2 px-4 rounded flex items-center`}
            onClick={handleLikeClick}
            disabled={liked}
          >
            <FaHeart className="mr-2" /> {likeText}
          </button>
        </div>
      </footer>
    </article>
  );
};

export default BlogPost;