import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import verifyToken from './verifyToken';
import { Card, Checkbox, Button, Label, Modal } from 'flowbite-react';
import { motion, AnimatePresence } from 'framer-motion';
import { HiInformationCircle, HiLightningBolt, HiChevronDown, HiChevronUp, HiUserAdd, HiUserCircle, HiSparkles, HiChatAlt2, HiThumbUp, HiShieldCheck, HiClock, HiCube } from 'react-icons/hi';
import axios from 'axios';
import { customTheme } from '../../customTheme';

const RegisterPage = () => {
  const [allConsentsChecked, setAllConsentsChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [expandedConsents, setExpandedConsents] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [googleResponse, setGoogleResponse] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPicture, setUserPicture] = useState('');
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigate = useNavigate();
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const checkUserExists = async (userInfo) => {
    const apiUrl = process.env.REACT_APP_CHECK_USER_EXISTS_URI;
    const username = process.env.REACT_APP_API_USER;
    const password = process.env.REACT_APP_API_PASS;
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);

    try {
      const response = await axios.post(apiUrl, userInfo, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error in checkUserExists:', error);
      throw error;
    }
  };

  const handleLoginSuccess = async (response) => {
    const { access_token } = response;
    if (access_token) {
      Cookies.set('accessToken', access_token, { expires: 1 });
    } else {
      console.error('Access token not found in response');
    }

    setGoogleResponse(response);

    const { credential } = response;
    if (credential) {
      try {
        const decoded = verifyToken(credential);
        if (decoded && decoded.email) {
          setUserEmail(decoded.email);
          setUserName(decoded.name);
          setUserPicture(decoded.picture);

          // Split the full name into first and last name
          const nameParts = decoded.name.split(' ');
          const firstName = nameParts[0];
          const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

          const userInfo = {
            firstName: firstName,
            lastName: lastName,
            email: decoded.email,
            CustomerID: 'None',
            phone: 'None',
            address: 'None',
            birthdate: 'None',
            gender: 'None',
            country: 'None',
            city: 'None',
            postalCode: 'None',
            picture: decoded.picture || 'None'
          };

          const userData = await checkUserExists(userInfo);
          setUserStatus(userData.status);
        }
      } catch (error) {
        console.error('Error decoding token or checking user existence', error);
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Google login failed:', error);
    if (error.error === 'popup_blocked_by_browser') {
      setErrorMessage('Popup blocked by the browser. Please allow popups for this site.');
    } else {
      setErrorMessage('Failed to sign in. Please try again.');
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSigningIn(true);

    if (!googleResponse) {
      setErrorMessage('You must sign in with Google to continue.');
      setIsSigningIn(false);
      return;
    }

    const { credential } = googleResponse;

    if (!credential) {
      setIsSigningIn(false);
      return;
    }

    try {
      const decoded = verifyToken(credential);

      if (!decoded || !decoded.email) {
        throw new Error('Decoded JWT does not contain email');
      }

      // Split the full name into first and last name
      const nameParts = decoded.name.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

      const userInfo = {
        firstName: firstName,
        lastName: lastName,
        email: decoded.email,
        CustomerID: 'None',
        phone: 'None',
        address: 'None',
        birthdate: 'None',
        gender: 'None',
        country: 'None',
        city: 'None',
        postalCode: 'None',
        picture: decoded.picture || 'None'
      };

      const userData = await checkUserExists(userInfo);

      Cookies.set('user', JSON.stringify({
        email: userEmail,
        name: userName,
        picture: userPicture,
        receiveEmails: privacyChecked,
        participateInStudies: consentChecked,
        CustomerID: userData.customer_id
      }));

      setShowRegistrationModal(true);
    } catch (error) {
      console.error('Error handling form submission', error);
      setErrorMessage('An error occurred during sign in. Please try again.');
    } finally {
      setIsSigningIn(false);
    }
  };

  const handleAllConsentsChange = (e) => {
    const checked = e.target.checked;
    setAllConsentsChecked(checked);
    setTermsChecked(checked);
    setPrivacyChecked(checked);
    setConsentChecked(checked);
  };

  const RegistrationModal = ({ show, onClose, isNewUser }) => {
    useEffect(() => {
      if (show && !isNewUser) {
        const timer = setTimeout(() => {
          onClose();
        }, 2000);
        return () => clearTimeout(timer);
      }
    }, [show, isNewUser, onClose]);

    return (
      <AnimatePresence>
        {show && (
          <Modal show={show} onClose={onClose} size="md">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            >
              <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-md w-full mx-4">
                <div className={`${customTheme.gradients.coolVibesOnboarding} text-white py-6 px-6 flex items-center`}>
                  {isNewUser ? <HiUserAdd className="text-3xl mr-3" /> : <HiUserCircle className="text-3xl mr-3" />}
                  <h3 className="text-2xl font-semibold">{isNewUser ? 'Welcome to LLMazing.online!' : 'Welcome Back!'}</h3>
                </div>
                <div className="p-6">
                  {isNewUser ? (
                    <>
                      <p className="text-gray-700 mb-6 text-lg">
                        We're excited to have you on board! Our web app is designed to help you boost your productivity with AI-powered tools.
                      </p>
                      <div className="mb-6">
                        <h4 className="font-semibold flex items-center mb-3 text-lg">
                          <HiSparkles className="mr-2 text-yellow-500" />
                          Help Us Improve
                        </h4>
                        <p className="text-gray-600">
                          Your feedback is invaluable! Help us create a productivity tool tailored to your needs.
                        </p>
                      </div>
                      <div className="mb-6">
                        <h4 className="font-semibold flex items-center mb-3 text-lg">
                          <HiChatAlt2 className="mr-2 text-green-500" />
                          Share Your Ideas
                        </h4>
                        <p className="text-gray-600">
                          Have a great idea for a new feature? We'd love to hear it!
                        </p>
                      </div>
                    </>
                  ) : (
                    <p className="text-gray-700 mb-6 text-lg">
                      We're glad to see you again! Your account is ready for use. Let's boost your productivity together!
                    </p>
                  )}
                  <div className="flex justify-center">
                    <Button onClick={onClose} className={`${customTheme.button.primary} py-3 px-6 text-lg font-semibold rounded-full transition duration-300 transform hover:scale-105`}>
                      <HiThumbUp className="mr-2" />
                      {isNewUser ? "Let's Get Started!" : 'Continue to Dashboard'}
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
    );
  };

  const canSignIn = googleResponse;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className={`flex justify-center items-center min-h-screen ${customTheme.gradients.coolVibes} p-4`}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md"
        >
          <Card className="backdrop-filter backdrop-blur-lg bg-white bg-opacity-80 text-gray-800 shadow-xl">
            <div className="text-center">
              <motion.h2
                className="text-4xl font-bold mb-6 text-primary"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                Join LLMazing.online
              </motion.h2>
              <motion.p
                className="text-xl text-gray-700 mb-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                Access our AI-powered productivity tools for free
              </motion.p>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="mb-8"
              >
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={handleLoginFailure}
                  shape="pill"
                  theme="filled_blue"
                  size="large"
                  text="continue_with"
                  logo_alignment="left"
                  width="300"
                />
              </motion.div>
              {userEmail && (
                <motion.p
                  className="text-center mt-4 text-gray-700 text-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                >
                  Signing in as {userEmail}
                </motion.p>
              )}
            </div>
            {userEmail && (
              <motion.div
                className="space-y-4 mt-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
              >
                <div className="flex items-center space-x-3 bg-primary bg-opacity-10 p-4 rounded-lg">
                  <Checkbox
                    id="allConsents"
                    checked={allConsentsChecked}
                    onChange={handleAllConsentsChange}
                    className="text-secondary"
                  />
                  <Label htmlFor="allConsents" className="flex items-center text-gray-800">
                    <span className="text-lg font-medium">I agree to all terms and consent to communications</span>
                    <Button
                      size="xs"
                      onClick={() => setExpandedConsents(!expandedConsents)}
                      className="ml-2 bg-transparent hover:bg-primary hover:bg-opacity-20 text-primary"
                    >
                      {expandedConsents ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                    </Button>
                  </Label>
                </div>
                <AnimatePresence>
                  {expandedConsents && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="space-y-3 ml-6"
                    >
                      <div className="flex items-center space-x-3">
                        <Checkbox
                          id="terms"
                          checked={termsChecked}
                          onChange={(e) => setTermsChecked(e.target.checked)}
                          className="text-secondary"
                        />
                        <Label htmlFor="terms" className="flex text-gray-700">
                          <span>I agree to the </span>
                          <Link to="/terms-and-conditions" className="text-secondary hover:underline ml-1">Terms of Service</Link>
                          <span> and </span>
                          <Link to="/data-privacy" className="text-secondary hover:underline ml-1">Privacy Policy</Link>
                        </Label>
                      </div>
                      <div className="flex items-center space-x-3">
                        <Checkbox
                          id="privacy"
                          checked={privacyChecked}
                          onChange={(e) => setPrivacyChecked(e.target.checked)}
                          className="text-secondary"
                        />
                        <Label htmlFor="privacy" className="text-gray-700">
                          I'd like to receive emails about AI updates and offers (optional)
                        </Label>
                      </div>
                      <div className="flex items-center space-x-3">
                        <Checkbox
                          id="consent"
                          checked={consentChecked}
                          onChange={(e) => setConsentChecked(e.target.checked)}
                          className="text-secondary"
                        />
                        <Label htmlFor="consent" className="text-gray-700">
                          I'd like to participate in AI research studies (optional)
                        </Label>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
            {canSignIn && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="mt-8"
              >
                <Button
                  onClick={handleFormSubmit}
                  disabled={isSigningIn}
                  className={`w-full px-6 py-4 text-xl font-semibold ${customTheme.button.primary} rounded-full shadow-lg transition-all duration-300 transform ${
                    isSigningIn ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl hover:-translate-y-1'
                  }`}
                >
                  <HiLightningBolt className="mr-2 text-2xl" />
                  {isSigningIn ? 'Signing In...' : 'Get Started'}
                </Button>
              </motion.div>
            )}
            {errorMessage && (
              <motion.div
                className="flex items-center p-4 mt-6 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50"
                role="alert"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <HiInformationCircle className="flex-shrink-0 inline w-5 h-5 mr-3" />
                <span className="sr-only">Info</span>
                <div>{errorMessage}</div>
              </motion.div>
            )}
          </Card>
        </motion.div>
        <RegistrationModal
          show={showRegistrationModal}
          onClose={() => {
            setShowRegistrationModal(false);
            navigate('/dashboard/workflows');
          }}
          isNewUser={userStatus === 'new'}
        />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
        className="fixed bottom-0 left-0 right-0 p-4 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg"
      >
        <div className="container mx-auto flex flex-wrap justify-center space-y-2 md:space-y-0 md:space-x-8 text-gray-800">
          <div className="flex items-center">
            <HiShieldCheck className="text-2xl mr-2 text-primary" />
            <span>Secure & Private using Google authentication</span>
          </div>
          <div className="flex items-center">
            <HiClock className="text-2xl mr-2 text-primary" />
            <span>Free and Quick Setup</span>
          </div>
          <div className="flex items-center">
            <HiCube className="text-2xl mr-2 text-primary" />
            <span>No credit card or any other payment method needed</span>
          </div>
        </div>
      </motion.div>
    </GoogleOAuthProvider>
  );
};

export default RegisterPage;