import React from 'react';
import { Footer as FlowbiteFooter } from 'flowbite-react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub, FaYoutube } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerSections = [
    {
      title: 'Company',
      links: [
        { name: 'About', href: '/about' }, // Assuming these are still relevant
      ],
    },
    {
      title: 'Legal',
      links: [
        { name: 'Privacy Policy', href: '/data-privacy' },
        { name: 'Terms of Service', href: '/terms-and-conditions' },
      ],
    },
    {
      title: 'Dashboard',
      links: [
        { name: 'Workflows', href: '/dashboard/workflows' },
        { name: 'Marketplace', href: '/dashboard/marketplace' },
        { name: 'Profile', href: '/dashboard/profile' },
        { name: 'Settings', href: '/dashboard/settings' },
      ],
    },
  ];

  return (
    <FlowbiteFooter container className="bg-primary text-white">
      <div className="w-full max-w-7xl mx-auto py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div>
              <FlowbiteFooter.Brand
                href="/"
                src="/images/logo-dots-no-back.png"
                alt="LLMazing Logo"
                name=""
              />
            </div>
            <p className="mt-4 max-w-xs text-white">
              Empowering your workflow with AI-driven productivity tools.
            </p>
          </motion.div>
          {footerSections.map((section) => (
            <motion.div
              key={section.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-right"
            >
              <FlowbiteFooter.Title title={section.title} className="text-white" />
              <FlowbiteFooter.LinkGroup col className="text-right">
                {section.links.map((link) => (
                  <FlowbiteFooter.Link
                    key={link.name}
                    href={link.href}
                    target={link.href.startsWith('http') ? '_blank' : '_self'}
                    rel={link.href.startsWith('http') ? 'noopener noreferrer' : ''}
                    className="text-white hover:text-gray-300"
                  >
                    {link.name}
                  </FlowbiteFooter.Link>
                ))}
              </FlowbiteFooter.LinkGroup>
            </motion.div>
          ))}
        </div>
        <FlowbiteFooter.Divider className="border-gray-500" />
        <motion.div
          className="w-full sm:flex sm:items-center sm:justify-between mt-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <FlowbiteFooter.Copyright
            href="#"
            by="LLMazing™"
            year={currentYear}
            className="text-white"
          />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            {[
              // { href: 'https://facebook.com', icon: FaFacebookF },
              // { href: 'https://instagram.com', icon: FaInstagram },
              { href: 'https://www.linkedin.com/company/llmazing-usecases', icon: FaLinkedinIn },
              { href: 'https://www.youtube.com/@LLMazingCompany', icon: FaYoutube },
            ].map((socialLink) => (
              <FlowbiteFooter.Icon
                key={socialLink.href}
                href={socialLink.href}
                icon={socialLink.icon}
                className="text-white hover:text-gray-300"
                target="_blank"                 // Open in new tab
                rel="noopener noreferrer"       // Prevent security risks

              />
            ))}
          </div>
        </motion.div>
      </div>
    </FlowbiteFooter>
  );
};

export default Footer;
