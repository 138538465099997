import React, { useState } from 'react';
import { Button } from 'flowbite-react';
import { customTheme } from '../../customTheme';

const TextSelectionMenu = ({ onOptionSelect }) => {
    const [isLoading, setIsLoading] = useState(false);
    const options = [
      'Work breakdown',
      'Generate Questions',
      'Improve wording',
      'Explain',
      'Summarize'
    ];
  
    const handleOptionClick = async (option) => {
      setIsLoading(true);
      try {
        await onOptionSelect(option);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div 
        style={{
          position: 'fixed',
          top: '80px', // Increased to be below the header
          left: '40%', // Moved slightly to the left
          transform: 'translateX(-50%)',
          zIndex: 10000, // Increased z-index to be above everything
          width: '200px',
        }}
        className="bg-gray-100 shadow-md rounded-md overflow-hidden"
      >
        <div className="bg-gray-700 text-white text-xs py-2 px-3 font-semibold">
          LLMazing assistant
        </div>
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(option)}
            disabled={isLoading}
            className={`block w-full text-left px-3 py-2 text-sm text-gray-700 transition-colors duration-150 ${
              isLoading 
                ? 'bg-gray-200 cursor-not-allowed' 
                : 'hover:bg-gray-200'
            }`}
          >
            {isLoading ? 'Processing...' : option}
          </button>
        ))}
      </div>
    );
  };

export default TextSelectionMenu;