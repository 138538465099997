import React, { useContext, useMemo, useState, useEffect } from 'react';
import { ToolsContext } from '../../context/ToolsContext';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageTracking from '../../usePageTracking.js';
import { motion, AnimatePresence } from 'framer-motion';
import { HiSearch, HiLightningBolt } from 'react-icons/hi';
import { TextInput, Button, Badge } from 'flowbite-react';

const Tools = () => {
  usePageTracking();
  const { tools, loading } = useContext(ToolsContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All Tools');
  const [filteredTools, setFilteredTools] = useState([]);

  useEffect(() => {
    const category = new URLSearchParams(location.search).get('category') || 'All Tools';
    setSelectedCategory(category);
  }, [location]);

  useEffect(() => {
    if (tools) {
      let filtered = tools;
      if (selectedCategory !== 'All Tools') {
        filtered = filtered.filter(tool => tool.category === selectedCategory);
      }
      if (searchTerm) {
        filtered = filtered.filter(tool => 
          tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tool.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredTools(filtered);
    }
  }, [tools, selectedCategory, searchTerm]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!tools || tools.length === 0) {
    return <div className="text-center text-gray-600 dark:text-gray-400 text-xl mt-8">No tools available.</div>;
  }

  const handleToolClick = (tool) => {
    navigate(`/dashboard/tools/${encodeURIComponent(tool.name)}`, { state: { tool } });
  };

  const categories = ['All Tools', ...new Set(tools.map(tool => tool.category))];

  return (
    <div className="container mx-auto px-4 max-w-7xl">
      <h2 className="text-3xl font-bold mb-6 text-white dark:text-white">
        AI-Powered Tools
      </h2>
      
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <TextInput
          type="text"
          placeholder="Search tools..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          icon={HiSearch}
          className="flex-grow"
        />
        <div className="flex flex-wrap gap-2">
          {categories.map((category) => (
            <Button
              key={category}
              color={category === selectedCategory ? "primary" : "light"}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </Button>
          ))}
        </div>
      </div>

      <AnimatePresence>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {filteredTools.map(tool => (
            <motion.div
              key={tool.name}
              variants={{
                hidden: { y: 20, opacity: 0 },
                visible: { y: 0, opacity: 1 }
              }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
              onClick={() => handleToolClick(tool)}
              className="bg-white bg-opacity-10 p-4 rounded-xl backdrop-filter backdrop-blur-lg cursor-pointer"
            >
              <h3 className="text-lg font-semibold mb-2 text-white">{tool.name}</h3>
              <p className="text-gray-300 mb-4 text-sm">{tool.description.slice(0, 100)}...</p>
              <div className="flex flex-wrap gap-2">
                {(Array.isArray(tool.labels) ? tool.labels : (tool.labels || '').split(', ')).slice(0, 3).map((label, index) => (
                  <Badge key={`${tool.name}-${label}-${index}`} color="info" className="text-xs">
                    {label}
                  </Badge>
                ))}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>

      {filteredTools.length === 0 && (
        <div className="text-center text-gray-600 dark:text-gray-400 text-xl mt-8">
          No tools found. Try adjusting your search or category selection.
        </div>
      )}
    </div>
  );
};

export default Tools;