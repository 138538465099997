import React, { createContext, useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

const BlogContext = createContext();

export const useBlogContext = () => useContext(BlogContext);

export const BlogProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const CACHE_DURATION = 900000; // 1 hour in milliseconds

  const getCachedData = (key) => {
    const cachedData = sessionStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedData = (key, data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    sessionStorage.setItem(key, JSON.stringify(cacheData));
  };

  const fetchPosts = useCallback(async (force = false) => {
    if (!force) {
      const cachedPosts = getCachedData('blogPosts');
      if (cachedPosts) {
        setPosts(cachedPosts);
        setLoading(false);
        setDataLoaded(true);
        return;
      }
    }

    try {
      setLoading(true);
      const response = await axios.get(process.env.REACT_APP_GET_BLOG, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setPosts(response.data);
      setCachedData('blogPosts', response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setError('Failed to fetch blog posts');
    } finally {
      setLoading(false);
      setDataLoaded(true);
    }
  }, []);

  const debouncedFetchPosts = useRef(debounce(fetchPosts, 300)).current;

  useEffect(() => {
    if (!dataLoaded) {
      debouncedFetchPosts();
    }
  }, [dataLoaded, debouncedFetchPosts]);

  // Set up interval for periodic cache validation
  useEffect(() => {
    const intervalId = setInterval(() => fetchPosts(true), CACHE_DURATION);
    return () => clearInterval(intervalId);
  }, [fetchPosts]);

  // Fetch on tab focus if cache is stale
  useEffect(() => {
    const handleFocus = () => {
      const cachedData = sessionStorage.getItem('blogPosts');
      if (cachedData) {
        const { timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp >= CACHE_DURATION) {
          fetchPosts(true);
        }
      }
    };
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [fetchPosts]);

  const refreshPosts = () => {
    fetchPosts(true); // Force a refresh
  };

  return (
    <BlogContext.Provider value={{ posts, loading, error, refreshPosts }}>
      {children}
    </BlogContext.Provider>
  );
};