import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Button } from 'flowbite-react';
import { useContexts } from '../../context/ContextsContext';
import { customTheme } from '../../customTheme';
import { HiLightBulb, HiCheck, HiX } from 'react-icons/hi';

const OnboardingOverlay = ({ onClose, currentStep, onNext }) => {
  const [step, setStep] = useState(0);
  const { updateHideOnboarding } = useContexts();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const steps = [
    { 
      text: "Welcome to Workflow Builder! Start by adding a tool to your workflow by clicking the 'Add Tool' button",
      highlight: "add-tool-button" 
    },
    { 
      text: "Great! Add some context in the settings of the tool card and after execute your workflow by clicking 'Execute' button.",
      highlight: "execute-button"
    },
    { 
      text: "For working smarter during meetings or brainstorming sessions - switch to Meeting Mode for note-taking. Click on the 'Enter Meeting Mode' button and use our smart notes",
      highlight: "meeting-mode-button"
    }
  ];

  useEffect(() => {
    const highlightElement = document.getElementById(steps[step].highlight);
    if (highlightElement) {
      highlightElement.classList.add('onboarding-highlight');
    }

    return () => {
      if (highlightElement) {
        highlightElement.classList.remove('onboarding-highlight');
      }
    };
  }, [step]);

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      handleFinish();
    }
  };

  const handleFinish = () => {
    if (doNotShowAgain) {
      updateHideOnboarding(true);
    }
    onClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={`${customTheme.gradients.coolVibesOnboarding} p-8 rounded-xl max-w-md shadow-2xl`}
        style={{ opacity: 1 }} 
      >
        <div className="flex items-center mb-6">
          <HiLightBulb className="text-3xl mr-4 text-yellow-300" />
          <h2 className="text-2xl font-bold text-white">Workflow Builder Guide</h2>
        </div>
        <p className="text-lg mb-6 text-white">{steps[step].text}</p>
        <div className="flex justify-between items-center">
          <label className="flex items-center text-white">
            <input
              type="checkbox"
              checked={doNotShowAgain}
              onChange={(e) => setDoNotShowAgain(e.target.checked)}
              className="mr-2 rounded text-pink-500 focus:ring-pink-500"
            />
            Don't show again
          </label>
          <div className="flex space-x-4">
            <Button
              color="light"
              onClick={onClose}
              className="bg-white text-gray-800 hover:bg-gray-200"
            >
              <HiX className="mr-2" /> Skip
            </Button>
            <Button
              onClick={onNext}
              className={`${customTheme.button.base} ${customTheme.button.accent}`}
            >
              {step < steps.length - 1 ? 'Next' : 'Finish'} <HiCheck className="ml-2" />
            </Button>
          </div>
        </div>
        <div className="mt-4 text-right text-white text-sm">
          Step {step + 1} of {steps.length}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default OnboardingOverlay;