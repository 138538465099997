// customTheme.js
export const customTheme = {
  // Color palette
  colors: {
    primary: '#3A0CA3',
    secondary: '#4CC9F0',
    accent: '#F72585',
    background: '#F8F9FA',
    text: '#212529',
    success: '#4CAF50',
    warning: '#FFC107',
    error: '#F44336',
    darkblue: '#080B53'
  },

  // Typography
  fontFamily: {
    sans: ['Inter', 'sans-serif'],
    heading: ['Poppins', 'sans-serif'],
  },

  // Component theming
  card: {
    root: {
      base: "flex rounded-xl border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 transition-all duration-300 hover:shadow-lg",
      children: "flex h-full flex-col justify-between p-6",
    },
    img: {
      base: "rounded-t-xl h-48 w-full object-cover",
    },
  },

  button: {
    base: "inline-flex items-center justify-center rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300",
    primary: "bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white",
    secondary: "bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 text-white",
    accent: "bg-gradient-to-r from-pink-500 to-rose-500 hover:from-pink-600 hover:to-rose-600 text-white",
  },


  navbar: {
    root: {
      base: "bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg px-4 py-3 dark:bg-gray-800 sticky top-0 z-50 transition-all duration-300",
    },
    link: {
      base: "text-gray-700 hover:text-primary dark:text-gray-300 dark:hover:text-secondary px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300",
    },
  },

  textInput: {
    field: {
      base: "block w-full rounded-full border-gray-300 shadow-sm focus:border-secondary focus:ring focus:ring-secondary focus:ring-opacity-50 transition-all duration-300",
    },
  },

  footer: {
    root: {
      base: "bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700",
    },
    copyright: {
      base: "text-sm text-gray-500 dark:text-gray-400",
    },
  },

  gradients: {
    dashboard: "bg-gradient-to-b from-primary to-secondary",
    softGlow: "bg-gradient-to-br from-blue-900 via-pink-500/40 to-indigo-300/20",
    subtleWaves: "bg-gradient-to-r from-indigo-700/70 via-pink-300/40 to-indigo-900/20",
    coolVibes: "bg-gradient-to-tr from-blue-800/60 via-pink-400/30 to-blue-500/10",
    coolVibesOnboarding: "bg-gradient-to-tr from-blue-800 via-pink-400 to-blue-500",
    sunsetDream: "bg-gradient-to-br from-pink-500/80 via-orange-400/60 to-yellow-300/20",
    oceanBreeze: "bg-gradient-to-tl from-blue-700/70 via-teal-400/40 to-blue-200/20",
    twilightHues: "bg-gradient-to-bl from-purple-900 via-pink-600/40 to-blue-300/15",
    
    // New AI-inspired digital backgrounds
    neonPulse: "bg-gradient-to-r from-purple-700 via-pink-500 to-blue-500",
    cyberWave: "bg-gradient-to-bl from-blue-800 via-cyan-600 to-teal-500",
    technoGrid: "bg-gradient-to-br from-purple-900 via-fuchsia-600/75 to-indigo-400/30",
    quantumLeap: "bg-gradient-to-tl from-teal-900 via-blue-600/50 to-purple-500/20",
    digitalAurora: "bg-gradient-to-r from-indigo-900 via-purple-700/50 to-pink-500/25",
    futureCircuit: "bg-gradient-to-tr from-cyan-700 via-blue-500/60 to-indigo-800/30",
    matrixFlow: "bg-gradient-to-br from-green-800 via-teal-600 to-cyan-500",
    AIChill: "bg-gradient-to-bl from-blue-600 via-purple-600 to-indigo-900",
    virtualVortex: "bg-gradient-to-r from-fuchsia-700 via-pink-500 to-purple-600",
    neuralNet: "bg-gradient-to-tl from-indigo-800 via-blue-600/50 to-cyan-400/30",
  },

  text: {
    light: "text-white",
  },

  breadcrumb: {
    item: "text-white hover:text-secondary",
  },


  heroSection: {
    base: "text-white text-center p-12",
    title: "text-5xl font-bold mb-6",
    subtitle: "text-2xl mb-10",
  },

  featureCard: {
    base: "bg-white bg-opacity-10 p-6 rounded-xl backdrop-filter backdrop-blur-lg transition-all duration-300 hover:shadow-lg",
    icon: "text-4xl mb-4 text-accent",
    title: "text-xl font-semibold mb-2 text-white",
    description: "text-gray-300",
  },

  toolCard: {
    base: "bg-white bg-opacity-10 p-6 rounded-xl backdrop-filter backdrop-blur-lg transition-all duration-300 hover:shadow-lg cursor-pointer",
    title: "text-xl font-semibold mb-2 text-white",
    description: "text-gray-300 mb-4",
    label: "bg-accent text-primary px-3 py-1 rounded-full text-sm",
  },
  sectionColorOne: "bg-indigo-900",
  sectionColorTwo: "bg-indigo-800",

  // Add this to your customTheme.js file
  mermaidWrapper: {
    base: "bg-white p-4 rounded-lg shadow-sm",
  },

  sectionTitle: "text-3xl font-bold text-center mb-12",

  animations: {
    fadeInUp: {
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    }
  },

};