import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TurndownService from 'turndown';
import DOMPurify from 'dompurify';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';
import rehypeStringify from 'rehype-stringify';
import './QuillEditor.css';
import { customTheme } from '../../customTheme';

const turndownService = new TurndownService();

// Preserve tables in Markdown
turndownService.addRule('table', {
  filter: ['table'],
  replacement: function(content, node) {
    return '\n\n' + node.outerHTML + '\n\n';
  }
});

const EditableResult = ({ initialContent, onContentChange }) => {
  const [content, setContent] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    if (initialContent) {
      convertMarkdownToHtml(initialContent).then(html => {
        setContent(html);
      });
    }
  }, [initialContent]);

  const convertMarkdownToHtml = async (markdown) => {
    const result = await unified()
      .use(remarkParse)
      .use(remarkGfm)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeRaw)
      .use(rehypeStringify)
      .process(markdown);

    let html = DOMPurify.sanitize(String(result), { 
      ADD_TAGS: ['table', 'thead', 'tbody', 'tr', 'th', 'td'],
      ADD_ATTR: ['colspan', 'rowspan']
    });

    // Add CSS classes to tables for styling
    html = html.replace(/<table>/g, '<table class="quill-table">');

    return html;
  };

  const handleQuillChange = (value) => {
    setContent(value);
    const markdown = turndownService.turndown(value);
    onContentChange(markdown);
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image'
  ];

  return (
    <div className="editable-result">
      <ReactQuill
        ref={quillRef}
        value={content}
        onChange={handleQuillChange}
        modules={modules}
        formats={formats}
        className="bg-white text-gray-800"
        style={{
          '--quill-primary': customTheme.colors.primary,
          '--quill-secondary': customTheme.colors.secondary,
          '--quill-accent': customTheme.colors.accent,
        }}
      />
      <style jsx>{`
        .quill-table {
          border-collapse: collapse;
          width: 100%;
          margin: 1rem 0;
        }
        .quill-table th, .quill-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        .quill-table th {
          background-color: #f2f2f2;
        }
      `}</style>
    </div>
  );
};

export default EditableResult;