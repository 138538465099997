import React, { createContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

export const WorkflowsContext = createContext({
  workflows: [],
  loading: true,
  fetchWorkflows: () => {}
});

export const WorkflowsProvider = ({ children }) => {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const CACHE_DURATION = 900000; // 15 minutes in milliseconds

  const getCachedData = (key) => {
    const cachedData = sessionStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedData = (key, data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    sessionStorage.setItem(key, JSON.stringify(cacheData));
  };

  const debouncedFetchWorkflows = useRef(
    debounce(async (force = false) => {
      if (dataLoaded && !force) return;

      try {
        setLoading(true);
        const cachedWorkflows = getCachedData('workflows');

        if (!force && cachedWorkflows) {
          setWorkflows(cachedWorkflows);
        } else {
          const response = await axios.get(process.env.REACT_APP_GET_WORKFLOWS_URL, {
            auth: {
              username: process.env.REACT_APP_API_USER,
              password: process.env.REACT_APP_API_PASS,
            },
          });
          const sortedWorkflows = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setWorkflows(sortedWorkflows);
          setCachedData('workflows', sortedWorkflows);
        }
      } catch (error) {
        console.error('Error fetching workflows:', error);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (!dataLoaded) {
      debouncedFetchWorkflows();
    }
  }, [dataLoaded, debouncedFetchWorkflows]);

  useEffect(() => {
    const intervalId = setInterval(() => debouncedFetchWorkflows(true), CACHE_DURATION);
    return () => clearInterval(intervalId);
  }, [debouncedFetchWorkflows]);

  useEffect(() => {
    const handleFocus = () => {
      const cachedData = sessionStorage.getItem('workflows');
      if (cachedData) {
        const { timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp >= CACHE_DURATION) {
          debouncedFetchWorkflows(true);
        }
      }
    };
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [debouncedFetchWorkflows]);

  const refreshWorkflows = () => {
    setDataLoaded(false);
    debouncedFetchWorkflows(true);
  };

  return (
    <WorkflowsContext.Provider value={{ workflows, loading, fetchWorkflows: refreshWorkflows }}>
      {children}
    </WorkflowsContext.Provider>
  );
};
