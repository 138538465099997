import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Card, Button, Label, TextInput, Textarea, Select, Accordion, Modal, Toast, Progress } from "flowbite-react";
import { HiOutlineSave, HiOutlinePencilAlt, HiOutlineViewBoards, HiOutlineLightBulb, HiLightningBolt, HiOutlineInformationCircle, HiPlus, HiX, HiChevronUp, HiChevronDown, HiTrash, HiSearch, HiCheckCircle, HiOutlineClipboardCopy, HiOutlineDocumentDownload, HiOutlinePresentationChartBar, HiOutlineRefresh, HiOutlineCheckCircle, HiQuestionMarkCircle, HiZoomIn, HiZoomOut } from 'react-icons/hi';
import { IoIosWarning } from 'react-icons/io';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import mermaid from 'mermaid';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToolsContext } from '../../context/ToolsContext';
import { useContexts } from '../../context/ContextsContext';
import { customTheme } from '../../customTheme';
import ReactDOMServer from 'react-dom/server';
import { stripHtml } from "string-strip-html";
import useCookie from '../../context/useCookie';
import EnhancedWorkflowSetup from './EnhancedWorkflowSetup';
import EditableResult from './EditableResult';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextSelectionMenu from './TextSelectionMenu';
import ValidationModal from './ValidationModal';
import NPSDetector from './NPSDetector';
import pptxgen from 'pptxgenjs';
import OnboardingOverlay from './OnboardingOverlay';
import TipOfTheDay from './TipOfTheDay';
import {
  BarChart,
  Lightbulb,
  Users,
  Info,
  FileText,
  ArrowRight,
  ArrowLeft,
  Plus,
  Minus,
  RefreshCw,
  PieChart,
  LineChart,
  Presentation,
  Image,
  LayoutGrid,
  List,
  Check,
  X,
  Settings,
  Download
} from 'lucide-react';
import { Helmet } from 'react-helmet';
import html2canvas from 'html2canvas';


const Tooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);
  const { tooltipsVisible } = useContexts();

  useEffect(() => {
    const handleMouseEnter = () => tooltipsVisible && setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);

    const triggerEl = triggerRef.current;
    triggerEl.addEventListener('mouseenter', handleMouseEnter);
    triggerEl.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      triggerEl.removeEventListener('mouseenter', handleMouseEnter);
      triggerEl.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [tooltipsVisible]);

  useEffect(() => {
    if (isVisible && tooltipRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: triggerRect.bottom + window.scrollY,
        left: triggerRect.left + window.scrollX,
      });
    }
  }, [isVisible]);

  return (
    <>
      <div ref={triggerRef}>{children}</div>
      {isVisible && (
        <div
          ref={tooltipRef}
          style={{ top: `${position.top}px`, left: `${position.left}px` }}
          className="fixed z-50 bg-white border border-gray-200 rounded-lg shadow-lg p-2 max-w-xs text-sm text-gray-400"
        >
          {content}
        </div>
      )}
    </>
  );
};



const LoadingEntertainment = () => {
  const [tip, setTip] = useState('');
  const tips = [
    "Did you know? AI can now write poetry and even compose music!",
    "Tip: Try combining different tools for unique and powerful results.",
    "Fun fact: The term 'artificial intelligence' was coined in 1956 at a Dartmouth conference.",
    "Experiment with different prompts to see how results can dramatically change.",
    "AI is constantly learning and improving. Your results may vary and improve over time!",
    "Productivity hack: Use the Pomodoro Technique - 25 minutes of focused work, then a 5-minute break.",
    "AI fact: The first AI program, the Logic Theorist, was created in 1955 by Allen Newell and Herbert Simon.",
    "Workflow tip: Start your day by tackling your most important task first.",
    "Did you know? OpenAI's GPT-3 model has 175 billion parameters!",
    "Productivity boost: Try the 2-minute rule. If a task takes less than 2 minutes, do it immediately.",
    "AI milestone: In 1997, IBM's Deep Blue defeated world chess champion Garry Kasparov.",
    "Workflow efficiency: Use keyboard shortcuts to speed up your work.",
    "Fun fact: The first computer programmer was Ada Lovelace, who lived in the 19th century.",
    "Time management tip: Use the Eisenhower Matrix to prioritize tasks based on urgency and importance.",
    "AI in daily life: Voice assistants like Siri and Alexa use natural language processing AI.",
    "Productivity hack: Practice 'batching' similar tasks to reduce context switching.",
    "Did you know? AI can now generate realistic images from text descriptions!",
    "Workflow tip: Regular breaks can actually increase your productivity and creativity.",
    "AI fact: Machine learning algorithms improve automatically through experience.",
    "Time saver: Use templates for recurring tasks or documents.",
    "Fun fact: The Turing Test, proposed in 1950, is still used to evaluate AI's ability to exhibit intelligent behavior.",
    "Productivity boost: Try the 'eat the frog' technique - do your most daunting task first thing in the morning.",
    "AI application: Recommendation systems on platforms like Netflix and Amazon use AI algorithms.",
    "Workflow efficiency: Use automation tools to handle repetitive tasks.",
    "Did you know? AI is being used to help in the fight against climate change.",
    "Focus tip: Use website blockers during work hours to avoid distractions.",
    "AI in medicine: AI is helping to diagnose diseases and develop new drugs faster than ever before.",
    "Productivity hack: The '5-second rule' - if you have an impulse to act on a goal, do it within 5 seconds.",
    "Fun fact: Some AI models can now generate human-like handwriting!",
    "Time management: Try timeboxing - allocate a fixed time period to each task.",
    "AI ethics: Many organizations are working on developing ethical guidelines for AI development.",
    "Workflow tip: Regular team check-ins can help keep projects on track and address issues early.",
    "Did you know? AI is being used to create more efficient and environmentally friendly supply chains.",
    "Productivity boost: Use the 'two-minute rule' - if something takes less than two minutes, do it now.",
    "AI in art: AI-generated art has been sold at major auction houses.",
    "Focus hack: Use ambient noise or music to improve concentration and productivity.",
    "Fun fact: Some AI models can now generate realistic 3D models from text descriptions.",
    "Workflow efficiency: Use project management tools to keep track of tasks and deadlines.",
    "AI in space: NASA uses AI to help design spacecraft and plan missions.",
    "Time saver: Learn to touch type to significantly increase your typing speed and efficiency."
  ];

  useEffect(() => {
    const displayRandomTip = () => {
      setTip(tips[Math.floor(Math.random() * tips.length)]);
    };

    displayRandomTip();
    const interval = setInterval(displayRandomTip, 15000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="mt-4 text-center text-white italic flex items-center justify-center space-x-2">
      <HiOutlineLightBulb size={24} className="text-yellow-300" />
      <span>{tip}</span>
    </div>
  );
};

const LoadingOverlay = ({ currentToolName }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col items-center justify-center text-white">
      <div className="text-2xl mb-4">Running: {currentToolName}</div>
      <div className="relative w-32 h-32 drop-shadow-2xl">
        <svg 
          className="animate-spin w-full h-full" 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24"
        >
          <path 
            fill={customTheme.colors.secondary} 
            d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" 
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className={`w-16 h-16 bg-${customTheme.colors.primary} bg-opacity-20 rounded-full flex items-center justify-center`}>
            <div className={`w-12 h-12 bg-${customTheme.colors.accent} rounded-full animate-pulse`}></div>
          </div>
        </div>
      </div>
      <LoadingEntertainment />
    </div>
  );
};
const scrollToResults = () => {
  const resultsElement = document.getElementById('workflow-results');
  if (resultsElement) {
    const yOffset = -100; // Adjust this value to fine-tune the scroll position
    const y = resultsElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};


const markdownToHtml = (markdown) => {
  return ReactDOMServer.renderToString(
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: ({ node, ...props }) => <h1 style={{ fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' }} {...props} />,
        h2: ({ node, ...props }) => <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' }} {...props} />,
        h3: ({ node, ...props }) => <h3 style={{ fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' }} {...props} />,
        p: ({ node, ...props }) => <p style={{ margin: '1em 0' }} {...props} />,
        ul: ({ node, ...props }) => <ul style={{ paddingLeft: '2em', margin: '1em 0', listStyleType: 'disc' }} {...props} />,
        ol: ({ node, ...props }) => <ol style={{ paddingLeft: '2em', margin: '1em 0', listStyleType: 'decimal' }} {...props} />,
        li: ({ node, children, ...props }) => {
          if (node.children[0].type === 'paragraph') {
            // If the first child is a paragraph, render it inline
            return <li className="mb-1" {...props}>{children}</li>;
          }
          // Otherwise, use the default rendering
          return <li className="mb-1" {...props}>{children}</li>;
        },
        pre: ({ node, ...props }) => <pre style={{ backgroundColor: '#f4f4f4', padding: '1em', overflowX: 'auto', margin: '1em 0' }} {...props} />,
        code: ({ node, inline, ...props }) =>
          inline
            ? <code style={{ backgroundColor: '#f4f4f4', padding: '0.2em 0.4em', borderRadius: '3px' }} {...props} />
            : <code style={{ display: 'block', backgroundColor: '#f4f4f4', padding: '1em', overflowX: 'auto', margin: '1em 0' }} {...props} />,
        a: ({ node, ...props }) => <a style={{ color: '#0366d6', textDecoration: 'none' }} {...props} />,
        blockquote: ({ node, ...props }) => <blockquote style={{ borderLeft: '0.25em solid #dfe2e5', color: '#6a737d', padding: '0 1em', margin: '1em 0' }} {...props} />,
        table: ({ node, ...props }) => <table style={{ borderCollapse: 'collapse', margin: '1em 0' }} {...props} />,
        th: ({ node, ...props }) => <th style={{ border: '1px solid #dfe2e5', padding: '6px 13px' }} {...props} />,
        td: ({ node, ...props }) => <td style={{ border: '1px solid #dfe2e5', padding: '6px 13px' }} {...props} />,
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
};

const TutorialVideo = ({ title, videoId, description }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h4 className="text-lg font-semibold mb-2">{title}</h4>
    <div className="aspect-w-16 aspect-h-9 mb-2 justify-center items-center">
      <iframe
        src={videoId}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <p className="text-sm text-gray-600">{description}</p>
  </div>
);



const FloatingNavWidget = ({ results }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isWidgetVisible, setIsWidgetVisible] = useState(true);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const closeWidget = () => {
    setIsWidgetVisible(false);
  };

  if (!isWidgetVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4 bg-white p-4 rounded-lg shadow-lg max-w-xs overflow-y-auto max-h-60 hidden md:block">
      <div className="flex justify-between items-center mb-2">
        <h4 className="font-bold text-black">Results Navigation</h4>
        <button
          onClick={closeWidget}
          className="text-gray-500 hover:text-gray-700"
          aria-label="Close navigation widget"
        >
          <HiX size={20} />
        </button>
      </div>

      <ul className="mb-4">
        {results.map((result, index) => (
          <li key={index} className="mb-1 text-xs">
            <a
              href={`#workflow-result-${index}`}
              className="text-blue-600 hover:underline"
              onClick={(e) => {
                e.preventDefault();
                const element = document.getElementById(`workflow-result-${index}`);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
            >
              Result {index + 1}: {result.toolName}
            </a>
          </li>
        ))}
      </ul>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="w-full bg-blue-500 text-xs text-white p-2 rounded shadow-lg hover:bg-blue-600 transition-colors duration-300"
          aria-label="Back to top"
        >
          Back to Top
        </button>
      )}
    </div>
  );
};
const ContextSelector = ({ toolIndex, selectedContexts, onContextChange }) => {
  const { contexts, groups, associations } = useContexts();

  const handleContextChange = (contextId) => {
    const newSelectedContexts = selectedContexts.includes(contextId)
      ? selectedContexts.filter(id => id !== contextId)
      : [...selectedContexts, contextId];
    onContextChange(toolIndex, newSelectedContexts);
  };

  const ungroupedContexts = contexts.filter(context =>
    !associations.some(a => a.context_id === context.id)
  );

  return (
    <div className="mt-4">
      <Label className="mb-2 text-sm font-medium text-gray-700">Select Contexts</Label>
      <div className="space-y-2">
        {groups.map(group => (
          <Accordion collapseAll={true} key={group.id} className="text-sm">
            <Accordion.Panel>
              <Accordion.Title className="py-2 px-4 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200">
                {group.name}
              </Accordion.Title>
              <Accordion.Content>
                <div className="p-2 space-y-1">
                  {contexts
                    .filter(context => associations.some(a => a.group_id === group.id && a.context_id === context.id))
                    .map(context => (
                      <div key={context.id} className="flex items-center">
                        <input
                          type="checkbox"
                          id={`context-${context.id}`}
                          checked={selectedContexts.includes(context.id)}
                          onChange={() => handleContextChange(context.id)}
                          className="mr-2 rounded text-indigo-600 focus:ring-indigo-500"
                        />
                        <Label htmlFor={`context-${context.id}`} className="text-sm text-gray-700">{context.name}</Label>
                      </div>
                    ))}
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        ))}
        {ungroupedContexts.length > 0 && (
          <Accordion collapseAll={true} >
            <Accordion.Panel>
              <Accordion.Title className="py-2 px-4 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200">
                Ungrouped Contexts
              </Accordion.Title>
              <Accordion.Content>
                <div className="p-2 space-y-1">
                  {ungroupedContexts.map(context => (
                    <div key={context.id} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`context-${context.id}`}
                        checked={selectedContexts.includes(context.id)}
                        onChange={() => handleContextChange(context.id)}
                        className="mr-2 rounded text-indigo-600 focus:ring-indigo-500"
                      />
                      <Label htmlFor={`context-${context.id}`} className="text-sm text-gray-700">{context.name}</Label>
                    </div>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        )}
      </div>
    </div>
  );
};

const ToolsAddedPopup = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-lg p-4 z-50"
    >
      <div className="flex items-center">
        <HiOutlineCheckCircle className="text-green-500 text-2xl mr-2" />
        <p className="text-gray-800">Tools are added and ready to use.</p>
      </div>
      <p className="text-gray-600 mt-2">Review the settings and click on the execute button when ready.</p>
      <button
        onClick={onClose}
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
      >
        Got it
      </button>
    </motion.div>
  );
};

const NotesTakingArea = ({ notes, setNotes, onSave, setShowToast, setToastMessage }) => {
  const [selectedText, setSelectedText] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const quillRef = useRef(null);


  const handleTextSelection = useCallback(() => {
    if (quillRef.current) {
      const selection = quillRef.current.getEditor().getSelection();
      if (selection && selection.length > 0) {
        const text = quillRef.current.getEditor().getText(selection.index, selection.length);
        if (text.trim()) {
          setSelectedText(text);
          setShowMenu(true);
        } else {
          setSelectedText('');
          setShowMenu(false);
        }
      } else {
        setSelectedText('');
        setShowMenu(false);
      }
    }
  }, []);

  const logUsage = async (option, prompt, result) => {
    const userInfo = Cookies.get('user');
    const userEmail = userInfo ? JSON.parse(userInfo).email : 'unregistered@llmazing.online';

    const payload = {
      user_email: userEmail,
      prompt: prompt,
      usecase: `Notes - ${option}`,
      generated_texts: [['gpt-4o-mini', result]],
    };

    try {
      await axios.post(process.env.REACT_APP_INSERT_LOG_SESSION_URL, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error("Error logging usage:", error);
    }
  };

  const handleOptionSelect = async (option) => {
    if (option === 'Work breakdown' || option === 'Improve wording' || option === 'Explain' || option === 'Summarize' || option === 'Generate Questions') {
      let prompt;

      switch (option) {
        case 'Work breakdown':
          prompt = `Break down into subtasks the following work: ${selectedText}. Reply directly with the breakdown structure.`;
          break;
        case 'Improve wording':
          prompt = `Improve the following text to sound better while keeping the same style: ${selectedText}. Reply directly with the improved text.`;
          break;
        case 'Explain':
          prompt = `Explain briefly what is: ${selectedText}. Reply as the following example: ${selectedText} (explanation goes here)`;
          break;
        case 'Summarize':
          prompt = `Summarize: Condense the selected text into a brief summary: ${selectedText}. Summary: `;
          break;
        case 'Generate Questions':
          prompt = `Create relevant questions based on the selected content for further discussion or exploration. Content: ${selectedText}. Summary: `;
          break;
        default:
          prompt = '';
      }

      try {
        const apiResponse = await axios.post(
          process.env.REACT_APP_OPENAI_COMPLETIONS_ENDPOINT,
          {
            model: 'gpt-4o-mini',
            messages: [{ role: "user", content: prompt }],
          },
          {
            auth: {
              username: process.env.REACT_APP_API_USER,
              password: process.env.REACT_APP_API_PASS,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const apiResult = `${apiResponse.data[0].message.content}`;

        const editor = quillRef.current.getEditor();
        const selection = editor.getSelection();
        if (selection) {
          editor.deleteText(selection.index, selection.length);
          editor.insertText(selection.index, apiResult);
        }

        // Log the usage after successful API call and text insertion
        await logUsage(option, prompt, apiResult);

      } catch (error) {
        console.error("Error processing text selection:", error);
        // Optionally, you can show an error message to the user here
      }
    }
    setShowMenu(false);
  };

  const handleCopyToClipboard = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection();
      let html, text;

      if (selection) {
        const range = selection;
        html = editor.getContents(range.index, range.length).ops
          .map(op => quillRef.current.getEditor().clipboard.convert(op))
          .join('');
        text = editor.getText(range.index, range.length);
      } else {
        html = quillRef.current.getEditor().root.innerHTML;
        text = editor.getText();
      }

      const blob = new Blob([html], { type: 'text/html' });
      const plainTextBlob = new Blob([text], { type: 'text/plain' });

      const clipboardItem = new ClipboardItem({
        'text/html': blob,
        'text/plain': plainTextBlob
      });

      navigator.clipboard.write([clipboardItem]).then(() => {
        // Optionally, you can show a success message to the user here
      }).catch(err => {
        console.error('Failed to copy: ', err);
        // Optionally, you can show an error message to the user here
      });
    }
  };

  const handleSaveNotes = async () => {
    const userInfo = Cookies.get('user');
    const userEmail = userInfo ? JSON.parse(userInfo).email : 'unregistered@llmazing.online';

    const payload = {
      user_email: userEmail,
      prompt: "Meeting Notes",
      usecase: "Meeting Notes",
      generated_texts: [['user-generated', notes]],
    };

    try {
      await axios.post(process.env.REACT_APP_INSERT_LOG_SESSION_URL, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setShowToast(true);
      setToastMessage('Notes saved successfully to your LLMazing workspace');
    } catch (error) {
      console.error("Error saving notes:", error);
      setShowToast(true);
      setToastMessage('Failed to save notes to your LLMazing workspace');
    }
  };

  useEffect(() => {
    // Load saved notes from localStorage when component mounts
    const savedNotes = localStorage.getItem('llmazing_notes');
    if (savedNotes) {
      setNotes(savedNotes);
    }
  }, [setNotes]);

  const handleNotesChange = (value) => {
    setNotes(value);
    // Save notes to localStorage as they are typed
    localStorage.setItem('llmazing_notes', value);
  };

  return (
    <div className="flex flex-col h-full bg-white text-gray-800">
      <div className="flex-shrink-0 border-b border-gray-200">
        <div id="toolbar">
          <span className="ql-formats">
            <select className="ql-header" defaultValue="normal">
              <option value="normal">Normal</option>
              <option value="1">Heading 1</option>
              <option value="2">Heading 2</option>
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
          </span>
          <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
          </span>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <ReactQuill
          ref={quillRef}
          value={notes}
          onChange={handleNotesChange}
          onChangeSelection={handleTextSelection}
          modules={{
            toolbar: '#toolbar'
          }}
          className="h-full"
        />
      </div>
      <div className="flex-shrink-0 p-4 border-t border-gray-200 flex justify-center space-x-2">
        <Button
          onClick={() => onSave(notes)}
          className={`${customTheme.button.base} ${customTheme.button.primary}`}
        >
          <HiOutlineDocumentDownload className="mr-2" /> Save as Word
        </Button>
        <Button
          onClick={handleCopyToClipboard}
          className={`${customTheme.button.base} ${customTheme.button.secondary}`}
        >
          <HiOutlineClipboardCopy className="mr-2" /> Copy to Clipboard
        </Button>
        <Button
          onClick={handleSaveNotes}
          className={`${customTheme.button.base} ${customTheme.button.accent}`}
        >
          <HiOutlineSave className="mr-2" /> Save to your LLMazing workspace
        </Button>
      </div>
      {showMenu && (
        <TextSelectionMenu
          onOptionSelect={handleOptionSelect}
        />
      )}
    </div>
  );
};

const EmptyStateGuidance = () => {
  return (
    <div className="text-center py-12">
      <p className="text-xl mb-4">Start by adding a tool to your workflow by clicking the "Add Tool" button.</p>
      <p className="text-xl mb-4">Or open the smart notes by clicking the "Enter Meeting Mode" button.</p>
    </div>
  );
};

const Workflows = () => {
  const { tools: contextTools, loading: contextLoading } = useContext(ToolsContext);
  const { contexts, groups, associations, loading: contextsLoading, error: contextsError } = useContexts();
  const [workflowTools, setWorkflowTools] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTools, setFilteredTools] = useState([]);
  const [categories, setCategories] = useState([]);
  const [workflow, setWorkflow] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState(null);
  const [isToolModalOpen, setIsToolModalOpen] = useState(false);
  const [connections, setConnections] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const location = useLocation();
  const [stepResults, setStepResults] = useState({});
  const [models, setModels] = useState([]);
  const resultRefs = useRef([]);
  const userInfo = useCookie('user');
  const isLoggedIn = !!userInfo;
  const [hasAddedTools, setHasAddedTools] = useState(false);
  const workflowToolsRef = useRef(workflowTools);
  const [suggestedTools, setSuggestedTools] = useState([]);
  const [showInstructionalVideo, setShowInstructionalVideo] = useState(false);
  const logoUrl = `${window.location.origin}/images/logo-dots-no-back.png`;
  const coverLogoUrl = `${window.location.origin}/images/logo_word_cover.png`;
  const footerLogoUrl = `${window.location.origin}/images/logo_word_footer.png`;
  const progressBarRef = useRef(null);
  const [showEnhancedSetup, setShowEnhancedSetup] = useState(false);
  const [showToolsAddedPopup, setShowToolsAddedPopup] = useState(false);
  const [editedResults, setEditedResults] = useState({});
  const mermaidRef = useRef(null);
  const needsMermaidRender = useRef(false);
  const [query, setQuery] = useState('');
  const [responseContent, setResponseContent] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [isMeetingMode, setIsMeetingMode] = useState(false);
  const [notes, setNotes] = useState('');
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [validationIssues, setValidationIssues] = useState([]);
  const tutorialModalRef = useRef(null);
  const [hasTriggeredNPS, setHasTriggeredNPS] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { userStatus, hideOnboarding } = useContexts();
  const { hasSeenOnboarding, markOnboardingAsSeen } = useContexts();
  const [showOnboarding, setShowOnboarding] = useState(!hasSeenOnboarding);
  const [onboardingStep, setOnboardingStep] = useState(1);
  const [showGraphErrorToast, setShowGraphErrorToast] = useState(false);

  const [isStepRunning, setIsStepRunning] = useState(false);
  const [mermaidKey, setMermaidKey] = useState(0);
  const mermaidContainerRef = useRef(null);
  const [mermaidZoom, setMermaidZoom] = useState(1);

  const [regenerationAttempts, setRegenerationAttempts] = useState({});
  const [isRegenerating, setIsRegenerating] = useState(false);
  const maxRegenerationAttempts = 3;

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
      flowchart: { useMaxWidth: false, htmlLabels: true },
      mindmap: { useMaxWidth: false },
      gantt: { useMaxWidth: false },
      journey: { useMaxWidth: false },
    });
  }, []);

  useEffect(() => {
    const renderMermaidDiagrams = () => {
      mermaid.init(undefined, document.querySelectorAll('.mermaid'));
    };

    renderMermaidDiagrams();
    window.addEventListener('resize', renderMermaidDiagrams);

    return () => {
      window.removeEventListener('resize', renderMermaidDiagrams);
    };
  }, [stepResults, mermaidKey]);

  const handleZoomIn = () => {
    setMermaidZoom(prevZoom => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setMermaidZoom(prevZoom => Math.max(prevZoom - 0.1, 0.5));
  };



  const countTokens = (text) => {
    return Math.ceil(text.split(/\s+/).length * 1.3);
  };

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    markOnboardingAsSeen();
  };

  const moveToNextOnboardingStep = () => {
    if (onboardingStep < 3) {
      setOnboardingStep(onboardingStep + 1);
    } else {
      handleOnboardingClose();
    }
  };


  // useEffect(() => {
  //   if (userStatus === 'new' && !hideOnboarding) {
  //     setShowOnboarding(true);
  //   }
  // }, [userStatus, hideOnboarding]);

  const [userActivity, setUserActivity] = useState({
    savedAsWord: false,
    exportedMermaid: false,
    usedAISetup: false,
    // Add other relevant activity flags
  });


  const moveTool = (index, direction) => {
    const newTools = [...workflowTools];
    const newIndex = direction === 'up' ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < newTools.length) {
      [newTools[index], newTools[newIndex]] = [newTools[newIndex], newTools[index]];
      setWorkflowTools(newTools);

      // Update connections
      const updatedConnections = { ...connections };
      Object.keys(updatedConnections).forEach(key => {
        const [toolIndex, settingKey] = key.split('-');
        if (parseInt(toolIndex) === index) {
          updatedConnections[`${newIndex}-${settingKey}`] = updatedConnections[key];
          delete updatedConnections[key];
        } else if (parseInt(toolIndex) === newIndex) {
          updatedConnections[`${index}-${settingKey}`] = updatedConnections[key];
          delete updatedConnections[key];
        }
      });
      setConnections(updatedConnections);
    }
  };

  const moveToolUp = (index) => moveTool(index, 'up');
  const moveToolDown = (index) => moveTool(index, 'down');


  const renderMermaidDiagrams = () => {
    mermaid.init(undefined, document.querySelectorAll('.mermaid'));
  };

  const checkMermaidConsistency = async (mermaidCode) => {
    try {
      await mermaid.parse(mermaidCode);
      return mermaidCode;
    } catch (error) {
      console.error("Mermaid parsing error:", error);
      return "graph TD\nA[Failed to generate valid diagram]";
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        mermaid.init(undefined, document.querySelectorAll('.mermaid'));
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!hasTriggeredNPS && Object.keys(stepResults).length > 0) {
      // console.log("Checking NPS triggers after workflow completion");
      const timer = setTimeout(() => {
        checkNPSTriggers();
      }, 20000); // Wait for 20 seconds after results are displayed
      return () => clearTimeout(timer);
    }
  }, [stepResults, hasTriggeredNPS]);

  const checkNPSTriggers = () => {
    // console.log("Checking NPS triggers, current userActivity:", userActivity);
    if (shouldTriggerNPS()) {
      // console.log("NPS trigger conditions met");
      setHasTriggeredNPS(true);
      const event = determineTriggerEvent();
      // console.log("NPS trigger event:", event);
      setUserActivity(prev => ({ ...prev, npsTriggerEvent: event }));
    }
  };

  const shouldTriggerNPS = () => {
    const aiSetupTrigger = userActivity.usedAISetup && workflowTools.length >= 3;
    const longWorkflowTrigger = !userActivity.usedAISetup && workflowTools.length >= 5;
    const workflowCompleted = Object.keys(stepResults).length === workflowTools.length;

    // console.log("NPS Trigger Conditions:", {
    //   aiSetupTrigger,
    //   longWorkflowTrigger,
    //   workflowCompleted,
    //   savedAsWord: userActivity.savedAsWord,
    //   workflowToolsLength: workflowTools.length,
    //   stepResultsLength: Object.keys(stepResults).length,
    //   usedAISetup: userActivity.usedAISetup
    // });

    return (aiSetupTrigger || longWorkflowTrigger || userActivity.savedAsWord) && workflowCompleted;
  };


  const determineTriggerEvent = () => {
    if (userActivity.savedAsWord) return 'save_as_word';
    if (userActivity.usedAISetup) return 'ai_setup_completion';
    return 'long_workflow_completion';
  };

  useEffect(() => {
    // console.log("userActivity updated:", userActivity);
  }, [userActivity]);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
      flowchart: { useMaxWidth: false, htmlLabels: true },
      mindmap: { useMaxWidth: false },
      gantt: { useMaxWidth: false },
      journey: { useMaxWidth: false },
    });
  }, []);

  useEffect(() => {
    if (Object.keys(stepResults).length > 0) {
      setTimeout(() => {
        mermaid.init(undefined, document.querySelectorAll('.mermaid'));
      }, 100);
    }
  }, [stepResults]);



  useEffect(() => {
    let timer;
    if (showToast) {
      timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showToast]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.state?.workflow || location.state?.selectedTool || location.state?.suggestedTools) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (mermaidRef.current) {
      mermaid.contentLoaded();
    }
  }, [stepResults]);



  useEffect(() => {
    // console.log("Location state:", location.state);

    if (location.state?.workflow) {
      const steps = location.state.workflow.configuration?.steps || [];
      setWorkflowTools(steps.map(step => step.tool));
    } else if (location.state?.createNew && location.state?.suggestedTools) {
      // console.log("Setting suggested tools:", location.state.suggestedTools);
      if (location.state.suggestedTools.length === 0) {
        // console.warn("No suggested tools received");
        return;
      }
      const preparedTools = location.state.suggestedTools.map(tool => ({
        ...tool,
        settings: Object.keys(tool.settings || {}).reduce((acc, key) => {
          acc[key] = { ...tool.settings[key], value: tool.settings[key]?.default || '' };
          return acc;
        }, {})
      }));
      // console.log("Prepared tools:", preparedTools);
      setWorkflowTools(preparedTools);
      setSuggestedTools(location.state.suggestedTools.map(tool => tool.name));
    } else {
      // console.log("No workflow or tools in location state");
    }
  }, [location.state]);


  useEffect(() => {
    if (!contextLoading) {
      const sortedTools = contextTools
        .filter(tool =>
          (tool.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (tool.description && tool.description.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      setFilteredTools(sortedTools);

      const categoriesSet = new Set(contextTools.map(tool => tool.category));
      const sortedCategories = Array.from(categoriesSet).sort();
      setCategories(sortedCategories);
    }
  }, [searchTerm, contextTools, contextLoading]);

  useEffect(() => {
    if (showInstructionalVideo && tutorialModalRef.current) {
      tutorialModalRef.current.scrollTop = 0;
    }
  }, [showInstructionalVideo]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_CHAT_TAGS_URI, {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PASS,
          },
        });
        const fetchedModels = response.data.models.map(model => model.name);
        // Add OpenAI model
        fetchedModels.push('openai/gpt-4o-mini');
        setModels(fetchedModels);
      } catch (error) {
        // console.error("Error fetching models:", error);
      }
    };
    fetchModels();
  }, []);

  useEffect(() => {
    workflowToolsRef.current = workflowTools;
  }, [workflowTools]);

  const handleAddSuggestedTools = useCallback(() => {
    if (hasAddedTools) return;

    // console.log("Adding suggested tools:", suggestedTools);

    if (suggestedTools.length === 0) {
      // console.warn("No suggested tools available");
      alert("No suggested tools available. Please go back and try again.");
      return;
    }

    setWorkflowTools(prevTools => {
      const newTools = suggestedTools
        .map(toolName => contextTools.find(tool => tool.name === toolName))
        .filter(Boolean)
        .filter(suggestedTool => !prevTools.some(prevTool => prevTool.name === suggestedTool.name));

      const updatedTools = [...prevTools, ...newTools];
      // console.log("Updated tools:", updatedTools);
      setHasAddedTools(true);
      setToastMessage(`Added ${newTools.length} new tool(s) to workflow`);
      setShowToast(true);
      return updatedTools;
    });
  }, [suggestedTools, contextTools, setToastMessage, setShowToast]);

  useEffect(() => {
    if (mermaidRef.current) {
      mermaid.contentLoaded();
    }
  }, [stepResults]);

  useEffect(() => {
    if (Object.keys(stepResults).length > 0) {
      setTimeout(() => {
        renderMermaidDiagrams();
      }, 100);
    }
  }, [stepResults]);



  // Reset hasAddedTools when responseContent changes
  useEffect(() => {
    setHasAddedTools(false);
  }, [responseContent]);

  const logUsage = async (sessionState) => {
    const payload = {
      user_email: sessionState.userEmail,
      prompt: sessionState.finalPrompt,
      usecase: sessionState.toolName,
      generated_texts: [[sessionState.model, sessionState.generatedText]],
    };

    try {
      await axios.post(process.env.REACT_APP_INSERT_LOG_SESSION_URL, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error("Error logging usage:", error);
    }
  };

  const handleReset = () => {
    setWorkflowTools([]);
    setStepResults({});
    setWorkflow(null);
    setError(null);
    setCurrentStep(0);
    // Clear any other relevant state
  };

  const handleContentChange = (stepIndex, newContent) => {
    // console.log(`Content changed for step ${stepIndex}:`, newContent);
    setEditedResults(prev => {
      const updated = {
        ...prev,
        [stepIndex]: newContent
      };
      // console.log('Updated editedResults:', updated);
      return updated;
    });
  };

  const renderResult = (result, toolTemplate, stepIndex) => {
    const disclaimer = (
      <div className="mt-4 text-sm text-gray-500 italic border-t border-gray-200 pt-2">
        <p>
          Note: This content is AI-generated and reviewed by the LLMazing team. While we strive for accuracy,
          we recommend verifying important information before use.
        </p>
      </div>
    );

    let content;
    const isMermaid = toolTemplate === 'graph_creator';

    if (isMermaid) {
      const mermaidContent = result.replace(/^```mermaid\n|```$/gm, '').trim();
      content = (
        <div className="relative">
          <div
            className={`${customTheme.mermaidWrapper.base} mermaid-container`}
            key={`mermaid-${stepIndex}-${mermaidKey}`}
            ref={mermaidContainerRef}
            style={{ transform: `scale(${mermaidZoom})`, transformOrigin: 'top left' }}
          >
            <pre className="mermaid">
              {mermaidContent}
            </pre>
          </div>
          <div className="absolute top-2 right-2 flex space-x-2">
            <button onClick={handleZoomIn} className="p-1 bg-gray-200 rounded">
              <HiZoomIn />
            </button>
            <button onClick={handleZoomOut} className="p-1 bg-gray-200 rounded">
              <HiZoomOut />
            </button>
          </div>
          {regenerationAttempts[stepIndex] >= maxRegenerationAttempts && (
            <div className="mt-2 text-red-500">
              Max regeneration attempts reached. The graph may not be accurate.
            </div>
          )}
        </div>
      );
    } else {
      content = (
        <EditableResult
          initialContent={result}
          onContentChange={(newContent) => handleContentChange(stepIndex, newContent)}
        />
      );
    }

    return (
      <div className="relative">
        {!isLoggedIn && (
          <div className="bg-gradient-to-b from-purple-900 to-transparent bg-opacity-90 p-4 mb-4 rounded-t-lg">
            <div className="flex flex-col items-center justify-center">
              <p className="text-white text-xl mb-4">Sign up to see the full result!</p>
              <Link to="/register">
                <Button className="px-6 py-3 text-lg font-semibold bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                  <HiLightningBolt className="mr-2" />
                  Sign Up to Unleash Your Productivity
                </Button>
              </Link>
            </div>
          </div>
        )}
        <div className={`${isLoggedIn ? '' : 'blur-sm'}`}>
          {content}
        </div>
        {isLoggedIn && disclaimer}
      </div>
    );
  };


  const addTool = (tool) => {
    setWorkflowTools([...workflowTools, {
      ...tool,
      settings: {
        ...tool.settings,
        model: {
          type: 'select',
          description: 'Select Model',
          options: models,
          value: tool.model || models[0]
        }
      }
    }]);
    setIsToolModalOpen(false);
    setToastMessage(`${tool.name} added to workflow`);
    setShowToast(true);
  };

  const removeTool = (index) => {
    setWorkflowTools(workflowTools.filter((_, i) => i !== index));
    setConnections(prev => {
      const newConnections = { ...prev };
      Object.keys(newConnections).forEach(key => {
        if (key.startsWith(`${index}-`) || newConnections[key].startsWith(`${index}-`)) {
          delete newConnections[key];
        }
      });
      return newConnections;
    });
    setShowToast(true);
    setToastMessage("Tool removed from workflow");
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(workflowTools);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setWorkflowTools(items);
  };

  const handleSettingChange = (toolIndex, key, value) => {
    setWorkflowTools(prevTools =>
      prevTools.map((tool, index) =>
        index === toolIndex
          ? { ...tool, settings: { ...tool.settings, [key]: { ...tool.settings[key], value } } }
          : tool
      )
    );
  };

  const handleConnectionChange = (toolIndex, key, value) => {
    setConnections(prev => ({
      ...prev,
      [`${toolIndex}-${key}`]: value
    }));
  };

  const validateWorkflow = () => {
    const issues = [];
    // console.log('Validating workflow:', workflowTools);
    // console.log('Current connections:', connections);

    workflowTools.forEach((tool, index) => {
      // console.log(`Validating tool ${index}:`, tool);

      if (!tool || typeof tool !== 'object') {
        issues.push(`Tool ${index + 1} is invalid or undefined.`);
        return;
      }

      const hasInputs = Object.entries(tool.settings).some(([key, setting]) =>
        key !== 'model' &&
        setting &&
        setting.value !== '' &&
        setting.value !== undefined
      );

      const isChained = Object.keys(connections).some(key => {
        const [toolIndex, settingKey] = key.split('-');
        return parseInt(toolIndex) === index && connections[key] !== 'Input';
      });

      // console.log(`Tool ${index}: Has inputs: ${hasInputs}, Is chained: ${isChained}`);

      if (index === 0 && !hasInputs) {
        issues.push(`The first tool (${tool.name || 'Unnamed tool'}) must have at least one input.`);
      } else if (index > 0 && !hasInputs && !isChained) {
        issues.push(`Tool ${index + 1} (${tool.name || 'Unnamed tool'}) has no inputs and is not chained to any previous tool.`);
      }
    });

    // console.log('Validation issues:', issues);
    return issues;
  };

  const executeFlow = () => {
    const validationIssues = validateWorkflow();

    if (validationIssues.length > 0) {
      // console.log('Validation failed. Issues:', validationIssues);
      setIsValidationModalOpen(true);
      setValidationIssues(validationIssues);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      setTimeout(() => {

        setLoading(true);
        setError(null);
        setCurrentStep(0);
        setStepResults({});
        const workflowWithInputs = {
          steps: workflowTools.map((tool, index) => ({
            tool: {
              name: tool.name,
              description: tool.description,
              enriched_instructions: tool.enriched_instructions,
              model: tool.settings.model.value,
              examples: tool.examples,
              template: tool.template
            },
            settings: Object.entries(tool.settings).reduce((acc, [key, setting]) => {
              if (key !== 'model') {
                const connectionValue = connections[`${index}-${key}`];
                if (connectionValue && connectionValue !== 'Input') {
                  const [prevStepIndex, outputKey] = connectionValue.split('-');
                  acc[key] = { type: 'reference', value: `${prevStepIndex}-${outputKey}` };
                } else if (setting.value !== '' && setting.value !== undefined) {
                  acc[key] = { type: 'value', value: setting.value };
                }
              }
              return acc;
            }, {})
          }))
        };
        setWorkflow(workflowWithInputs);
        executeWorkflow(workflowWithInputs);
      }, 300);
    }
  };




  const executeWorkflow = async (workflow) => {
    try {
      let currentResults = {};
      for (let i = 0; i < workflow.steps.length; i++) {
        setCurrentStep(i);
        setIsStepRunning(true);
        const result = await executeStep(i, currentResults, workflow);
        currentResults = { ...currentResults, [i]: result };
        setStepResults(currentResults);
        setIsStepRunning(false);
      }
      setResults(Object.values(currentResults));
      scrollToResults();
    } catch (error) {
      console.error("Workflow execution error:", error);
      setError(`Error executing workflow: ${error.message}`);
    } finally {
      setLoading(false);
      setIsStepRunning(false);
    }
  };


  const executeStep = async (stepIndex, currentResults, workflow) => {
    // console.log(`Starting execution of step ${stepIndex}:`, workflow.steps[stepIndex]);
    const step = workflow.steps[stepIndex];
    try {
      if (!step || !step.tool || !step.tool.name) {
        throw new Error(`Invalid step configuration for step ${stepIndex}`);
      }
      const toolContext = { ...step.tool, ...contextTools.find(ct => ct.name === step.tool.name) };
      if (!toolContext) {
        throw new Error(`Tool ${step.tool.name} not found in context`);
      }

      const resolvedSettings = await resolveSettings(step.settings, currentResults);
      // console.log('Resolved settings:', resolvedSettings);

      const selectedModel = resolvedSettings.model || toolContext.model;
      if (!selectedModel) {
        throw new Error(`Model for tool ${step.tool.name} is not defined`);
      }
      // console.log('Selected model:', selectedModel);

      // Separate additionalContext (if it exists) from other inputs
      const { additionalContext, ...otherInputs } = resolvedSettings;
      delete otherInputs.model; // Remove model from otherInputs

      // Get selected contexts for this step
      const selectedContextIds = toolContexts[stepIndex] || [];
      const selectedContexts = contexts.filter(context => selectedContextIds.includes(context.id));
      const contextContent = selectedContexts.map(context => context.content).join('\n\n');

      // Combine additionalContext (if it exists) and contextContent for summarization
      let contentToSummarize = contextContent;
      if (additionalContext) {
        contentToSummarize = `${additionalContext}\n\nContext:\n${contextContent}`;
      }

      const tokenCount = countTokens(contentToSummarize);
      console.log('Token count for content to summarize:', tokenCount);

      let summarizedContent = null;
      if (tokenCount > 1500) {
        console.log('Content exceeds 1000 tokens. Initiating summarization...');
        try {
          const response = await axios.post(
            process.env.REACT_APP_LONG_PROMPT_CHUNKING,
            { text: contentToSummarize },
            {
              auth: {
                username: process.env.REACT_APP_API_USER,
                password: process.env.REACT_APP_API_PASS,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          // console.log('Summarization API response:', response.data);

          if (response.data && response.data.content && Array.isArray(response.data.content)) {
            summarizedContent = response.data.content.join('\n\n');
            const summarizedtokenCount = countTokens(summarizedContent);
            console.log('Token count for summarized content:', summarizedtokenCount);
            // console.log('Summarized content:', summarizedContent);
          } else {
            console.error("Unexpected summarization API response format:", response.data);
            summarizedContent = contentToSummarize;
          }
        } catch (error) {
          console.error("Error during summarization:", error);
          summarizedContent = contentToSummarize;
        }
      } else {
        summarizedContent = contentToSummarize;
      }

      // Generate the prompt using generatePrompt
      const prompt = generatePrompt(toolContext, {
        ...otherInputs,
        taskContext: summarizedContent
      }, ''); // Empty string for contextContent as it's already in summarizedContent

      // console.log('Generated prompt:', prompt);

      const result = await executeTool(toolContext, resolvedSettings, prompt, selectedModel);
      // console.log('Execution result:', result);

      if (toolContext.template === 'graph_creator') {
        const mermaidContent = result.replace(/^```mermaid\n|```$/gm, '').trim();
        const validMermaidCode = await checkMermaidConsistency(mermaidContent);
        return `\`\`\`mermaid\n${validMermaidCode}\n\`\`\``;
      }

      return result;
    } catch (error) {
      console.error(`Error in step ${stepIndex}:`, error);
      throw new Error(`Error in step ${stepIndex + 1}: ${error.message}`);
    }
  };

  const resolveSettings = async (settings, previousResults) => {
    const resolvedSettings = {};
    for (const [key, setting] of Object.entries(settings)) {
      if (setting.type === 'reference') {
        const [prevStepIndex, outputKey] = setting.value.split('-');
        resolvedSettings[key] = previousResults[prevStepIndex];
        // console.log(`Resolved reference for ${key}:`, resolvedSettings[key]);
      } else {
        resolvedSettings[key] = setting.value;
      }
    }
    // console.log("Resolved settings:", resolvedSettings);
    return resolvedSettings;
  };

  const executeTool = async (toolContext, settings, prompt, selectedModel) => {
    // console.log('executeTool - Received prompt:', prompt);

    const userInfo = Cookies.get('user');
    const userEmail = userInfo ? JSON.parse(userInfo).email : 'unregistered@llmazing.online';
    const numCopies = settings.numCopies || 1;
    let selectedModels = [selectedModel];

    if (numCopies > 1) {
      selectedModels = [selectedModel, ...getRandomModels(selectedModel, numCopies - 1)];
    }

    const apiResponses = [];

    for (const model of selectedModels) {
      if (!model) {
        // console.error('Model is undefined, skipping this iteration');
        continue;
      }

      // console.log(`Sending prompt to model ${model}:`, prompt);

      try {
        let apiResponse;
        let content;

        if (model.startsWith('openai/')) {
          apiResponse = await axios.post(
            process.env.REACT_APP_OPENAI_COMPLETIONS_ENDPOINT,
            {
              model: model.replace('openai/', ''),
              messages: [{ role: "user", content: prompt }],
            },
            {
              auth: {
                username: process.env.REACT_APP_API_USER,
                password: process.env.REACT_APP_API_PASS,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          content = apiResponse.data[0].message.content;
        } else {
          const payload_ollama = {
            model: model,
            toolName: toolContext.name,
            messages: { role: "user", content: prompt },
            stream: false,
            user_email: userEmail
          };

          apiResponse = await axios.post(
            process.env.REACT_APP_CHAT_EVAL_PROMPT_URI,
            payload_ollama,
            {
              auth: {
                username: process.env.REACT_APP_API_USER,
                password: process.env.REACT_APP_API_PASS,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          content = apiResponse.data.message.content;
        }

        apiResponses.push({ content, model });

        // Log usage for all models
        const sessionState = {
          userEmail: userEmail,
          finalPrompt: prompt,
          toolName: toolContext.name,
          model: model,
          generatedText: content
        };
        await logUsage(sessionState);

      } catch (modelError) {
        // console.error(`Error with model ${model}:`, modelError);
        if (numCopies === 1) {
          throw modelError;
        }
      }
    }

    if (apiResponses.length === 0) {
      throw new Error('No successful API responses');
    }

    return apiResponses.map(response => response.content).join('\n\n---\n\n');
  };

  const generatePrompt = (toolContext, settings, contextContent) => {
    const timestamp = new Date().toISOString();
    let prompt = `[Prompt generated at ${timestamp}]\n`;
    prompt += toolContext.enriched_instructions || "Please provide detailed instructions.";

    if (contextContent) {
      prompt += `\n\nContext:\n${contextContent}`;
    }

    Object.entries(settings).forEach(([key, value]) => {
      if (key !== 'model') {  // Exclude the 'model' field
        prompt += `\n${key}: ${value || 'Not provided'}`;
      }
    });

    // console.log(`Generated prompt at ${timestamp}:`, prompt);
    return prompt;
  };





  const getRandomModels = (currentModel, numModels) => {
    const availableModels = models.filter(model => model !== currentModel);
    return availableModels.sort(() => 0.5 - Math.random()).slice(0, numModels);
  };

  const regenerateStep = async (stepIndex) => {
    if (loading || isRegenerating) return;
    setIsRegenerating(true);
    setError(null);
  
    try {
      let currentResults = { ...stepResults };
      setCurrentStep(stepIndex);
      
      // Check if the current step is a Mermaid graph
      if (workflow.steps[stepIndex].tool.template === 'graph_creator') {
        console.log("Skipping regeneration for Mermaid graph");
        setIsRegenerating(false);
        return;
      }
  
      const result = await executeStep(stepIndex, currentResults, workflow);
      currentResults = { ...currentResults, [stepIndex]: result };
      setStepResults(currentResults);
      setResults(Object.values(currentResults));
  
      setMermaidKey(prevKey => prevKey + 1);
    } catch (error) {
      setError(`Error regenerating workflow: ${error.message}`);
    } finally {
      setIsRegenerating(false);
      setTimeout(() => {
        mermaid.init(undefined, document.querySelectorAll('.mermaid'));
      }, 100);
    }
  };



  const handleCopyToClipboard = (result, stepIndex) => {
    const contentToCopy = editedResults[stepIndex] || result;

    navigator.clipboard.writeText(contentToCopy)
      .then(() => {
        setShowToast(true);
        setToastMessage('Content copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
        setShowToast(true);
        setToastMessage('Failed to copy content');
      });
  };

  const handleSaveAsPowerPoint = async (result, stepIndex) => {
    setIsGenerating(true);
    try {
      const jsonOutline = await convertOutlineToJson(result);
      console.log('Parsed JSON Outline:', JSON.stringify(jsonOutline, null, 2));
      await generatePowerPoint(jsonOutline);
      setShowToast(true);
      setToastMessage('PowerPoint presentation saved successfully');
    } catch (error) {
      console.error('Error saving PowerPoint:', error);
      console.error('Error stack:', error.stack);
      console.error('JSON Outline:', JSON.stringify(result, null, 2));
      setShowToast(true);
      setToastMessage(`Failed to save PowerPoint presentation: ${error.message}`);
    } finally {
      setIsGenerating(false);
    }
  };



  const convertOutlineToJson = async (outline) => {
    const makeRequest = async () => {

      const requestBody = {
        model: 'gpt-4o-mini',
        response_format: { type: "json_object" },
        messages: [
          {
            role: "user", content: `Convert this presentation outline to JSON format. Each key should be a slide number, and include:
        - type: ('title', 'section', or 'main_content')
        - title: The slide title (header)
        - subtitle: The slide subtitle (subheader), if applicable (for title and section slides)
        - content: An array of content items for main_content slides
        - icon: Suggest a relevant icon name from this expanded list: ['BarChart', 'Lightbulb', 'Users', 'Info', 'FileText', 'ArrowRight', 'ArrowLeft', 'Plus', 'Minus', 'RefreshCw', 'PieChart', 'LineChart', 'Presentation', 'Image', 'LayoutGrid', 'List', 'Check', 'X', 'Settings', 'Download']
        - Respect the number of slides provided and re-create them in the json format without changing texts or number of slides.
        
        The first slide should always be of type 'title'.
        Use 'section' type for major section breaks or chapter introductions.
        Use 'main_content' type for slides with bullet points or detailed information.

        Respond ONLY with the JSON, no additional text.

        Example answer:
        {
          "1": {
            type: "TITLE_SLIDE",
            title: "Presentation Title",
            content: ["Subtitle or additional content"],
            icon: "ChartBar"
          },
          "2": {
            type: "SECTION_SLIDE",
            title: "Section Title",
            content: ["Section content"],
            icon: "Lightbulb"
          },
          "3": {
            type: "MAIN_CONTENT_SLIDE",
            title: "Slide Title",
            content: ["Bullet point 1", "Bullet point 2", "Bullet point 3"],
            icon: "Users"
          }
          // ... more slides
        }

        Here's the outline and respect the number of slides provided in the outline! Generate the same number of slides!:
        ${outline}
      
        ` }
        ],
      };

      // console.log('OpenAI API Request:', JSON.stringify(requestBody, null, 2));

      try {
        const response = await axios.post(
          process.env.REACT_APP_OPENAI_COMPLETIONS_ENDPOINT,
          requestBody,
          {
            auth: {
              username: process.env.REACT_APP_API_USER,
              password: process.env.REACT_APP_API_PASS,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const content = response.data[0].message.content;
        try {
          const jsonOutline = JSON.parse(content);
          // Validate the structure of the JSON
          if (!jsonOutline || typeof jsonOutline !== 'object') {
            throw new Error('Invalid JSON structure');
          }
          return jsonOutline;
        } catch (parseError) {
          console.error('JSON Parse Error:', parseError);
          throw new Error('Invalid JSON response from OpenAI API');
        }
      } catch (error) {
        console.error('OpenAI API Error:', error.response ? error.response.data : error.message);
        throw error;
      }
    };

    for (let attempt = 1; attempt <= 3; attempt++) {
      try {
        const jsonOutline = await makeRequest();
        return jsonOutline;
      } catch (error) {
        console.error(`Attempt ${attempt} failed:`, error);
        if (attempt === 3) {
          throw new Error('Failed to generate valid JSON after 3 attempts');
        }
      }
    }
  };


  const ensureArray = (content) => {
    if (Array.isArray(content)) return content;
    if (typeof content === 'string') return [content];
    return [];
  };

  const defineTemplateMasters = (pptx) => {
    // Define title slide master
    pptx.defineSlideMaster({
      title: 'TITLE_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        { placeholder: { type: 'title', x: 0.5, y: 1, w: '95%', h: 1.5, align: 'center' } },
        { placeholder: { type: 'subtitle', x: 0.5, y: 3, w: '95%', h: 5, align: 'center' } }
      ]
    });

    // Define section slide master
    pptx.defineSlideMaster({
      title: 'SECTION_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        { placeholder: { type: 'title', x: 0.5, y: 1, w: '95%', h: 1.5, align: 'left' } },
        { placeholder: { type: 'body', x: 0.5, y: 3, w: '95%', h: 5, align: 'left' } }
      ]
    });

    // Define main content slide master
    pptx.defineSlideMaster({
      title: 'MAIN_CONTENT_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        { placeholder: { type: 'title', x: 0.5, y: 0.5, w: '95%', h: 1, align: 'left' } },
        { placeholder: { type: 'subtitle', x: 0.5, y: 1, w: '95%', h: 1, align: 'left' } },
        { placeholder: { type: 'body', x: 0.5, y: 2, w: '95%', h: 5, align: 'left' } }
      ]
    });
  };

  const calculateFontSize = (text, baseSize, minSize) => {
    const length = text.length;
    if (length > 200) return Math.max(baseSize - 4, minSize);
    if (length > 150) return Math.max(baseSize - 2, minSize);
    return baseSize;
  };


  const generatePowerPoint = async (jsonOutline) => {
    const pptx = new pptxgen();

    // Use colors from customTheme
    const BRAND_COLORS = {
      background: customTheme.colors.background,
      accent: customTheme.colors.accent,
      text: customTheme.colors.text,
      darkBlue: customTheme.colors.darkblue
    };

    // Define logo details
    const LOGO = {
      path: `${process.env.PUBLIC_URL}/logo/Logo Files/For Web/png/Black logo - no background.png`,
      width: 1,
      height: 0.3
    };

    const LOGO_COLOR = {
      path: `${process.env.PUBLIC_URL}/logo/Logo Files/For Web/png/Color logo - no background.png`,
      width: 1,
      height: 0.3
    };

    const ANDROID_LOGO = {
      path: `${process.env.PUBLIC_URL}/logo/Logo Files/For Web/Favicons/Android.png`,
    };

    const iconComponents = {
      BarChart,
      Lightbulb,
      Users,
      Info,
      FileText,
      ArrowRight,
      ArrowLeft,
      Plus,
      Minus,
      RefreshCw,
      PieChart,
      LineChart,
      Presentation,
      Image,
      LayoutGrid,
      List,
      Check,
      X,
      Settings,
      Download
    };




    const addTextSafely = (slide, text, options) => {
      if (typeof text === 'string') {
        slide.addText(text, options);
      } else if (Array.isArray(text)) {
        text.forEach((item, index) => {
          slide.addText(item, { ...options, y: options.y + index * 0.5 });
        });
      } else {
        console.warn(`Skipping invalid text content: ${JSON.stringify(text)}`);
      }
    };

    const addFooter = (slide, slideNumber) => {
      slide.addShape(pptx.shapes.RECTANGLE, {
        x: 0, y: '90%', w: '100%', h: '10%',
        fill: { color: BRAND_COLORS.darkBlue }
      });

      slide.addText(slideNumber.toString(), {
        x: 0.5, y: '92%', w: 1, h: 0.3,
        color: 'FFFFFF', fontSize: 12, bold: true
      });

      slide.addImage({
        path: LOGO_COLOR.path,
        x: '85%', y: '92%', w: LOGO.width, h: LOGO.height
      });
    };

    const addLogo = (slide) => {
      slide.addImage({
        path: LOGO.path,
        x: '85%', y: '5%', w: LOGO.width, h: LOGO.height
      });
    };

    const addIcon = (slide, iconName, x, y, size) => {
      const IconComponent = iconComponents[iconName];
      if (IconComponent) {
        const svgString = ReactDOMServer.renderToString(
          <IconComponent color={BRAND_COLORS.accent} size={size} />
        );
        slide.addImage({
          data: `data:image/svg+xml;base64,${btoa(svgString)}`,
          x: x,
          y: y,
          w: size / 72, // Convert pixels to inches
          h: size / 72
        });
      } else {
        console.warn(`Icon ${iconName} not found. Using default icon.`);
        // Use a default icon if the specified one is not found
        const DefaultIcon = iconComponents.InfoCircle;
        const svgString = ReactDOMServer.renderToString(
          <DefaultIcon color={BRAND_COLORS.accent} size={size} />
        );
        slide.addImage({
          data: `data:image/svg+xml;base64,${btoa(svgString)}`,
          x: x,
          y: y,
          w: size / 72,
          h: size / 72
        });
      }
    };

    const splitContent = (content, title) => {
      if (Array.isArray(content) && content.length > 5) {
        const firstSlide = content.slice(0, 5);
        const secondSlide = content.slice(5);
        return [
          { title: `${title} (1/2)`, content: firstSlide },
          { title: `${title} (2/2)`, content: secondSlide }
        ];
      }
      return [{ title, content }];
    };

    try {
      let slideNumber = 1;
      for (const [index, slideData] of Object.entries(jsonOutline)) {
        if (typeof slideData !== 'object' || slideData === null) {
          console.warn(`Invalid slide data for slide ${index}. Skipping.`);
          continue;
        }

        const { type, title, subtitle, content, icon } = slideData;

        if (index === '1') {
          // Title slide
          let slide = pptx.addSlide();
          slide.background = { color: BRAND_COLORS.background };


          addLogo(slide);

          // Add Android logo as background
          slide.addImage({
            path: ANDROID_LOGO.path,
            x: '50%', y: 1.5, w: 4, h: 4,
            sizing: { type: 'contain', w: '50%', h: '50%' },
            align: 'center', valign: 'top'
          });

          addTextSafely(slide, title, {
            x: 0.5, y: 1.5, w: '50%', h: 1,
            fontSize: 32, color: BRAND_COLORS.text, bold: true, align: 'left'
          });
          if (subtitle) {
            addTextSafely(slide, subtitle, {
              x: 0.5, y: 3, w: '50%', h: 0.75,
              fontSize: 20, color: BRAND_COLORS.accent, align: 'left'
            });
          }
          addTextSafely(slide, 'Generated and powered by LLMazing.online - Your Productivity Partner!', {
            x: 0.5, y: 5, w: '95%', h: 0.3,
            fontSize: 10, color: BRAND_COLORS.text, align: 'left'
          });
        } else {
          // Content slides
          const splitSlides = splitContent(content, title);
          for (const [subIndex, subSlide] of splitSlides.entries()) {
            let slide = pptx.addSlide();
            slide.background = { color: BRAND_COLORS.background };


            addTextSafely(slide, subSlide.title, {
              x: 0.5, y: 0.5, w: '95%', h: 0.75,
              fontSize: 24, color: BRAND_COLORS.text, bold: true, align: 'left'
            });
            if (subtitle && subIndex === 0) {
              addTextSafely(slide, subtitle, {
                x: 0.5, y: 1.1, w: '95%', h: 0.5,
                fontSize: 18, color: BRAND_COLORS.accent, align: 'left'
              });
            }
            if (subSlide.content) {
              if (Array.isArray(subSlide.content)) {
                const fontSize = calculateFontSize(subSlide.content.join(' '), 18, 12);
                subSlide.content.forEach((item, idx) => {
                  addTextSafely(slide, item, {
                    x: 0.5, y: 1.8 + idx * 0.5, w: '55%', h: 0.5,
                    fontSize: fontSize, color: BRAND_COLORS.text,
                    bullet: true
                  });
                });
              } else {
                const fontSize = calculateFontSize(subSlide.content, 18, 12);
                addTextSafely(slide, subSlide.content, {
                  x: 0.5, y: 1.8, w: '55%', h: 2,
                  fontSize: fontSize, color: BRAND_COLORS.text
                });
              }
            }
            addIcon(slide, icon || 'Info', 6, 1.5, 216);
            addFooter(slide, slideNumber.toString());
            slideNumber++;
          }
        }
      }

      await pptx.writeFile({ fileName: 'LLMazing_Presentation.pptx' });
      console.log('PowerPoint generated successfully');
    } catch (error) {
      console.error('Error generating PowerPoint:', error);
      throw error;
    }
  };




  const saveMermaidAsPNG = async (stepIndex, filename = 'mermaid_diagram.png') => {
    const element = document.querySelector(`#workflow-result-${stepIndex} .mermaid`);

    if (!element) {
      console.error('Mermaid element not found');
      setShowToast(true);
      setToastMessage('Failed to save diagram: Element not found');
      return;
    }

    try {
      // Clone the mermaid element
      const clone = element.cloneNode(true);

      // Create a container for the clone
      const container = document.createElement('div');
      container.appendChild(clone);

      // Set styles to ensure the entire graph is captured
      Object.assign(container.style, {
        position: 'absolute',
        left: '-9999px',
        top: '-9999px',
        width: 'auto',
        height: 'auto',
        transform: 'none',
        transformOrigin: 'top left'
      });

      // Append the container to the body
      document.body.appendChild(container);

      // Use html2canvas on the clone
      const canvas = await html2canvas(clone, {
        scale: 2, // Increase resolution
        logging: false,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null // Preserve transparency
      });

      // Remove the temporary container
      document.body.removeChild(container);

      // Convert canvas to blob and save
      canvas.toBlob((blob) => {
        saveAs(blob, filename);
      }, 'image/png');

      setUserActivity(prev => ({ ...prev, exportedMermaid: true }));
      setShowToast(true);
      setToastMessage('Diagram saved successfully');
    } catch (error) {
      console.error('Error saving diagram:', error);
      setShowToast(true);
      setToastMessage('Failed to save diagram: ' + error.message);
    }
  };





  const handleSaveNotesAsWord = (content, title = 'Meeting Notes') => {
    const html = DOMPurify.sanitize(content);
    const coverPage = `
      <div style="height: 100vh; position: relative; background-color: #130f40;">
        <img src="${coverLogoUrl}" alt="Logo" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 80%; max-width: 400px;">
      </div>
    `;

    const secondPage = `
      <div style="height: 100vh; position: relative; padding: 40px;">
        <h1 style="font-size: 36px; color: #FF1493; margin-top: 60px; margin-bottom: 10px;">${title.toUpperCase()}</h1>
        <p style="font-size: 18px; color: #333; margin-bottom: 20px;">MEETING NOTES</p>
      </div>
    `;

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <style>
          @page { margin: 1in; }
          body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
          h1, h2, h3, h4, h5, h6 { margin-top: 20px; margin-bottom: 10px; }
          p { margin-bottom: 10px; }
          ul, ol { margin-bottom: 10px; padding-left: 20px; }
          table { border-collapse: collapse; margin-bottom: 10px; }
          th, td { border: 1px solid #ddd; padding: 8px; }
          pre { background-color: #f4f4f4; padding: 10px; border-radius: 4px; white-space: pre-wrap; }
          code { background-color: #f4f4f4; padding: 2px 4px; border-radius: 4px; }
          .page-break { page-break-before: always; }
          .content { padding: 40px; }
        </style>
      </head>
      <body>
        ${coverPage}
        <div class="page-break"></div>
        ${secondPage}
        <div class="page-break"></div>
        <div class="content">
          ${html}
        </div>
      </body>
      </html>
    `;

    const footer = `
      <div style="display: flex; justify-content: space-between; align-items: center; font-size: 10px;">
        <div>
          <img src="${footerLogoUrl}" alt="Logo" style="height: 15px; margin-right: 5px; vertical-align: middle;">
          Powered by LLMazing - Your productivity pal, www.llmazing.online
        </div>
        <div>Page <span style="mso-field-code: PAGE"></span> of <span style="mso-field-code: NUMPAGES"></span></div>
      </div>
    `;

    const blob = htmlDocx.asBlob(htmlContent, { footer: footer });
    saveAs(blob, `${title}.docx`);
    setShowToast(true);
    setToastMessage('Saved notes as Word document');
  };

  const handleSaveAsWord = (content, toolName, stepIndex) => {

    // console.log('Original content:', content);
    // console.log('Edited results:', editedResults);
    const editedContent = editedResults[stepIndex] || content;
    // console.log('Content being saved:', editedContent);
    const html = DOMPurify.sanitize(marked.parse(editedContent));

    const coverPage = `
      <div style="height: 100vh; position: relative; background-color: #130f40;">
        <img src="${coverLogoUrl}" alt="Logo" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 80%; max-width: 400px;">
      </div>
    `;

    const secondPage = `
      <div style="height: 100vh; position: relative; padding: 40px;">
        <h1 style="font-size: 36px; color: #FF1493; margin-top: 60px; margin-bottom: 10px;">${toolName.toUpperCase()}</h1>
        <p style="font-size: 18px; color: #333; margin-bottom: 20px;">RESULT TAILORED FOR YOU</p>
      </div>
    `;

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <style>
          @page { margin: 1in; }
          body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
          h1, h2, h3, h4, h5, h6 { margin-top: 20px; margin-bottom: 10px; }
          p { margin-bottom: 10px; }
          ul, ol { margin-bottom: 10px; padding-left: 20px; }
          table { border-collapse: collapse; margin-bottom: 10px; }
          th, td { border: 1px solid #ddd; padding: 8px; }
          pre { background-color: #f4f4f4; padding: 10px; border-radius: 4px; white-space: pre-wrap; }
          code { background-color: #f4f4f4; padding: 2px 4px; border-radius: 4px; }
          .page-break { page-break-before: always; }
          .content { padding: 40px; }
        </style>
      </head>
      <body>
        ${coverPage}
        <div class="page-break"></div>
        ${secondPage}
        <div class="page-break"></div>
        <div class="content">
          ${html}
        </div>
      </body>
      </html>
    `;

    const footer = `
      <div style="display: flex; justify-content: space-between; align-items: center; font-size: 10px;">
        <div>
          <img src="${footerLogoUrl}" alt="Logo" style="height: 15px; margin-right: 5px; vertical-align: middle;">
          Powered by LLMazing - Your productivity pal, www.llmazing.online
        </div>
        <div>Page <span style="mso-field-code: PAGE"></span> of <span style="mso-field-code: NUMPAGES"></span></div>
      </div>
    `;

    const blob = htmlDocx.asBlob(htmlContent, { footer: footer });
    saveAs(blob, `${toolName}_result.docx`);
    setShowToast(true);
    setToastMessage('Saved formatted content as Word document');

    setUserActivity(prev => {
      const newState = { ...prev, savedAsWord: true };
      // Use setTimeout to ensure this runs after the state update
      setTimeout(() => {
        checkNPSTriggers();
      }, 0);
      return newState;
    });
  };





  const countWordsAndCharacters = (text) => {
    const words = text.trim().split(/\s+/).length;
    const characters = text.length;
    return { words, characters };
  };

  const [toolContexts, setToolContexts] = useState({});

  const handleContextChange = (toolIndex, selectedContexts) => {
    setToolContexts(prev => ({
      ...prev,
      [toolIndex]: selectedContexts
    }));
  };


  const renderToolSettings = (tool, index) => {
    const mainInputField = Object.values(tool.settings).find(setting => setting.type !== 'select');
    const mainInputValue = mainInputField ? mainInputField.value : '';
    const wordCount = mainInputValue?.trim().split(/\s+/).length || 0;
    const hasContext = toolContexts[index] && toolContexts[index].length > 0;
    const showWarning = wordCount < 5 && !hasContext;
  
    return (
      <motion.div
        key={`tool-${index}`}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className={`mb-4 overflow-hidden ${isStepRunning && currentStep === index ? 'border-2 border-blue-500' : ''}`}
      >
        <div className={`bg-gradient-to-r from-purple-600 to-indigo-600 rounded-t-xl p-4 ${isStepRunning && currentStep === index ? 'relative overflow-hidden' : ''}`}>
          {isStepRunning && currentStep === index && (
            <div className="absolute bottom-0 left-0 h-1 bg-blue-300 animate-progress"></div>
          )}
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-10 h-10 bg-white text-indigo-600 rounded-full flex items-center justify-center mr-3 font-bold text-xl">
                {index + 1}
              </div>
              <h3 className="text-xl font-semibold text-white">{tool.name}</h3>
              {showWarning && (
                <Tooltip content="Warning: Your input is brief and lacks context. Consider adding more details or selecting relevant contexts for a more tailored result.">
                  <div className="inline-flex items-center">
                    <IoIosWarning className="text-yellow-300 text-2xl ml-2" />
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="flex items-center">
              <Tooltip content={tool.description}>
                <HiQuestionMarkCircle className="text-white cursor-help mr-2" />
              </Tooltip>
              <Button
                color="light"
                size="xs"
                onClick={() => moveToolUp(index)}
                disabled={index === 0}
                className="mr-1"
              >
                <HiChevronUp />
              </Button>
              <Button
                color="light"
                size="xs"
                onClick={() => moveToolDown(index)}
                disabled={index === workflowTools.length - 1}
                className="mr-1"
              >
                <HiChevronDown />
              </Button>
              <Button color="failure" size="xs" onClick={() => removeTool(index)} className="bg-red-500 hover:bg-red-600 text-white rounded-full">
                <HiTrash className="mr-1" /> Remove
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-b-xl shadow-lg p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(tool.settings).map(([key, setting]) => {
              const inputProps = {
                id: `tool-input-${index}-${key}`,
                className: `tool-input-${index}-${key} w-full bg-gray-50 text-gray-800 text-sm rounded-lg border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`,
                placeholder: setting.placeholder || `Enter ${key}`,
                value: setting.value || '',
                onChange: (e) => handleSettingChange(index, key, e.target.value)
              };
              return (
                <div key={key} className="flex flex-col">
                  <Label htmlFor={inputProps.id} className="mb-1 text-sm font-medium text-gray-700">{setting.description}</Label>
                  <div className="flex space-x-2">
                    {setting.type === 'select' ? (
                      <Select {...inputProps} className="text-sm flex-grow">
                        {setting.options.map(option => (
                          <option key={option} value={option}>{option}</option>
                        ))}
                      </Select>
                    ) : setting.type === 'textarea' ? (
                      <Textarea {...inputProps} className={`${inputProps.className} min-h-[80px] text-sm`} />
                    ) : (
                      <TextInput {...inputProps} className="text-sm flex-grow" />
                    )}
                    {key !== 'model' && (
                      <Select
                        onChange={(e) => handleConnectionChange(index, key, e.target.value)}
                        value={connections[`${index}-${key}`] || ''}
                        className="w-1/3 bg-gray-50 text-gray-800 text-xs rounded-lg border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      >
                        <option value="">Input</option>
                        {workflowTools.slice(0, index).map((prevTool, prevIndex) => (
                          <option key={prevIndex} value={`${prevIndex}-output`}>
                            {prevTool.name.substring(0, 10)}... (Step {prevIndex + 1})
                          </option>
                        ))}
                      </Select>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4">
            <ContextSelector
              toolIndex={index}
              selectedContexts={toolContexts[index] || []}
              onContextChange={handleContextChange}
            />
          </div>
        </div>
      </motion.div>
    );
  };

  const handleExecuteEnhancedWorkflow = (workflow) => {
    // console.log("Executing enhanced workflow:", workflow);

    // Add the recommended tools to the workflow
    const newTools = workflow.recommendedTools
      .map(tool => {
        const contextTool = contextTools.find(t => t.name === tool.name);
        if (!contextTool) {
          console.warn(`Tool ${tool.name} not found in context tools`);
          return null;
        }

        // Pre-fill settings based on the workflow data
        const settings = Object.entries(contextTool.settings).reduce((acc, [key, setting]) => {
          acc[key] = {
            ...setting,
            value: key === 'additionalContext' ? workflow.additionalInfo : (setting.default || '')
          };
          return acc;
        }, {});

        // Ensure model is set
        settings.model = {
          ...settings.model,
          value: 'openai/gpt-4o-mini'  // Set default model
        };

        return { ...contextTool, settings };
      })
      .filter(Boolean);

    // Chain the tools together
    const chainedTools = newTools.map((tool, index) => {
      if (index === 0) {
        // For the first tool, use the original query as input
        const mainInputField = Object.keys(tool.settings).find(key => key !== 'model' && key !== 'additionalContext');
        if (mainInputField) {
          tool.settings[mainInputField].value = workflow.query;
        }
      }
      return tool;
    });

    // console.log("Chained tools:", chainedTools);

    setWorkflowTools(chainedTools);

    // Set up connections
    const newConnections = {};
    chainedTools.forEach((tool, index) => {
      if (index > 0) {
        const firstInputField = Object.keys(tool.settings).find(key => key !== 'model' && key !== 'additionalContext');
        if (firstInputField) {
          newConnections[`${index}-${firstInputField}`] = `${index - 1}-output`;
        }
      }
    });
    setConnections(newConnections);

    // Set the contexts for all tools
    const newToolContexts = chainedTools.reduce((acc, _, index) => {
      acc[index] = workflow.selectedContexts;
      return acc;
    }, {});
    setToolContexts(newToolContexts);

    // Close the modal
    setShowEnhancedSetup(false);

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Show the tools added popup
    setShowToolsAddedPopup(true);
    setUserActivity(prev => ({ ...prev, usedAISetup: true }));
  };





  return (
    <>
      <Helmet>
        <title>AI-Powered Workflows | LLMazing</title>
        <meta name="description" content="Create and manage AI-powered workflows to automate your tasks and boost productivity with LLMazing's intuitive tools." />
        <meta name="keywords" content="AI workflows, task automation, productivity tools" />
        <meta property="og:title" content="AI-Powered Workflows | LLMazing" />
        <meta property="og:description" content="Create and manage AI-powered workflows to automate your tasks and boost productivity with LLMazing's intuitive tools." />
        <meta property="og:image" content="https://llmazing.online/path-to-workflow-image.png" />
        <meta property="og:url" content="https://llmazing.online/dashboard/workflows" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="relative min-h-screen">
        {showOnboarding && (
          <OnboardingOverlay
            onClose={handleOnboardingClose}
            currentStep={onboardingStep}
            onNext={moveToNextOnboardingStep}
          />
        )}
        {/* <TipOfTheDay /> */}
        <style jsx>{`
        .mermaid-container {
          width: 100%;
          overflow-x: auto;
        }
        .mermaid-result {
          width: ${isMeetingMode && isDesktop ? 'calc(50vw - 2rem)' : '100%'};
        }
      `}</style>
        <div className="fixed top-20 right-5 flex flex-col space-y-2 z-50">
          <div className="flex space-x-2">
            <Button
              id="add-tool-button"
              onClick={() => setIsToolModalOpen(true)}
              className={`${customTheme.button.base} ${customTheme.button.primary} ${onboardingStep === 1 ? 'onboarding-highlight' : ''}`}
            >
              <HiPlus className="mr-1" /> Add Tool
            </Button>
            <Button
              onClick={() => setShowEnhancedSetup(true)}
              className={`${customTheme.button.base} ${customTheme.button.primary}`}
            >
              <HiLightningBolt className="mr-2" /> AI Setup
            </Button>
            <Button
              id="execute-button"
              onClick={executeFlow}
              disabled={workflowTools.length === 0 || loading}
              className={`${customTheme.button.base} ${customTheme.button.primary} ${onboardingStep === 2 ? 'onboarding-highlight' : ''}`}
            >
              {loading ? (
                <>
                  <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
                  Executing...
                </>
              ) : (
                <>
                  <HiLightningBolt className="mr-2" />
                  Execute
                </>
              )}
            </Button>
            <Button
              onClick={handleReset}
              className={`${customTheme.button.base} ${customTheme.button.accent}`}
            >
              <HiOutlineRefresh className="mr-1" /> Reset
            </Button>

            <Button
              onClick={() => setShowInstructionalVideo(true)}
              className={`${customTheme.button.base} ${customTheme.button.accent}`}
            >
              <HiOutlineInformationCircle className="mr-2" /> Help
            </Button>
          </div>
          {isDesktop && (
            <Button
              id="meeting-mode-button"
              onClick={() => setIsMeetingMode(!isMeetingMode)}
              className={`${customTheme.button.base} ${customTheme.button.primary} ${onboardingStep === 3 ? 'onboarding-highlight' : ''}`}
            >
              {isMeetingMode ? <HiOutlineViewBoards className="mr-2" /> : <HiOutlinePencilAlt className="mr-2" />}
              {isMeetingMode ? 'Exit Meeting Mode' : 'Enter Meeting Mode'}
            </Button>
          )}
          <ValidationModal
            isOpen={isValidationModalOpen}
            onClose={() => setIsValidationModalOpen(false)}
            issues={validationIssues}
          />
        </div>

        <div className={`p-6 text-white ${isMeetingMode && isDesktop ? 'flex' : ''}`}>
          <div className={`${isMeetingMode && isDesktop ? 'w-1/2 pr-4 overflow-y-auto flex flex-col' : 'w-full'}`}>
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="mb-6">
              <div className="flex items-center mb-4">
                <h2 className="text-4xl font-bold mr-2">Workflow Builder</h2>
                <p className="text-gray-300 text-sm">Create and manage your custom workflows</p>
              </div>
            </motion.div>
            {/* <div className="mb-8 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gradient-to-r from-primary to-secondary p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">New to Workflow Builder?</h3>
              <p className="mb-6 text-lg">Watch our quick tutorials to get started and master the Workflow Builder:</p>
              <Button
                onClick={() => setShowInstructionalVideo(true)}
                className={`w-full ${customTheme.button.base} ${customTheme.button.accent} font-bold py-3 px-6 rounded-full transition duration-300 transform hover:scale-105 shadow-lg`}
              >
                <HiOutlineInformationCircle className="mr-2 text-2xl" /> Watch Tutorials
              </Button>
            </div>
            <div className="bg-secondary p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">AI-Powered Workflow Setup</h3>
              <p className="mb-6 text-lg">Let our AI assistant help you create the perfect workflow:</p>
              <Button
                onClick={() => setShowEnhancedSetup(true)}
                className={`w-full ${customTheme.button.base} ${customTheme.button.primary} font-bold py-3 px-6 rounded-full transition duration-300 transform hover:scale-105 shadow-lg`}
              >
                <HiLightningBolt className="mr-2 text-2xl" /> AI Workflow Setup
              </Button>
            </div>
          </div> */}

            {loading && (
              <div className="mb-6">
                <LoadingOverlay currentToolName={workflowTools[currentStep]?.name || ''} />
              </div>
            )}
            {workflowTools.length === 0 ? (
              <EmptyStateGuidance />
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="workflow">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <AnimatePresence>
                        {workflowTools.map((tool, index) => (
                          <Draggable key={index} draggableId={`tool-${index}`} index={index}>
                            {(provided) => (
                              <motion.div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -50 }} transition={{ duration: 0.3 }} className="mb-4">
                                {renderToolSettings(tool, index)}
                              </motion.div>
                            )}
                          </Draggable>
                        ))}
                      </AnimatePresence>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {error && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="mt-4 p-4 bg-red-500 bg-opacity-50 text-white rounded">
                Error: {error}
              </motion.div>
            )}
            <div id="workflow-results" className="workflow-executor mt-8">
              <AnimatePresence>
                {Object.keys(stepResults).length > 0 && (
                  <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5 }}>
                    <h3 className="text-2xl font-bold mb-4 text-white">Workflow Results</h3>
                    {Object.entries(stepResults).map(([stepIndex, result]) => {
                      const isMermaid = workflow.steps[stepIndex].tool.template === 'graph_creator';
                      return (
                        <Card key={stepIndex} id={`workflow-result-${stepIndex}`} className="mb-4 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg">
                          <div className="flex justify-between items-center mb-4">
                            <span className="text-black">Step {parseInt(stepIndex) + 1}: {workflow.steps[stepIndex].tool.name}</span>
                          </div>
                          <div className="bg-white bg-opacity-20 p-4 rounded-lg mb-4" ref={el => resultRefs.current[stepIndex] = el}>
                            {renderResult(result, workflow.steps[stepIndex].tool.template, stepIndex)}
                          </div>
                          {isLoggedIn && (
                            <>
                              <div className="flex flex-wrap gap-2 mb-4">
                                {!isMermaid && (
                                  <>
                                    <Button color="light" onClick={() => handleCopyToClipboard(result, stepIndex)}>
                                      <HiOutlineClipboardCopy className="mr-2" /> Copy to Clipboard
                                    </Button>
                                    <Button color="light" onClick={() => handleSaveAsWord(result, workflow.steps[stepIndex].tool.name, stepIndex)}>
                                      <HiOutlineDocumentDownload className="mr-2" /> Save as Word
                                    </Button>
                                  </>
                                )}
                                {isMermaid && (
                                  <Button color="light" onClick={() => saveMermaidAsPNG(stepIndex, `mermaid_diagram_step${stepIndex}.png`)}>
                                    <HiOutlineDocumentDownload className="mr-2" /> Save as PNG
                                  </Button>
                                )}
                                {workflow.steps[stepIndex].tool.name === "Presentation Slide Content" && (
                                  <Button
                                    onClick={() => handleSaveAsPowerPoint(result, stepIndex)}
                                    disabled={isGenerating}
                                    color="light"
                                  >
                                    {isGenerating ? (
                                      <>
                                        <div className="inline-block animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-black mr-2"></div>
                                        Generating Presentation...
                                      </>
                                    ) : (
                                      <>
                                        <HiOutlinePresentationChartBar className="mr-2" /> Save as PowerPoint
                                      </>
                                    )}
                                  </Button>
                                )}
                              </div>
                              {!isMermaid && (
                                <p className="text-sm text-gray-300 mb-4">
                                  Word count: {countWordsAndCharacters(result).words},
                                  Character count: {countWordsAndCharacters(result).characters}
                                </p>
                              )}
                              <Button
                                color="light"
                                onClick={() => !loading && !isRegenerating && regenerateStep(parseInt(stepIndex))}
                                disabled={loading || isRegenerating}
                              >
                                <HiOutlineRefresh className="mr-2" />
                                {isRegenerating ? 'Regenerating...' : 'Re-generate Step'}
                              </Button>
                            </>
                          )}
                        </Card>
                      );
                    })}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          {isMeetingMode && isDesktop && (
            <div className="w-1/2 pl-4 flex flex-col h-screen fixed top-0 right-0 overflow-hidden">
              <h2 className="text-2xl font-bold mb-4 pt-24 mt-12">Meeting Notes</h2>
              <div className="flex-grow bg-white rounded-lg overflow-hidden flex flex-col">
                <NotesTakingArea
                  notes={notes}
                  setNotes={setNotes}
                  onSave={(content) => handleSaveNotesAsWord(content, 'Meeting Notes')}
                  setShowToast={setShowToast}
                  setToastMessage={setToastMessage}

                />
              </div>
            </div>
          )}
        </div>

        <Modal
          show={showEnhancedSetup}
          onClose={() => setShowEnhancedSetup(false)}
          size="xl"
        >
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 mt-16">
            <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-4xl w-full mx-4 flex flex-col">
              <Modal.Header className="border-b border-gray-200 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
                <h3 className="text-white">AI-Powered Workflow Setup</h3>
              </Modal.Header>
              <Modal.Body className="bg-white text-white overflow-y-auto max-h-[70vh] p-6">
                <EnhancedWorkflowSetup onExecuteWorkflow={handleExecuteEnhancedWorkflow} />
              </Modal.Body>
            </div>
          </div>
        </Modal>



        {showToolsAddedPopup && (
          <ToolsAddedPopup onClose={() => setShowToolsAddedPopup(false)} />
        )}

        <Modal show={showInstructionalVideo} onClose={() => setShowInstructionalVideo(false)} size="xl">
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 mt-16">
            <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-4xl w-full mx-4">
              <Modal.Header className="border-b border-gray-200 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
                <h3 className="text-white">LLMazing Tutorial Videos</h3>

              </Modal.Header>


              <div ref={tutorialModalRef} className="p-6 max-h-[70vh] overflow-y-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center items-center">
                  <TutorialVideo
                    title="Quick Overview"
                    videoId="https://www.youtube.com/embed/jJmllVbEQVA?si=jMHpAEiHzCDvxKNu"
                    description="Get a quick overview of LLMazing and its capabilities."
                  />
                  <TutorialVideo
                    title="How to Add Tools"
                    videoId="https://www.youtube.com/embed/w7sYHmXGrYY?si=h-9WuBM3cI-tGQus"
                    description="Learn how to add and configure tools in your workflow."
                  />
                  <TutorialVideo
                    title="Making Tools Work Together"
                    videoId="https://www.youtube.com/embed/dqOXj8rmVXY?si=24RGOlWJLriron-2"
                    description="Discover how to connect tools and create powerful workflows."
                  />
                  <TutorialVideo
                    title="Exporting Results"
                    videoId="https://www.youtube.com/embed/tfY7ztv4yug?si=h2LzZPettmDtquvN"
                    description="Find out how to export and save your workflow results."
                  />
                  <TutorialVideo
                    title="AI-Powered Workflow Setup"
                    videoId="https://www.youtube.com/embed/x-YzgXXf7JI?si=Go7kF5bG2JIFxLql"
                    description="Learn how to use our AI assistant to create optimal workflows."
                  />
                  <TutorialVideo
                    title="Managing Contexts"
                    videoId="https://www.youtube.com/embed/elu34wn5ouU?si=hi-5IosJxXnLyhZS"
                    description="Understand how to create, edit, and use contexts in your workflows."
                  />
                </div>
                {/* <div className="mt-6">
                <h4 className="text-xl font-semibold mb-4">Additional Resources</h4>
                <p className="mb-2">For more in-depth guides and tutorials, visit our comprehensive Learning Center:</p>
                <a href="/learning-center" className="text-blue-600 hover:underline">LLMazing Learning Center</a>
              </div> */}
                <div className="mt-6 flex justify-end">
                  <Button onClick={() => setShowInstructionalVideo(false)} className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {responseContent && (
          <motion.div
            className="bg-white text-gray-800 p-6 rounded-xl shadow-lg max-w-2xl mx-auto mt-8 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl font-bold mb-2">{responseContent.response_header}</h2>
            <p className="text-lg mb-4">{responseContent.response_subheader}</p>
            <h3 className="text-xl font-semibold mb-2">Task Breakdown:</h3>
            <ul className="list-disc list-inside mb-4">
              {responseContent.task_breakdown.map((task, index) => (
                <li key={index} className="mb-1">{task}</li>
              ))}
            </ul>
            <h3 className="text-xl font-semibold mb-2">Recommended Tools:</h3>
            <ul className="list-disc list-inside mb-4">
              {responseContent.recommended_tools.map((tool, index) => (
                <li key={index} className="mb-2">
                  <strong>{tool.name}</strong>: {tool.description}
                </li>
              ))}
            </ul>
            <h3 className="text-xl font-semibold mb-2">Next Steps:</h3>
            <p className="mb-4">{responseContent.next_steps}</p>
            <div className="flex justify-center mt-4">
              <motion.button
                className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleAddSuggestedTools}
                disabled={hasAddedTools}
              >
                {hasAddedTools ? 'Tools Added' : 'Add Suggested Tools to Workflow'}
              </motion.button>
            </div>
          </motion.div>
        )}

        {apiLoading && (
          <div className="loader mt-4 text-center">Loading...</div>
        )}

        {error && (
          <div className="error-message mt-4 text-red-500 text-center">{error}</div>
        )}

        {Object.keys(stepResults).length > 0 && (
          <FloatingNavWidget results={workflow.steps.map(step => ({ toolName: step.tool.name }))} />
        )}
        <Modal show={isToolModalOpen} onClose={() => setIsToolModalOpen(false)} size="xl" className="mt-16 rounded-lg">
          <Modal.Header className="border-b border-gray-200 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
            <h3 className="text-2xl font-bold text-white">Add a Tool to Your Workflow</h3>
          </Modal.Header>
          <Modal.Body className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg text-gray-400 overflow-y-auto max-h-[80vh]">
            <TextInput type="text" placeholder="Search tools" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} icon={HiSearch} className="mb-4" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {categories.sort().map((category, index) => (
                <div key={index} className="bg-white bg-opacity-20 p-4 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl">
                  <h4 className="font-semibold mb-3 text-xl text-gray-400 border-b border-gray-400 pb-2">{category}</h4>
                  <div className="space-y-2">
                    {filteredTools
                      .filter(tool => tool.category === category)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((tool, toolIndex) => (
                        <div key={toolIndex} className="flex items-center mb-2">
                          <Button
                            className={`w-full text-left flex justify-between items-center bg-white bg-opacity-30 hover:bg-opacity-40 transition-all duration-200 ${customTheme.button.base} ${customTheme.button.secondary}`}
                            onClick={() => addTool(tool)}
                          >
                            <span className="text-white font-medium">{tool.name}</span>
                            <div className="flex items-center">
                              <div className="relative group">
                                <HiQuestionMarkCircle className="text-white mr-2 cursor-help" />
                                <div className="absolute hidden group-hover:block right-0 bottom-full mb-2 w-64 bg-white text-gray-800 text-sm rounded-lg shadow-lg p-2">
                                  {tool.description}
                                </div>
                              </div>
                              <HiPlus className="text-white" />
                            </div>
                          </Button>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        {showToast && (
          <div className="fixed bottom-5 right-5 z-50">
            <Toast>
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                <HiCheckCircle className="h-5 w-5" />
              </div>
              <div className="ml-3 text-sm font-normal">{toastMessage}</div>
              <Toast.Toggle onDismiss={() => setShowToast(false)} />
            </Toast>
          </div>
        )}
        <NPSDetector
          workflowTools={workflowTools}
          stepResults={stepResults}
          userActivity={userActivity}
          onSurveyComplete={(score) => {
            // Handle the NPS score (e.g., send to backend)
            // You might want to send this score to your backend or analytics service
          }}
        />
      </div>
    </>
  );
};
export default Workflows;
