import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import Overview from './Overview';
import Tools from './Tools';
import ToolTemplate from './tools/toolTemplate';
import Workflows from './Workflows';
import UsageHistoryDashboard from './UsageHistoryDashboard'
import Marketplace from './Marketplace';
import Profile from './Profile';
import Settings from './Settings';
import GoogleCalendar from './tools/GoogleCalendar';
import GoogleTasks from './tools/GoogleTasks';
import useCookie from '../../context/useCookie';
import { customTheme } from '../../customTheme';

const Dashboard = () => {
  const location = useLocation();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskList, setTaskList] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState('dashboard');

  const accessToken = useCookie('accessToken');
  const userCookie = useCookie('user');

  // console.log('Token: ', accessToken);
  // console.log('userCookie: ', userCookie);

  const fetchCalendarEvents = useCallback(async () => {
    if (!accessToken) return;

    try {
      const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=' + (new Date()).toISOString() + '&maxResults=10&orderBy=startTime&singleEvents=true', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch calendar events');
      }
      const data = await response.json();
      setCalendarEvents(data.items || []);
    } catch (error) {
      // console.error('Error fetching calendar events', error);
    }
  }, [accessToken]);

  const loadToDo = useCallback(async () => {
    if (!accessToken) return;

    try {
      const listResponse = await fetch('https://tasks.googleapis.com/tasks/v1/users/@me/lists', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!listResponse.ok) {
        throw new Error('Failed to fetch task lists');
      }
      const listData = await listResponse.json();
      let taskList = listData.items.find(tl => tl.title === "Dashboard Tasks");

      if (!taskList) {
        const newListResponse = await fetch('https://tasks.googleapis.com/tasks/v1/users/@me/lists', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ title: "Dashboard Tasks" })
        });
        if (!newListResponse.ok) {
          throw new Error('Failed to create new task list');
        }
        taskList = await newListResponse.json();
      }

      setTaskList(taskList);

      const tasksResponse = await fetch(`https://tasks.googleapis.com/tasks/v1/lists/${taskList.id}/tasks`, {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!tasksResponse.ok) {
        throw new Error('Failed to fetch tasks');
      }
      const tasksData = await tasksResponse.json();
      setTasks(tasksData.items || []);
    } catch (error) {
      // console.error('Error loading tasks', error);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      fetchCalendarEvents();
      loadToDo();
    }
  }, [accessToken, fetchCalendarEvents, loadToDo]);

  // const getBreadcrumbs = useCallback(() => {
  //   const paths = location.pathname.split('/').filter(Boolean);
  //   let breadcrumbs = [
  //     { name: 'Home', url: '/' },
  //     { name: 'Dashboard', url: '/dashboard' }
  //   ];

  //   paths.forEach((path, index) => {
  //     if (path !== 'dashboard') {
  //       const url = `/${paths.slice(0, index + 2).join('/')}`;
  //       breadcrumbs.push({ name: path.charAt(0).toUpperCase() + path.slice(1), url });
  //     }
  //   });

  //   return breadcrumbs;
  // }, [location.pathname]);

  const handleThemeChange = (e) => {
    setSelectedTheme(e.target.value);
  };

  return (
    <div className={`flex flex-col min-h-screen ${customTheme.gradients[selectedTheme]}`}>
      {/* <div className="p-4">
        <label
          htmlFor="theme-select"
          className="text-white mr-2"
          style={{ fontSize: '0.6rem' }} // Adjust label font size if needed
        >
          Select Theme:
        </label>
        <select
          id="theme-select"
          className="p-1 rounded-md"
          value={selectedTheme}
          onChange={handleThemeChange}
          style={{
            fontSize: '0.6rem',  // Smaller font size
            padding: '0.2rem 0.5rem',  // Reduced padding
            width: 'auto',  // Auto width based on content
            height: '1.5rem',  // Smaller height
            minWidth: '100px',  // Minimum width to keep it readable
            border: '1px solid #ccc',  // Add a border to define the dropdown
          }}
        >
          {Object.keys(customTheme.gradients).map((key) => (
            <option key={key} value={key}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </option>
          ))}
        </select>
      </div> */}


      <main className={`flex-1 p-6 ${customTheme.text.light}`}>
        {/* <Breadcrumb className="mb-6">
          {getBreadcrumbs().map((crumb, index) => (
            <Breadcrumb.Item 
              key={index} 
              href={crumb.url}
              icon={index === 0 ? HiHome : null}
              className={customTheme.breadcrumb.item}
            >
              {crumb.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb> */}
        {/* Uncomment this section if you want to show Google Calendar and Tasks
        {userCookie && accessToken && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <GoogleCalendar events={calendarEvents} />
            <GoogleTasks tasks={tasks} taskList={taskList} loadToDo={loadToDo} accessToken={accessToken} />
          </div>
        )}
        */}
        <Routes>
          <Route index element={<Overview />} />
          <Route path="tools" element={<Tools />} />
          <Route path="tools/:toolName" element={<ToolTemplate />} />
          <Route path="workflows" element={<Workflows />} />
          <Route path="usage-history" element={<UsageHistoryDashboard />} />
          <Route path="marketplace" element={<Marketplace />} /> 
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </main>
    </div>
  );
};

export default Dashboard;
