import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useBlogContext } from '../../context/BlogContext';
import { FaCalendar, FaEye } from 'react-icons/fa';

const BlogTagList = () => {
  const { tag } = useParams();
  const { posts, loading, error } = useBlogContext();

  if (loading) return <div className="container mx-auto px-4 py-8">Loading...</div>;
  if (error) return <div className="container mx-auto px-4 py-8">Error: {error}</div>;

  const filteredPosts = posts.filter(post => post.tags && post.tags.includes(tag));

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>{`Posts tagged with "${tag}" | LLMazing`}</title>
        <meta name="description" content={`Explore blog posts tagged with "${tag}" on LLMazing.`} />
      </Helmet>

      <h1 className="text-4xl font-bold mb-8 text-center">Posts tagged with "{tag}"</h1>

      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {filteredPosts.map((post) => (
          <Link 
            key={post.post_id} 
            to={`/blog/${post.slug}`} 
            className="block group"
          >
            <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 group-hover:shadow-xl">
              {post.image && (
                <img 
                  src={post.image} 
                  alt={post.title} 
                  className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110" 
                />
              )}
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-600 transition-colors duration-300">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <FaCalendar className="mr-2" />
                  <span className="mr-4">{formatDate(post.created_at)}</span>
                  <FaEye className="mr-2" />
                  <span>{post.views} views</span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {filteredPosts.length === 0 && (
        <p className="text-center text-gray-600">No posts found with the tag "{tag}".</p>
      )}
    </div>
  );
};

export default BlogTagList;