import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { motion, useInView } from 'framer-motion';
import { FaClipboard, FaFileWord, FaFilePowerpoint, FaMagic, FaProjectDiagram, FaSitemap, FaReact, FaRocket, FaBrain, FaClock, FaChartLine, FaUsers, FaLightbulb, FaLink, FaShieldAlt, FaBolt, FaComments, FaNotesMedical, FaListUl, FaQuestion, FaFileAlt, FaEdit } from 'react-icons/fa';
import { ToolsContext } from '../context/ToolsContext';
import { WorkflowsContext } from '../context/WorkflowsContext';
// import { initializeChat } from './common/chatInit';
import { Button, Card, Badge } from 'flowbite-react';
import { customTheme } from '../customTheme';
import useCookie from '../context/useCookie';
import { Helmet } from 'react-helmet';

function MainContent() {
  const { tools, loading: toolsLoading, error: toolsError, fetchTools } = useContext(ToolsContext);
  const { workflows, loading: workflowsLoading } = useContext(WorkflowsContext);
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [responseContent, setResponseContent] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const [suggestedTools, setSuggestedTools] = useState([]);

  const userInfo = useCookie('user');
  const isAuthenticated = !!userInfo;


  useEffect(() => {
    // console.log("Available tools in toolkit:", tools);
  }, [tools]);

  useEffect(() => {
    const loadTools = async () => {
      if (tools.length === 0) {
        await fetchTools();
      }
    };
    loadTools();
    // const cleanupChat = initializeChat();
    // return cleanupChat;
  }, [fetchTools, tools.length]);

  const handleNavigation = (path, state = {}) => {
    if (isAuthenticated) {
      navigate(path, { state });
    } else {
      navigate('/register');
      window.scrollTo(0, 0);
    }
  };

  const cleanName = (name) => {
    return name.replace(/\*+/g, '').trim().replace(/\s+/g, ' ');
  };

  const extractToolNames = (response) => {
    const lines = response.toLowerCase().split('\n');
    const toolNames = new Set();

    const cleanName = (name) => {
      return name.replace(/\*+/g, '').trim().replace(/\s+/g, ' ');
    };

    const knownTools = tools.map(tool => cleanName(tool.name.toLowerCase()));

    lines.forEach(line => {
      // Check for tool mentions with asterisks or bold formatting
      const boldMatch = line.match(/\*\*(.*?)\*\*/);
      if (boldMatch) {
        toolNames.add(cleanName(boldMatch[1]));
      }

      // Check for tool mentions after "Tool:" or similar indicators
      const toolIndicators = ['tool:', 'tool :', '•', '*', '-'];
      toolIndicators.forEach(indicator => {
        const indicatorIndex = line.indexOf(indicator);
        if (indicatorIndex !== -1) {
          const afterIndicator = line.slice(indicatorIndex + indicator.length).trim();
          const colonIndex = afterIndicator.indexOf(':');
          if (colonIndex !== -1) {
            toolNames.add(cleanName(afterIndicator.slice(0, colonIndex)));
          } else {
            toolNames.add(cleanName(afterIndicator));
          }
        }
      });

      // Check for known tool names directly
      knownTools.forEach(tool => {
        if (line.includes(tool)) {
          toolNames.add(tool);
        }
      });
    });

    const extractedTools = Array.from(toolNames);
    // console.log("Extracted tool names:", extractedTools);
    return extractedTools;
  };

  const handleCreateWorkflow = () => {
    setIsCreatingWorkflow(true);

    if (tools && tools.length > 0 && suggestedTools.length > 0) {
      const workflowTools = suggestedTools
        .map(toolName => tools.find(tool => tool.name.toLowerCase() === toolName.toLowerCase()))
        .filter(Boolean);

      if (workflowTools.length === 0) {
        console.warn("No matching tools found");
        alert("No matching tools found in your toolkit. Please check the tool names and try again.");
        setIsCreatingWorkflow(false);
        return;
      }

      handleNavigation('/dashboard/workflows', {
        state: {
          suggestedTools: workflowTools,
          createNew: true
        }
      });
    } else {
      console.warn("Tools are not available");
      alert("Tools are not available at the moment. Please try again later.");
    }
    setIsCreatingWorkflow(false);
  };



  const handleGoClick = async () => {
    if (!query.trim()) return;
    if (toolsLoading || toolsError) {
      setError("Tools are not available at the moment. Please try again later.");
      return;
    }
    setApiLoading(true);
    setError(null);
    setResponseContent(null);

    const useCases = tools.map(tool => `${tool.name}: ${tool.description}`).join("\n");
    const prompt = `Your task is to assist users in structuring their goals or projects into manageable tasks, encouraging them to think creatively and step out of their comfort zone. Follow these steps:

1. Break down the user's task or goal into main phases or components.
2. For each phase, suggest ideation and visualization tools to help users explore and expand their ideas.
3. Then, recommend project management and execution tools to help plan and implement these ideas.
4. For each subtask or phase, suggest the most relevant and helpful tools from our available set.

Instructions for the answer:
1. Be comprehensive yet concise. Recommend all highly relevant tools that will genuinely assist the user.
2. Emphasize creative thinking and exploration in the early stages before moving to structured planning.
3. Encourage users to visualize their ideas using tools like mindmaps or flowcharts.
4. Suggest ways to break down abstract concepts into concrete, actionable steps.
5. Maintain a supportive and encouraging tone, especially for tasks that might be outside the user's comfort zone.
6. If no tool is perfectly suited for a specific need, honestly say so and suggest the closest alternative or a general approach.

Return your response as a JSON object with the following keys:
1. "response_header": A brief, engaging title for the response.
2. "response_subheader": A short, motivational subtitle or summary.
3. "task_breakdown": An array of main tasks or phases.
4. "recommended_tools": An array of objects, each containing "name" and "description" of the tool.
5. "next_steps": A brief description of what to do next.
6. "encouragement": A motivational message to inspire the user to take action and explore new possibilities.

User query: ${query}

Available use cases:
${useCases}`;

    try {
      const response = await axios.post(
        process.env.REACT_APP_OPENAI_COMPLETIONS_ENDPOINT,
        {
          model: 'gpt-4o-mini',
          temperature: 0.3,
          messages: [{ role: "user", content: prompt }],
        },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PASS,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log("API Response:", response.data);

      let contentToParse = response.data[0].message.content;

      // Remove everything before the first '{' and after the last '}'
      contentToParse = contentToParse.substring(
        contentToParse.indexOf('{'),
        contentToParse.lastIndexOf('}') + 1
      );

      let parsedContent;

      try {
        parsedContent = JSON.parse(contentToParse);
        // console.log("Parsed Content:", parsedContent);
      } catch (parseError) {
        // console.error("Error parsing JSON:", parseError);
        setError(`Error parsing response: ${parseError.message}`);
        return; // Exit the function if parsing fails
      }

      setResponseContent(parsedContent);
      setSuggestedTools(parsedContent.recommended_tools.map(tool => tool.name));


    } catch (error) {
      // console.error("API Error:", error);
      setError(`Error: ${error.message}`);
    } finally {
      setApiLoading(false);
    }
  };




  if (toolsLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
      </div>
    );
  }

  if (toolsError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-white text-2xl">Error loading tools: {toolsError}</div>
      </div>
    );
  }

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };
  const Section = ({ children, className, id }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.1 });

    return (
      <motion.section
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={customTheme.animations.fadeInUp}
        className={`py-16 w-full text-white ${className}`}
        id={id}
      >
        {children}
      </motion.section>
    );
  };


  return (
    <main className="w-full">
      <Helmet>
        <title>LLMazing - AI-Powered Productivity Tools</title>
        <meta name="description" content="Boost your productivity with LLMazing's AI-powered tools. Streamline your workflow and accomplish more in less time." />
        <meta name="keywords" content="AI, productivity, workflow, automation" />
        <meta property="og:title" content="LLMazing - AI-Powered Productivity Tools" />
        <meta property="og:description" content="Boost your productivity with LLMazing's AI-powered tools. Streamline your workflow and accomplish more in less time." />
        <meta property="og:image" content="https://llmazing.online/path-to-your-logo.png" />
        <meta property="og:url" content="https://llmazing.online" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <section className={`${customTheme.heroSection.base} min-h-screen flex items-center justify-center px-4 pt-16 w-full bg-gradient-to-b from-primary to-secondary text-white`}>
        <div className="container mx-auto text-center w-full">
          <motion.h1
            className={customTheme.heroSection.title}
            variants={customTheme.animations.fadeInUp}
            initial="hidden"
            animate="visible"
          >
            Supercharge Your Productivity with AI
          </motion.h1>
          <motion.p
            className={customTheme.heroSection.subtitle}
            variants={customTheme.animations.fadeInUp}
            initial="hidden"
            animate="visible"
          >
            LLMazing: Your AI-powered assistant for smarter workflows
          </motion.p>
          {/*
          <motion.div
            className="max-w-2xl mx-auto mb-8 flex w-full"
            variants={customTheme.animations.fadeInUp}
            initial="hidden"
            animate="visible"
          >
            <input
              type="text"
              placeholder="What do you want to achieve today?"
              className={customTheme.textInput.field.base}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') handleGoClick(); }}
            />
            <Button
              className={customTheme.button.primary}
              onClick={handleGoClick}
            >
              Go
            </Button>
          </motion.div>*/}
          {responseContent && (
            <Card className="w-full mx-auto mt-8">
              <h2 className="text-2xl font-bold mb-2">{responseContent.response_header}</h2>
              <p className="text-lg mb-4">{responseContent.response_subheader}</p>
              <h3 className="text-xl font-semibold mb-2">Task Breakdown:</h3>
              <ul className="list-disc list-inside mb-4">
                {responseContent.task_breakdown.map((task, index) => (
                  <li key={index}>{task}</li>
                ))}
              </ul>
              <h3 className="text-xl font-semibold mb-2">Recommended Tools:</h3>
              <ul className="list-disc list-inside mb-4">
                {responseContent.recommended_tools.map((tool, index) => (
                  <li key={index}><strong>{tool.name}</strong>: {tool.description}</li>
                ))}
              </ul>
              <h3 className="text-xl font-semibold mb-2">Next Steps:</h3>
              <p>{responseContent.next_steps}</p>
              {responseContent.fun_fact && (
                <p className="mt-4 italic">Fun fact: {responseContent.fun_fact}</p>
              )}
              <Button
                className={`${customTheme.button.secondary} mt-4`}
                onClick={handleCreateWorkflow}
                disabled={isCreatingWorkflow}
              >
                {isCreatingWorkflow ? 'Creating Workflow...' : 'Create Workflow with Suggested Tools'}
              </Button>
            </Card>

          )}

          {apiLoading && (
            <div className="loader mt-4">Loading...</div>
          )}
          {error && (
            <div className="error-message mt-4 text-red-500">{error}</div>
          )}

          <motion.div
            className="mt-8 w-full max-w-3xl mx-auto px-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
              <iframe
                src="https://www.youtube.com/embed/3P5c1dgg2T0?si=w1vOjeati4mni1e9"
                title="LLMazing Company"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"
              ></iframe>
            </div>
          </motion.div>

          <motion.div
            className="mt-8 flex justify-center space-x-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            {/* <div>
              <h3 className="text-2xl font-bold">10,000+</h3>
              <p>Active Users</p>
            </div>
            <div>
              <h3 className="text-2xl font-bold">4.8/5</h3>
              <p>User Score</p>
            </div> */}
            <div>
              <h3 className="text-2xl font-bold">15+ human hours</h3>
              <p>Saved per Week</p>
            </div>
          </motion.div>
        </div>
      </section>

      <Section className={`${customTheme.sectionColorTwo} ai-notes`} id="ai-notes">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Revolutionize Your Meetings with AI-Powered Notes</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
              <p className="text-xl mb-6">
                Transform your meeting experience with our advanced AI Notes feature in Meeting Mode. Capture every important detail effortlessly while staying fully engaged in the conversation.
              </p>
              <h3 className="text-2xl font-semibold mb-4">Unlock the Power of AI-Assisted Notetaking:</h3>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <FaListUl className="mr-2 text-blue-400" /> Generate work breakdowns for any topic
                </li>
                <li className="flex items-center">
                  <FaQuestion className="mr-2 text-blue-400" /> Get instant explanations of complex terminology
                </li>
                <li className="flex items-center">
                  <FaEdit className="mr-2 text-blue-400" /> Improve and refine your text with AI suggestions
                </li>
                <li className="flex items-center">
                  <FaFileAlt className="mr-2 text-blue-400" /> Create concise summaries of lengthy discussions
                </li>
                <li className="flex items-center">
                  <FaNotesMedical className="mr-2 text-blue-400" /> Generate insightful questions from your notes
                </li>
              </ul>
            </div>
            <div className="md:w-1/2">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="rounded-lg overflow-hidden shadow-lg"
              >
                <img
                  src="/images/home/AI_Notes_5.gif"
                  alt="AI Notes in action"
                  className="w-full h-auto rounded-lg"
                  loading="lazy"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorOne} brainstorming-tools`} id="brainstorming-tools">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Supercharge Your Brainstorming with AI-Generated Visuals</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
              <p className="text-xl mb-6">
                Unleash your creativity and boost productivity with our AI-powered automated flowcharts and mindmaps. Simply input your topic and context, and watch as our AI generates comprehensive visual representations of your ideas.
              </p>
              <h3 className="text-2xl font-semibold mb-4">Transform Your Ideation Process:</h3>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <FaBolt className="mr-2 text-yellow-400" /> Instantly generate complex flowcharts and mindmaps
                </li>
                <li className="flex items-center">
                  <FaBrain className="mr-2 text-yellow-400" /> Stimulate creative thinking and idea exploration
                </li>
                <li className="flex items-center">
                  <FaProjectDiagram className="mr-2 text-yellow-400" /> Visualize processes and concepts effortlessly
                </li>
                <li className="flex items-center">
                  <FaUsers className="mr-2 text-yellow-400" /> Enhance team collaboration and understanding
                </li>
                <li className="flex items-center">
                  <FaSitemap className="mr-2 text-yellow-400" /> Organize complex information structures with ease
                </li>
                <li className="flex items-center">
                  <FaLightbulb className="mr-2 text-yellow-400" /> Uncover new perspectives and connections
                </li>
              </ul>
            </div>
            <div className="md:w-1/2">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="rounded-lg overflow-hidden shadow-lg"
              >
                <img
                  src="/images/home/AI_charts.gif"
                  alt="AI-generated flowcharts and mindmaps"
                  className="w-full h-auto rounded-lg"
                  loading="lazy"
                />
              </motion.div>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-lg">
              Whether you're planning a project, exploring new ideas, or trying to understand complex concepts, our AI-powered brainstorming tools are here to elevate your thinking and boost your productivity.
            </p>
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorTwo} result-export`} id="result-export">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Seamless Result Exporting</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
              <p className="text-xl mb-6">
                Effortlessly transfer your AI-generated content to your preferred platforms with our advanced export features. Whether you need to share your results quickly or integrate them into your existing documents, we've got you covered.
              </p>
              <h3 className="text-2xl font-semibold mb-4">Export Your Results With Ease:</h3>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <FaClipboard className="mr-2 text-blue-400" /> Copy to clipboard for quick sharing
                </li>
                <li className="flex items-center">
                  <FaFileWord className="mr-2 text-blue-400" /> Export directly to Microsoft Word
                </li>
                <li className="flex items-center">
                  <FaFilePowerpoint className="mr-2 text-blue-400" /> Generate PowerPoint presentations instantly
                </li>
                <li className="flex items-center">
                  <FaMagic className="mr-2 text-blue-400" /> Preserve formatting and styling
                </li>
                <li className="flex items-center">
                  <FaBolt className="mr-2 text-blue-400" /> Save time with one-click exports
                </li>
              </ul>
            </div>
            <div className="md:w-1/2">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="rounded-lg overflow-hidden shadow-lg"
              >
                <img
                  src="/images/home/AI_result_export.gif"
                  alt="AI result export demonstration"
                  className="w-full h-auto rounded-lg"
                  loading="lazy"
                />
              </motion.div>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-lg">
              With our Presentation Generator tool and easy export options, you can transform your AI-generated content into polished presentations and documents in seconds. Streamline your workflow and focus on what matters most.
            </p>
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorOne} features`} id="features">
        <div className="container mx-auto px-4 w-full">
          <h2 className={customTheme.sectionTitle}>Unlock Your Potential</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { icon: <FaRocket />, title: "Boost Productivity", description: "Streamline your workflow and accomplish more in less time" },
              { icon: <FaBrain />, title: "AI-Powered Insights", description: "Leverage advanced AI to make data-driven decisions" },
              { icon: <FaClock />, title: "Save Time", description: "Automate repetitive tasks and focus on what matters most" },
              { icon: <FaChartLine />, title: "Improve Quality", description: "Enhance the quality of your work with AI assistance" },
              { icon: <FaUsers />, title: "Collaborate Smarter", description: "Foster teamwork with intelligent collaboration tools" },
              { icon: <FaLightbulb />, title: "Innovate Faster", description: "Turn ideas into action with rapid prototyping tools" },
            ].map((feature, index) => (
              <Card key={index} className={customTheme.featureCard.base}>
                <div className={customTheme.featureCard.icon}>{feature.icon}</div>
                <h3 className={customTheme.featureCard.title}>{feature.title}</h3>
                <p className={customTheme.featureCard.description}>{feature.description}</p>
              </Card>
            ))}
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorTwo} safety`} id="safety">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Safety First: Your Data is Secured</h2>
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
              <FaShieldAlt className="text-6xl text-blue-400 mx-auto mb-4" />
              <p className="text-lg text-center">
                At LLMazing, we prioritize the security and privacy of your data. Our state-of-the-art encryption and security measures ensure that your information remains protected at all times.
              </p>
            </div>
            <ul className="md:w-1/2 text-left">
              <li className="mb-4 flex items-center">
                <FaShieldAlt className="mr-2 text-blue-400" /> End-to-end encryption
              </li>
              <li className="mb-4 flex items-center">
                <FaShieldAlt className="mr-2 text-blue-400" /> Regular security audits
              </li>
              <li className="mb-4 flex items-center">
                <FaShieldAlt className="mr-2 text-blue-400" /> Compliance with data protection regulations
              </li>
              <li className="flex items-center">
                <FaShieldAlt className="mr-2 text-blue-400" /> Transparent data usage policies
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorOne} beta-offer`}>
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Why LLMazing is Free: We're in Beta!</h2>
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
              <FaBolt className="text-6xl text-yellow-400 mx-auto mb-4" />
              <p className="text-lg text-center">
                We're excited to offer LLMazing for free during our Beta phase. This means you get access to all features and 1000 free credits while we fine-tune our platform based on your valuable feedback.
              </p>
            </div>
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold mb-4">What is Beta?</h3>
              <p className="mb-4">
                Beta is a pre-release phase where we invite users like you to test our platform, provide feedback, and help us improve. It's your chance to shape the future of LLMazing!
              </p>
              <p>
                After the Beta phase, we'll introduce new profile types to cater to different user needs while maintaining our commitment to providing value.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorTwo} ai`} id="ai">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">All Top AI Models in One Place</h2>
          <p className="text-xl text-center mb-8">
            LLMazing brings together the best AI models to tailor personal solutions for you.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            {[
              { name: 'OpenAI', image: '/images/home/logos/openai.png' },
              { name: 'Mistral', image: '/images/home/logos/mistral.png' },
              { name: 'Claude', image: '/images/home/logos/claude.png' },
              { name: 'LLama3', image: '/images/home/logos/llama.png' },
              { name: 'Gemini', image: '/images/home/logos/gemini.png' },
              { name: 'And More', image: '/images/home/logos/more.png' }
            ].map((model, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 p-4 rounded-xl text-center"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <img
                  src={`../../${model.image}`}
                  alt={model.name}
                  className="w-16 h-16 object-contain mx-auto mb-2"
                />
                <p>{model.name}</p>
              </motion.div>
            ))}
          </div>
          <p className="text-center mt-8">
            We use a blend of proprietary and open-source state-of-the-art models to deliver relevant solutions.
          </p>
        </div>
      </Section>

      {/* Hidden section */}
      {/* 
      <Section className="integrations">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">LLMazing: Your Ultimate Team Player</h2>
          <p className="text-xl text-center mb-8">
            Seamlessly integrate LLMazing with your favorite apps and boost your productivity.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {['Slack', 'Trello', 'Google Workspace', 'Microsoft 365'].map((app, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 p-4 rounded-xl text-center"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <FaPuzzlePiece className="text-4xl mx-auto mb-2 text-blue-400" />
                <p>{app}</p>
              </motion.div>
            ))}
          </div>
          <p className="text-center mt-8">
            Don't see your favorite app? Let us know, and we'll prioritize its integration!
          </p>
        </div>
      </Section>
            */}

      <Section className={`${customTheme.sectionColorOne} feedback`} id="feedback">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">We Listen to Your Needs</h2>
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
              <FaComments className="text-6xl text-blue-400 mx-auto mb-4" />
              <p className="text-lg text-center">
                Your feedback shapes the future of LLMazing. We're committed to continuously improving our platform based on your suggestions and needs.
              </p>
            </div>
            <ul className="md:w-1/2 text-left">
              <li className="mb-4 flex items-center">
                <FaComments className="mr-2 text-blue-400" /> Report bugs and issues
              </li>
              <li className="mb-4 flex items-center">
                <FaComments className="mr-2 text-blue-400" /> Suggest new features
              </li>
              <li className="mb-4 flex items-center">
                <FaComments className="mr-2 text-blue-400" /> Request new integrations
              </li>
              <li className="flex items-center">
                <FaComments className="mr-2 text-blue-400" /> Share your success stories
              </li>
            </ul>
          </div>
        </div>
      </Section>



      {/* <Section className={`${customTheme.sectionColorTwo} tools`} id="tools">
        <div className="container mx-auto px-4 w-full">
          <h2 className={customTheme.sectionTitle}>Our AI-Powered Tools</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {tools.sort((a, b) => a.name.localeCompare(b.name)).map((tool, index) => (
              <div
                key={index}
                className={`${customTheme.toolCard.base} cursor-pointer`}
                onClick={() => handleNavigation('/dashboard/workflows', {
                  state: {
                    selectedTool: tool,
                    createNew: true
                  }
                })}

              >
                <FaReact className="text-4xl mb-2 text-blue-400" />
                <h3 className={customTheme.toolCard.title}>{tool.name}</h3>
                <p className={customTheme.toolCard.description}>{tool.description.slice(0, 100)}...</p>
              </div>
            ))}
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorOne} workflows`} id="workflows">
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Create Powerful Workflows</h2>
          <p className="text-center text-xl mb-8">
            Chain multiple tools together to create end-to-end processes tailored to your needs.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {workflows.slice(0, 2).map((workflow, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 p-6 rounded-xl backdrop-filter backdrop-blur-lg"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <h3 className="text-xl font-semibold mb-2">{workflow.name}</h3>
                <p className="text-gray-300 mb-4">{workflow.description}</p>
                <div className="flex items-center space-x-2">
                  <FaLink className="text-blue-400" />
                  <span className="text-sm">
                    {workflow.configuration.steps.map(step => step.tool.name).join(' → ')}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </Section>

      <Section className={`${customTheme.sectionColorTwo} popularworkflows`}>
        <div className="container mx-auto px-4 w-full">
          <h2 className="text-3xl font-bold text-center mb-12">Popular Workflows</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {workflows.sort((a, b) => a.name.localeCompare(b.name)).map((workflow, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 p-6 rounded-xl backdrop-filter backdrop-blur-lg cursor-pointer"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 300 }}
                onClick={() => handleNavigation('/dashboard/workflows', { state: { workflow } })}
              >
                <h3 className="text-xl font-semibold mb-2">{workflow.name}</h3>
                <p className="text-gray-300 mb-4">{workflow.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </Section> */}

      <Section className={`${customTheme.sectionColorOne} cta`}>
        <div className="container mx-auto px-4 text-center w-full">
          <h2 className={customTheme.sectionTitle}>Ready to Transform Your Workflow?</h2>
          <div className="flex justify-center">
            <Button
              className={`${customTheme.button.primary} text-xl`}
              onClick={() => navigate('/register')}
            >
              Register for Free - No Credit Card Required
            </Button>
          </div>
          <p className="mt-4 text-gray-300">Start boosting your productivity today!</p>
        </div>
      </Section>
    </main>
  );
}

export default MainContent;