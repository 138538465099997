import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

const useCookie = (cookieName) => {
  const getCookie = useCallback(() => {
    return Cookies.get(cookieName);
  }, [cookieName]);

  const [cookie, setCookie] = useState(getCookie());

  useEffect(() => {
    const interval = setInterval(() => {
      setCookie(getCookie());
    }, 1000);

    return () => clearInterval(interval);
  }, [getCookie]);

  return cookie;
};

export default useCookie;
