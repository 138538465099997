import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import PptxGenJS from 'pptxgenjs';
import mermaid from 'mermaid';
import usePageTracking from '../../../usePageTracking.js';
// import { initializeChat } from '../../common/chatInit';
import { motion, AnimatePresence } from "framer-motion";
import { Accordion, Button, Card, Label, TextInput, Textarea, Select, Toast, Progress } from 'flowbite-react';
import { HiOutlineInformationCircle, HiLightningBolt, HiOutlineClipboardCopy, HiOutlineDocumentDownload, HiOutlinePresentationChartBar, HiCheckCircle, HiOutlineRefresh } from 'react-icons/hi';
import { ToolsContext } from '../../../context/ToolsContext';
import { customTheme } from '../../../customTheme';
import ReactDOMServer from 'react-dom/server';
import { stripHtml } from "string-strip-html";
import Cookies from 'js-cookie';
import useCookie from '../../../context/useCookie';

const ToolTemplate = () => {
  usePageTracking();
  const { state } = useLocation();
  const { tool } = state || {};
  const { tools: contextTools } = useContext(ToolsContext);

  const [formValues, setFormValues] = useState({});
  const [responseContent, setResponseContent] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finalPrompt, setFinalPrompt] = useState('');
  const [models, setModels] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [retryCount, setRetryCount] = useState(0);

  const userInfo = useCookie('user');
  const isLoggedIn = !!userInfo;

  useEffect(() => {
    // const cleanupChat = initializeChat();
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
      flowchart: { useMaxWidth: true, htmlLabels: true },
      sequence: { useMaxWidth: true, showSequenceNumbers: true },
      gantt: { titleTopMargin: 25, barHeight: 20, barGap: 4, topPadding: 50, sidePadding: 75 },
      pie: { useMaxWidth: true },
      classDiagram: { useMaxWidth: true },
      stateDiagram: { useMaxWidth: true },
      er: { useMaxWidth: true },
      journey: { useMaxWidth: true },
    });
    // return cleanupChat;
  }, []);

  useEffect(() => {
    if (tool && tool.settings) {
      const initialValues = {};
      let settings = typeof tool.settings === 'string' ? JSON.parse(tool.settings) : tool.settings;
      Object.keys(settings).forEach(key => {
        initialValues[key] = settings[key].default;
      });
      setFormValues(initialValues);
    }
  }, [tool]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_CHAT_TAGS_URI, {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PASS,
          },
        });
        let fetchedModels = response.data.models.map(model => model.name);
        if (tool.remove_models) {
          const removeModels = tool.remove_models.split(',').map(m => m.trim());
          fetchedModels = fetchedModels.filter(model => !removeModels.includes(model));
        }
        setModels(fetchedModels);
      } catch (error) {
        // console.error("Error fetching models:", error);
      }
    };
    fetchModels();
  }, [tool.remove_models]);

  const handleChange = (key, value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  };

  const filterFieldsBasedOnDependencies = (settings, values) => {
    return Object.keys(settings).filter(key => {
      if (!settings[key].dependencies) return true;
      const dependencyKey = Object.keys(settings[key].dependencies)[0];
      const dependencyValues = settings[key].dependencies[dependencyKey];
      return dependencyValues.includes(values[dependencyKey]);
    });
  };

  const generatePrompt = () => {
    const settings = typeof tool.settings === 'string' ? JSON.parse(tool.settings) : tool.settings;
    let prompt = tool.enriched_instructions || "Please provide detailed instructions.";
    const filteredKeys = filterFieldsBasedOnDependencies(settings, formValues).filter(key => key !== 'numCopies');
    filteredKeys.forEach(key => {
      if (formValues[key]) {
        prompt += `\n${settings[key].description}: ${formValues[key]}`;
      }
    });
    return prompt;
  };

  const handleGoClick = async () => {
    setLoading(true);
    setError(null);
    setResponseContent([]);
    const prompt = generatePrompt();
    setFinalPrompt(prompt);

    const userInfo = Cookies.get('user');
    const userEmail = userInfo ? JSON.parse(userInfo).email : '';
    const numCopies = formValues.numCopies || 1;
    let selectedModels = [tool.model, ...getRandomModels(tool.model, numCopies - 1)];

    try {
      const apiResponses = await Promise.all(selectedModels.map(model => executeTool(model, prompt, userEmail)));
      setResponseContent(apiResponses);
      logUsage({ generated_texts: apiResponses, finalPrompt: prompt, userEmail });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const executeTool = async (model, prompt, userEmail) => {
    const payload = {
        model: model,
        messages: [{ role: "user", content: prompt }],
        stream: false,
        user_email: userEmail
      };

      const payload_ollama = {
        model: model,
        messages: { role: "user", content: prompt },
        stream: false,
        user_email: userEmail
      };

    try {
      let apiResponse;
      if (model.startsWith('openai')) {
        apiResponse = await axios.post(
          process.env.REACT_APP_OPENAI_COMPLETIONS_ENDPOINT,
          {
            model: model.replace('openai/', ''),
            messages: [{ role: "user", content: prompt }],
          },
          {
            auth: {
              username: process.env.REACT_APP_API_USER,
              password: process.env.REACT_APP_API_PASS,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return { content: apiResponse.data[0].message.content, model };
      } else {
        apiResponse = await axios.post(
          process.env.REACT_APP_CHAT_EVAL_PROMPT_URI,
          payload_ollama,
          {
            auth: {
              username: process.env.REACT_APP_API_USER,
              password: process.env.REACT_APP_API_PASS,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return { content: apiResponse.data.message.content, model };
      }
    } catch (error) {
      // console.error(`Error with model ${model}:`, error);
      throw error;
    }
  };

  const getRandomModels = (currentModel, numModels) => {
    const availableModels = models.filter(model => model !== currentModel);
    return availableModels.sort(() => 0.5 - Math.random()).slice(0, numModels);
  };

  const handleError = (error) => {
    if (error.response) {
      setError(`Server Error: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
    } else if (error.request) {
      setError('No response received from server.');
    } else {
      setError(`Error: ${error.message}`);
    }
  };

  const logUsage = async (sessionState) => {
    const payload = {
      user_email: sessionState.userEmail,
      prompt: sessionState.finalPrompt,
      usecase: tool.name,
      generated_texts: sessionState.generated_texts.map(text => [text.model, text.content]),
    };

    try {
      await axios.post(process.env.REACT_APP_INSERT_LOG_SESSION_URL, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      // console.error("Error logging usage:", error);
    }
  };

  const markdownToHtml = (markdown) => {
    return ReactDOMServer.renderToString(
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          h1: ({ node, ...props }) => <h1 style={{ fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' }} {...props} />,
          h2: ({ node, ...props }) => <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' }} {...props} />,
          h3: ({ node, ...props }) => <h3 style={{ fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' }} {...props} />,
          p: ({ node, ...props }) => <p style={{ margin: '1em 0' }} {...props} />,
          ul: ({ node, ...props }) => <ul style={{ paddingLeft: '2em', margin: '1em 0' }} {...props} />,
          ol: ({ node, ...props }) => <ol style={{ paddingLeft: '2em', margin: '1em 0' }} {...props} />,
          li: ({ node, ...props }) => <li style={{ margin: '0.5em 0' }} {...props} />,
          pre: ({ node, ...props }) => <pre style={{ backgroundColor: '#f4f4f4', padding: '1em', overflowX: 'auto', margin: '1em 0' }} {...props} />,
          code: ({ node, inline, ...props }) =>
            inline
              ? <code style={{ backgroundColor: '#f4f4f4', padding: '0.2em 0.4em', borderRadius: '3px' }} {...props} />
              : <code style={{ display: 'block', backgroundColor: '#f4f4f4', padding: '1em', overflowX: 'auto', margin: '1em 0' }} {...props} />,
          a: ({ node, ...props }) => <a style={{ color: '#0366d6', textDecoration: 'none' }} {...props} />,
          blockquote: ({ node, ...props }) => <blockquote style={{ borderLeft: '0.25em solid #dfe2e5', color: '#6a737d', padding: '0 1em', margin: '1em 0' }} {...props} />,
          table: ({ node, ...props }) => <table style={{ borderCollapse: 'collapse', margin: '1em 0' }} {...props} />,
          th: ({ node, ...props }) => <th style={{ border: '1px solid #dfe2e5', padding: '6px 13px' }} {...props} />,
          td: ({ node, ...props }) => <td style={{ border: '1px solid #dfe2e5', padding: '6px 13px' }} {...props} />,
        }}
      >
        {markdown}
      </ReactMarkdown>
    );
  };

  const handleCopyToClipboard = async (content) => {
    try {
      const formattedContent = markdownToHtml(content);
      const strippedContent = stripHtml(formattedContent).result;
      await navigator.clipboard.writeText(strippedContent);
      setShowToast(true);
      setToastMessage('Formatted content copied to clipboard');
    } catch (err) {
      // console.error("Error copying to clipboard:", err);
    }
  };

  const handleSaveAsWord = (content) => {
    const formattedContent = markdownToHtml(content);
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <style>
          body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
          h1, h2, h3, h4, h5, h6 { margin-top: 20px; margin-bottom: 10px; }
          p { margin-bottom: 10px; }
          ul, ol { margin-bottom: 10px; padding-left: 20px; }
          table { border-collapse: collapse; margin-bottom: 10px; }
          th, td { border: 1px solid #ddd; padding: 8px; }
          pre { background-color: #f4f4f4; padding: 10px; border-radius: 4px; white-space: pre-wrap; }
          code { background-color: #f4f4f4; padding: 2px 4px; border-radius: 4px; }
        </style>
      </head>
      <body>
        ${formattedContent}
      </body>
      </html>
    `;
    const blob = htmlDocx.asBlob(htmlContent);
    saveAs(blob, 'tool_result.docx');
    setShowToast(true);
    setToastMessage('Saved formatted content as Word document');
  };

  const handleSaveAsPowerPoint = (content) => {
    const formattedContent = markdownToHtml(content);
    const strippedContent = stripHtml(formattedContent).result;

    const pptx = new PptxGenJS();
    const slide = pptx.addSlide();
    slide.addText('Tool Result', { x: 1, y: 0.5, fontSize: 24, bold: true });
    slide.addText(strippedContent, { x: 1, y: 1.5, fontSize: 18, breakLine: true });
    pptx.writeFile({ fileName: 'tool_result.pptx' });
    setShowToast(true);
    setToastMessage('Saved formatted content as PowerPoint presentation');
  };

  const countWordsAndCharacters = (text) => {
    const words = text.trim().split(/\s+/).length;
    const characters = text.length;
    return { words, characters };
  };

  const renderMermaidDiagram = async (content) => {
    const svgContent = content.replace(/[\s\S]*```mermaid([\s\S]*?)```[\s\S]*/, '$1');
    const container = document.createElement('div');
    container.innerHTML = svgContent;
    document.body.appendChild(container);

    try {
      mermaid.render('graphDiv', svgContent, (svgCode) => {
        container.innerHTML = svgCode;
      });
    } catch (error) {
      // console.error('Mermaid rendering error:', error);
    }

    document.body.removeChild(container);
  };

  const renderInputField = (key, setting) => {
    const commonProps = {
      id: key,
      value: formValues[key] || '',
      onChange: (e) => handleChange(key, e.target.value),
      className: "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    };

    switch (setting.type) {
      case 'text':
      case 'number':
        return (
          <TextInput
            type={setting.type}
            placeholder={setting.placeholder}
            {...commonProps}
          />
        );
      case 'textarea':
        return (
          <Textarea
            placeholder={setting.placeholder}
            {...commonProps}
          />
        );
      case 'select':
        return (
          <Select {...commonProps}>
            {setting.options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  const validateMermaidSyntax = (content) => {
    try {
      mermaid.parse(content);
      return true;
    } catch (error) {
      // console.error('Mermaid syntax error:', error);
      return false;
    }
  };

  const updateMermaidDiagrams = () => {
    if (tool.template === 'graph_creator') {
      const allValid = responseContent.every(response => 
        validateMermaidSyntax(response.content.replace(/[\s\S]*```mermaid([\s\S]*?)```[\s\S]*/, '$1'))
      );
      if (allValid) {
        try {
          mermaid.contentLoaded();
          setError(null);
        } catch (mermaidError) {
          // console.error('Error generating mermaid graph:', mermaidError);
          setError('Error generating diagram. Please check your syntax.');
        }
      } else {
        if (retryCount < 5) {
          setRetryCount(retryCount + 1);
          handleGoClick();
        } else {
          setError('Failed to generate a valid diagram after multiple attempts. Please check your input.');
        }
      }
    }
  };

  useEffect(() => {
    if (responseContent.length > 0) {
      updateMermaidDiagrams();
    }
  }, [responseContent]);

  const renderResult = (result, toolTemplate, index) => {
    const disclaimer = (
      <div className="mt-4 text-sm text-gray-500 italic border-t border-gray-200 pt-2">
        <p>
          Note: This content is AI-generated and reviewed by the LLMazing team. While we strive for accuracy, 
          we recommend verifying important information before use.
        </p>
      </div>
    );
  
    let content;
    if (toolTemplate === 'graph_creator') {
      const mermaidContent = result.content.match(/```mermaid([\s\S]*?)```/)?.[1]?.trim() || result.content;
      content = (
        <div className={customTheme.mermaidWrapper.base}>
          <div className="mermaid" key={`mermaid-${index}`}>
            {mermaidContent}
          </div>
        </div>
      );
    } else {
      content = (
        <div className={`markdown-body`}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: ({node, ...props}) => <h1 className={`font-${customTheme.fontFamily.heading} text-2xl font-bold my-4`} {...props} />,
              h2: ({node, ...props}) => <h2 className={`font-${customTheme.fontFamily.heading} text-xl font-bold my-3`} {...props} />,
              h3: ({node, ...props}) => <h3 className={`font-${customTheme.fontFamily.heading} text-lg font-bold my-2`} {...props} />,
              p: ({node, ...props}) => <p className={`font-${customTheme.fontFamily.sans} my-2`} {...props} />,
              ul: ({node, ...props}) => <ul className="list-disc list-inside my-2" {...props} />,
              ol: ({node, ...props}) => <ol className="list-decimal list-inside my-2" {...props} />,
              li: ({node, ...props}) => <li className="ml-4" {...props} />,
              pre: ({node, ...props}) => <pre className={`${customTheme.card.root.base} p-2 my-2 overflow-x-auto`} {...props} />,
              code: ({node, inline, ...props}) => 
                inline 
                  ? <code className={`bg-${customTheme.colors.background} px-1 rounded`} {...props} />
                  : <code className={`block bg-${customTheme.colors.background} p-2 rounded my-2 overflow-x-auto`} {...props} />,
              a: ({node, ...props}) => <a className={`text-${customTheme.colors.primary} hover:underline`} {...props} />,
              blockquote: ({node, ...props}) => <blockquote className={`border-l-4 border-${customTheme.colors.secondary} pl-4 italic my-4`} {...props} />,
              table: ({node, ...props}) => <table className="min-w-full divide-y divide-gray-200 my-4" {...props} />,
              th: ({node, ...props}) => <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...props} />,
              td: ({node, ...props}) => <td className="px-6 py-4 whitespace-nowrap" {...props} />,
            }}
          >
            {result.content}
          </ReactMarkdown>
        </div>
      );
    }

    return (
      <div className="relative">
        <div className={`${isLoggedIn ? '' : 'blur-sm'}`}>
          {content}
        </div>
        {!isLoggedIn && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-b from-transparent to-purple-900 bg-opacity-90">
            <p className="text-white text-xl mb-4">Sign up to see the full result!</p>
            <Link to="/register">
              <Button className="px-6 py-3 text-lg font-semibold bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                <HiLightningBolt className="mr-2" />
                Sign Up to Unleash Your Productivity
              </Button>
            </Link>
          </div>
        )}
        {isLoggedIn && disclaimer}
      </div>
    );
  };

  if (!tool) return <div>Loading...</div>;

  const settings = typeof tool.settings === 'string' ? JSON.parse(tool.settings) : tool.settings;
  const sortedKeys = Object.keys(settings).sort((a, b) => settings[a].order - settings[b].order);
  const filteredKeys = filterFieldsBasedOnDependencies(settings, formValues);

  return (
    <div className="p-6 space-y-6 text-white bg-gradient-to-br from-purple-900 via-blue-900 to-cyan-700 min-h-screen">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">{tool.name}</h2>
        <p className="text-gray-300">{tool.description}</p>
      </motion.div>

      <Accordion collapseAll>
        <Accordion.Panel>
          <Accordion.Title>Tool Information</Accordion.Title>
          <Accordion.Content>
            <div className="space-y-4">
              <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                <h3 className="text-xl font-bold text-white">Usage</h3>
                <p className="text-gray-300"><strong>Number of Usages:</strong> {tool.nb_usages}</p>
                <p className="text-gray-300"><strong>Marked as Favorite:</strong> {tool.nb_marked_as_favorite}</p>
              </Card>
              <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                <h3 className="text-xl font-bold text-white">Examples</h3>
                <p className="text-gray-300">{tool.examples === "No example" ? "No examples available" : tool.examples}</p>
              </Card>
              <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                <h3 className="text-xl font-bold text-white">Labels</h3>
                <div className="flex flex-wrap gap-2">
                  {tool.labels ? tool.labels.split(', ').map(label => (
                    <span key={label} className="bg-blue-200 text-blue-800 rounded-full px-2 py-1 text-sm">{label}</span>
                  )) : "No labels available"}
                </div>
              </Card>
              <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                <h3 className="text-xl font-bold text-white">Model</h3>
                <p className="text-gray-300">{tool.model}</p>
              </Card>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>

      <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
        <div className="space-y-4">
          {sortedKeys.filter(key => filteredKeys.includes(key)).map(key => (
            <div key={key} className="mb-4">
              <Label htmlFor={key} value={settings[key].description} className="text-white" />
              {renderInputField(key, settings[key])}
            </div>
          ))}
        </div>

        <Button
          onClick={handleGoClick}
          disabled={loading}
          className="px-6 py-3 text-lg font-semibold bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
              Processing...
            </>
          ) : (
            <>
              <HiLightningBolt className="mr-2" />
              Generate
            </>
          )}
        </Button>
      </Card>

      <AnimatePresence>
        {responseContent.length > 0 && !loading && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
              <h3 className="text-xl font-bold mb-4 text-white">Generated Content</h3>
              {responseContent.map((response, index) => (
                <div key={index} className="mb-6 last:mb-0">
                  <h4 className="font-semibold mb-2 text-white">Result by model: <b>{response.model}</b>:</h4>
                  <div className="bg-white bg-opacity-20 p-4 rounded-lg mb-2">
                    {renderResult(response, tool.template, index)}
                  </div>
                  {isLoggedIn && (
                    <>
                      <div className="flex flex-wrap gap-2 mb-2">
                        <Button color="light" onClick={() => handleCopyToClipboard(response.content)}>
                          <HiOutlineClipboardCopy className="mr-2" /> Copy to Clipboard
                        </Button>
                        {tool.template !== 'graph_creator' && (
                          <>
                            <Button color="light" onClick={() => handleSaveAsWord(response.content)}>
                              <HiOutlineDocumentDownload className="mr-2" /> Save as Word
                            </Button>
                            <Button color="light" onClick={() => handleSaveAsPowerPoint(response.content)}>
                              <HiOutlinePresentationChartBar className="mr-2" /> Save as PowerPoint
                            </Button>
                          </>
                        )}
                        {tool.template === 'graph_creator' && (
                          <Button color="light" onClick={() => renderMermaidDiagram(response.content)}>
                            <HiOutlineRefresh className="mr-2" /> Render Diagram
                          </Button>
                        )}
                      </div>
                      <p className="text-sm text-gray-300">
                        Word count: {countWordsAndCharacters(response.content).words}, 
                        Character count: {countWordsAndCharacters(response.content).characters}
                      </p>
                    </>
                  )}
                </div>
              ))}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>

      {error && !loading && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-4 p-4 bg-red-500 bg-opacity-50 text-white rounded"
        >
          <h3 className="text-xl font-bold mb-2">Error</h3>
          <p>{error}</p>
        </motion.div>
      )}

      {showToast && (
        <div className="fixed top-5 right-5 z-50">
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
              <HiCheckCircle className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">
              {toastMessage}
            </div>
            <Toast.Toggle onDismiss={() => setShowToast(false)} />
          </Toast>
        </div>
      )}
    </div>
  );
};

export default ToolTemplate;