import React, { useState } from 'react';
import { Card, Button, Select, ToggleSwitch, TextInput } from 'flowbite-react';
import { motion } from 'framer-motion';
import { HiSave, HiColorSwatch, HiTranslate, HiBell, HiChip, HiClipboardCheck } from 'react-icons/hi';
import ContextManager from './ContextManager';

const Settings = () => {
  const [theme, setTheme] = useState('system');
  const [notifications, setNotifications] = useState(true);
  const [language, setLanguage] = useState('en');
  const [aiModel, setAiModel] = useState('gpt-4o-mini');
  const [toast, setToast] = useState(null);

  const handleSave = () => {
    // Here you would typically send the settings to your backend or store them in local storage
    // console.log('Settings saved:', { theme, notifications, language, aiModel });
    showToast('Settings saved successfully!');
  };

  const showToast = (message) => {
    setToast(message);
    setTimeout(() => setToast(null), 3000);
  };

  return (
    <motion.div
      className="p-6 min-h-screen text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold mb-6">Settings</h1>



      <ContextManager />



    </motion.div>
  );
};

export default Settings;