import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, TextInput, Accordion, Checkbox } from 'flowbite-react';
import { HiLightningBolt, HiChevronDown, HiOutlineQuestionMarkCircle, HiOutlinePencilAlt, HiOutlineClipboardList, HiOutlineCog } from 'react-icons/hi';
import { ToolsContext } from '../../context/ToolsContext';
import { useContexts } from '../../context/ContextsContext';
import { customTheme } from '../../customTheme';
import axios from 'axios';

const EnhancedWorkflowSetup = ({ onExecuteWorkflow }) => {
  const { tools: contextTools } = useContext(ToolsContext);
  const { contexts, groups, associations } = useContexts();
  const [query, setQuery] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedContexts, setSelectedContexts] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const executeButtonRef = useRef(null);

  const DEFAULT_MODEL = 'openai/gpt-4o-mini';

  useEffect(() => {
    if (apiResponse && executeButtonRef.current) {
      executeButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [apiResponse]);

  const handleGoClick = async () => {
    if (!query.trim()) return;
    setIsLoading(true);
    setApiResponse(null);
    
    const useCases = contextTools.map(tool => `${tool.name}: ${tool.description}`).join("\n");
    const prompt = `Your task is to assist users in structuring their goals or projects into manageable tasks, encouraging them to think creatively and step out of their comfort zone. Follow these steps:
  
    1. Break down the user's task or goal into main phases or components.
    2. For each phase, suggest ideation to help users explore and expand their ideas.
    3. Then, recommend project management to help plan and implement these ideas.
    4. Since the user might not know how exactly to work with LLMazing web-app, suggest maximum 3 tools to avoid confusion.

    Instructions for the answer:
    1. Be comprehensive yet concise. Recommend all highly relevant tools that will genuinely assist the user.
    2. Emphasize creative thinking and exploration in the early stages before moving to structured planning.
    3. Suggest ways to break down abstract concepts into concrete, actionable steps.
    4. Maintain a supportive and encouraging tone, especially for tasks that might be outside the user's comfort zone.
    5. If no tool is perfectly suited for a specific need, honestly say so and suggest the closest alternative or a general approach.

    Return your response as a JSON object with the following keys:
    1. "response_header": A brief, engaging title for the response.
    2. "response_subheader": A short, motivational subtitle or summary.
    3. "task_breakdown": An array of main tasks or phases.
    4. "recommended_tools": An array of objects, each containing "name" and "description" of the tool.
    5. "next_steps": A brief description of what to do next.
    6. "encouragement": A motivational message to inspire the user to take action and explore new possibilities.
  
    User query: ${query}
    Additional context: ${additionalInfo}
  
    Available use cases:
    ${useCases}`;
  
    try {
      const response = await axios.post(
        process.env.REACT_APP_OPENAI_COMPLETIONS_ENDPOINT,
        {
          model: DEFAULT_MODEL.replace('openai/', ''),
          temperature: 0.3,
          messages: [{ role: "user", content: prompt }],
        },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PASS,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      let contentToParse = response.data[0].message.content;
      
      const attemptJSONParse = (content) => {
        try {
          return JSON.parse(content);
        } catch (error) {
          return null;
        }
      };
  
      let parsedContent = attemptJSONParse(contentToParse);
  
      if (!parsedContent) {
        const markdownMatch = contentToParse.match(/```json\n([\s\S]*?\n)```/);
        if (markdownMatch) {
          contentToParse = markdownMatch[1].trim();
          parsedContent = attemptJSONParse(contentToParse);
        }
      }
  
      if (parsedContent) {
        setApiResponse(parsedContent);
      } else {
        console.error("Failed to parse JSON response");
        setApiResponse({
          response_header: "Parsing Error",
          response_subheader: "We encountered an issue processing the AI response.",
          task_breakdown: ["The AI generated a response, but it couldn't be properly formatted."],
          recommended_tools: [],
          next_steps: "Please try rephrasing your query or try again later.",
          encouragement: "Don't worry! Sometimes it takes a few attempts to get the perfect response."
        });
      }
    } catch (error) {
      console.error("API Error:", error);
      setApiResponse({
        response_header: "Error",
        response_subheader: "There was an issue processing your request.",
        task_breakdown: ["Unable to process the query at this time."],
        recommended_tools: [],
        next_steps: "Please try again later or refine your query.",
        encouragement: "Don't worry, we'll get through this together!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleContextChange = (contextId) => {
    setSelectedContexts(prev => 
      prev.includes(contextId) 
        ? prev.filter(id => id !== contextId)
        : [...prev, contextId]
    );
  };

  const handleExecuteWorkflow = () => {
    const workflow = {
      query,
      selectedContexts,
      additionalInfo,
      recommendedTools: apiResponse?.recommended_tools.map(tool => ({
        ...tool,
        settings: {
          ...tool.settings,
          model: { value: DEFAULT_MODEL }
        }
      })) || [],
    };
    onExecuteWorkflow(workflow);
  };

  const ungroupedContexts = contexts.filter(context =>
    !associations.some(a => a.context_id === context.id)
  );

  return (
    <div>
      <div className="mb-6">
        <label htmlFor="query" className="block mb-2 text-sm font-medium text-gray-700 flex items-center">
          <HiOutlinePencilAlt className="mr-2" />
          What do you want to achieve?
        </label>
        <TextInput
          id="query"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your goal or project"
          className="w-full bg-white border-gray-300 text-gray-800 placeholder-gray-400"
        />
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2 text-gray-700 flex items-center">
          <HiOutlineClipboardList className="mr-2" />
          Select Relevant Contexts
          <HiOutlineQuestionMarkCircle className="ml-2 text-gray-500 cursor-help" title="Choose contexts that are relevant to your project" />
        </h3>
        <div className="space-y-2">
          {groups.map(group => (
            <Accordion collapseAll={true} key={group.id} className="text-sm">
              <Accordion.Panel>
                <Accordion.Title className="py-2 px-4 bg-white rounded-lg hover:bg-gray-50 transition-colors duration-200 flex items-center justify-between text-gray-700">
                  {group.name}
                  <HiChevronDown className="w-5 h-5" />
                </Accordion.Title>
                <Accordion.Content>
                  <div className="p-2 space-y-1">
                    {contexts
                      .filter(context => associations.some(a => a.group_id === group.id && a.context_id === context.id))
                      .map(context => (
                        <div key={context.id} className="flex items-center">
                          <Checkbox
                            id={`context-${context.id}`}
                            checked={selectedContexts.includes(context.id)}
                            onChange={() => handleContextChange(context.id)}
                            className="mr-2 rounded text-blue-600 focus:ring-blue-500"
                          />
                          <label htmlFor={`context-${context.id}`} className="text-sm text-gray-600">
                            {context.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          ))}
          {ungroupedContexts.length > 0 && (
            <Accordion collapseAll={true} className="text-sm">
              <Accordion.Panel>
                <Accordion.Title className="py-2 px-4 bg-white rounded-lg hover:bg-gray-50 transition-colors duration-200 flex items-center justify-between text-gray-700">
                  Ungrouped Contexts
                  <HiChevronDown className="w-5 h-5" />
                </Accordion.Title>
                <Accordion.Content>
                  <div className="p-2 space-y-1">
                    {ungroupedContexts.map(context => (
                      <div key={context.id} className="flex items-center">
                        <Checkbox
                          id={`context-${context.id}`}
                          checked={selectedContexts.includes(context.id)}
                          onChange={() => handleContextChange(context.id)}
                          className="mr-2 rounded text-blue-600 focus:ring-blue-500"
                        />
                        <label htmlFor={`context-${context.id}`} className="text-sm text-gray-600">
                          {context.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          )}
        </div>
      </div>

      <div className="mb-6">
        <label htmlFor="additionalInfo" className="block mb-2 text-sm font-medium text-gray-700 flex items-center">
          <HiOutlineCog className="mr-2" />
          Additional Details:
        </label>
        <TextInput
          id="additionalInfo"
          type="text"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          placeholder="Any additional details or requirements to help tailor the results"
          className="w-full bg-white border-gray-300 text-gray-800 placeholder-gray-400"
        />
      </div>

      {!apiResponse && (
        <Button 
          onClick={handleGoClick} 
          disabled={isLoading || !query.trim()} 
          className={`w-full ${customTheme.button.base} ${customTheme.button.primary}`}
        >
          {isLoading ? 'Processing...' : 'Get AI Recommendations'}
        </Button>
      )}

      {apiResponse && (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">{apiResponse.response_header}</h2>
          <p className="text-gray-600 mb-4">{apiResponse.response_subheader}</p>
          <h3 className="text-xl font-semibold mt-4 mb-2 text-gray-700">Task Breakdown:</h3>
          <ul className="list-disc pl-5 mb-4 text-gray-600">
            {apiResponse.task_breakdown.map((task, index) => (
              <li key={index} className="mb-1">{task}</li>
            ))}
          </ul>
          <h3 className="text-xl font-semibold mt-4 mb-2 text-gray-700">Recommended Tools:</h3>
          <ul className="list-disc pl-5 mb-4 text-gray-600">
            {apiResponse.recommended_tools.map((tool, index) => (
              <li key={index} className="mb-2">
                <strong className="text-gray-800">{tool.name}</strong>: {tool.description}
              </li>
            ))}
          </ul>
          <p className="mt-4 mb-2 text-gray-600"><strong className="text-gray-800">Next Steps:</strong> {apiResponse.next_steps}</p>
          <p className="mt-2 italic text-gray-500 mb-4">{apiResponse.encouragement}</p>
          <Button 
            ref={executeButtonRef}
            onClick={handleExecuteWorkflow}
            className={`w-full mt-4 ${customTheme.button.base} ${customTheme.button.accent}`}
          >
            <HiLightningBolt className="mr-2" />
            Execute AI Workflow
          </Button>
        </div>
      )}
    </div>
  );
};

export default EnhancedWorkflowSetup;