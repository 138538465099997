// src/context/ContextsContext.js

import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useCookie from './useCookie';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';

const ContextsContext = createContext();

export const useContexts = () => useContext(ContextsContext);

export const ContextsProvider = ({ children }) => {
  const [contexts, setContexts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // New state variables for onboarding and tips
  const [userStatus, setUserStatus] = useState(null);
  const [hideOnboarding, setHideOnboarding] = useState(false);
  const [hideTips, setHideTips] = useState(false);
  const savedUserStatus = useCookie('userStatus');
  const savedHideOnboarding = useCookie('hideOnboarding');
  const savedHideTips = useCookie('hideTips');
  const [hasSeenOnboarding, setHasSeenOnboarding] = useState(() => {
    const savedHasSeenOnboarding = Cookies.get('hasSeenOnboarding');
    return savedHasSeenOnboarding === 'true';
  });

  // Add a new state for tooltip visibility
  const [tooltipsVisible, setTooltipsVisible] = useState(() => {
    const savedTooltipsVisible = Cookies.get('tooltipsVisible');
    return savedTooltipsVisible === undefined ? true : JSON.parse(savedTooltipsVisible);
  });

  const userInfo = useCookie('user');
  const user = userInfo ? JSON.parse(userInfo) : null;
  const { email } = user || {};

  const markOnboardingAsSeen = () => {
    setHasSeenOnboarding(true);
    Cookies.set('hasSeenOnboarding', 'true', { expires: 365 }); // Set cookie to expire in 1 year
  };

  const [dataLoaded, setDataLoaded] = useState(false);
  const CACHE_DURATION = 900000; // 15 minutes in milliseconds

  const getCachedData = (key) => {
    const cachedData = sessionStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedData = (key, data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    sessionStorage.setItem(key, JSON.stringify(cacheData));
  };

  // Implement lazy loading with debounce
  const debouncedFetchContextsAndGroups = useRef(
    debounce(async (force = false) => {
      if (!email || (dataLoaded && !force)) return;

      setLoading(true);
      try {
        const cachedContexts = getCachedData('contexts');
        const cachedGroups = getCachedData('groups');
        const cachedAssociations = getCachedData('associations');

        if (!force && cachedContexts && cachedGroups && cachedAssociations) {
          setContexts(cachedContexts);
          setGroups(cachedGroups);
          setAssociations(cachedAssociations);
          setError(null);
        } else {
          const response = await axios.post(
            process.env.REACT_APP_CONTEXT_MANAGER_URL,
            { command: 'get_contexts_and_groups', email },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
              }
            }
          );

          const data = response.data;
          
          if (Array.isArray(data) && data.length > 0) {
            const newContexts = data.filter(item => item.content !== undefined);
            const newGroups = data.filter(item => item.description !== undefined && item.content === undefined);
            const newAssociations = data.filter(item => item.context_id !== undefined);

            setContexts(newContexts);
            setGroups(newGroups);
            setAssociations(newAssociations);
            setError(null);

            setCachedData('contexts', newContexts);
            setCachedData('groups', newGroups);
            setCachedData('associations', newAssociations);
          } else {
            setContexts([]);
            setGroups([]);
            setAssociations([]);
            setError('No contexts or groups found. Start by adding one!');
          }
        }
      } catch (err) {
        setError('Failed to fetch contexts and groups. Please try again later.');
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (savedUserStatus) setUserStatus(savedUserStatus);
    if (savedHideOnboarding) setHideOnboarding(savedHideOnboarding === 'true');
    if (savedHideTips) setHideTips(savedHideTips === 'true');

    if (email && !dataLoaded) {
      debouncedFetchContextsAndGroups();
    }
  }, [email, savedUserStatus, savedHideOnboarding, savedHideTips, dataLoaded, debouncedFetchContextsAndGroups]);

  useEffect(() => {
    const intervalId = setInterval(() => debouncedFetchContextsAndGroups(true), CACHE_DURATION);
    return () => clearInterval(intervalId);
  }, [debouncedFetchContextsAndGroups]);

  useEffect(() => {
    const handleFocus = () => {
      const cachedData = sessionStorage.getItem('contexts');
      if (cachedData) {
        const { timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp >= CACHE_DURATION) {
          debouncedFetchContextsAndGroups(true);
        }
      }
    };
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [debouncedFetchContextsAndGroups]);

  const createContext = async (contextData) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'create_context',
          email,
          name: contextData.name,
          description: contextData.description,
          content: contextData.content,
          groupIds: contextData.groupIds
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      if (response.data && response.data.id) {
        const newContext = response.data;
        setContexts(prevContexts => [...prevContexts, newContext]);
        setError(null);
      } else {
        throw new Error('Failed to create context');
      }
    } catch (err) {
      setError('Failed to create context. Please try again.');
    } finally {
      setLoading(false);
    }

    await debouncedFetchContextsAndGroups();
  };

  const updateContext = async (contextId, contextData) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'update_context',
          email,
          id: contextId,
          name: contextData.name,
          description: contextData.description,
          content: contextData.content,
          groupIds: contextData.groupIds
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      if (response.data && response.data.id) {
        setContexts(prevContexts => prevContexts.map(c => c.id === contextId ? response.data : c));
        setError(null);
      } else {
        throw new Error('Failed to update context');
      }
    } catch (err) {
      setError('Failed to update context. Please try again.');
    } finally {
      setLoading(false);
    }

    await debouncedFetchContextsAndGroups();
  };

  const deleteContext = async (contextId) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'delete_context',
          email,
          id: contextId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      setContexts(prevContexts => prevContexts.filter(c => c.id !== contextId));
      setAssociations(prevAssociations => prevAssociations.filter(a => a.context_id !== contextId));
      setError(null);
    } catch (err) {
      setError('Failed to delete context. Please try again.');
    } finally {
      setLoading(false);
    }

    await debouncedFetchContextsAndGroups();
  };

  const createGroup = async (groupData) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'create_context_group',
          email,
          name: groupData.name,
          description: groupData.description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      if (response.data && response.data.id) {
        setGroups(prevGroups => [...prevGroups, response.data]);
        setError(null);
      } else {
        throw new Error('Failed to create group');
      }
    } catch (err) {
      setError('Failed to create group. Please try again.');
    } finally {
      setLoading(false);
    }

    await debouncedFetchContextsAndGroups();
  };

  const updateGroup = async (groupId, groupData) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'update_context_group',
          email,
          id: groupId,
          name: groupData.name,
          description: groupData.description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      if (response.data && response.data.id) {
        setGroups(prevGroups => prevGroups.map(g => g.id === groupId ? response.data : g));
        setError(null);
      } else {
        throw new Error('Failed to update group');
      }
    } catch (err) {
      setError('Failed to update group. Please try again.');
    } finally {
      setLoading(false);
    }

    await debouncedFetchContextsAndGroups();
  };

  const deleteGroup = async (groupId) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'delete_context_group',
          email,
          id: groupId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      setGroups(prevGroups => prevGroups.filter(g => g.id !== groupId));
      setAssociations(prevAssociations => prevAssociations.filter(a => a.group_id !== groupId));
      setError(null);
    } catch (err) {
      setError('Failed to delete group. Please try again.');
    } finally {
      setLoading(false);
    }

    await debouncedFetchContextsAndGroups();
  };

  const associateContextWithGroup = async (contextId, groupId) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    try {
      await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'associate_context_with_group',
          email,
          contextId,
          groupId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      setAssociations(prevAssociations => [
        ...prevAssociations,
        { context_id: contextId, group_id: groupId }
      ]);
    } catch (err) {
      setError('Failed to associate context with group. Please try again.');
    }

    await debouncedFetchContextsAndGroups();
  };

  const disassociateContextFromGroup = async (contextId, groupId) => {
    if (!email) {
      setError('User not logged in');
      return;
    }

    try {
      await axios.post(
        process.env.REACT_APP_CONTEXT_MANAGER_URL,
        {
          command: 'disassociate_context_from_group',
          email,
          contextId,
          groupId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
          }
        }
      );

      setAssociations(prevAssociations => 
        prevAssociations.filter(a => !(a.context_id === contextId && a.group_id === groupId))
      );
    } catch (err) {
      setError('Failed to disassociate context from group. Please try again.');
    }

    await debouncedFetchContextsAndGroups();
  };

  // New methods for managing onboarding and tips
  const updateUserStatus = (status) => {
    setUserStatus(status);
    document.cookie = `userStatus=${status}; path=/; max-age=31536000`; // 1 year expiration
  };

  const updateHideOnboarding = (hide) => {
    setHideOnboarding(hide);
    document.cookie = `hideOnboarding=${hide}; path=/; max-age=31536000`;
  };

  const updateHideTips = (hide) => {
    setHideTips(hide);
    document.cookie = `hideTips=${hide}; path=/; max-age=31536000`;
  };

  // Add a new function to update tooltip visibility
  const updateTooltipsVisible = (visible) => {
    setTooltipsVisible(visible);
    Cookies.set('tooltipsVisible', JSON.stringify(visible), { expires: 365 }); // Set cookie to expire in 1 year
  };

  const value = {
    contexts,
    groups,
    associations,
    loading,
    error,
    createContext,
    updateContext,
    deleteContext,
    createGroup,
    updateGroup,
    deleteGroup,
    associateContextWithGroup,
    disassociateContextFromGroup,
    refreshContexts: () => {
      setDataLoaded(false);
      debouncedFetchContextsAndGroups();
    },
    // New values for onboarding and tips
    userStatus,
    hideOnboarding,
    hideTips,
    updateUserStatus,
    updateHideOnboarding,
    updateHideTips,
    hasSeenOnboarding,
    markOnboardingAsSeen,
    tooltipsVisible,
    updateTooltipsVisible
  };

  return <ContextsContext.Provider value={value}>{children}</ContextsContext.Provider>;
};