import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Label, TextInput } from 'flowbite-react';
import { customTheme } from '../../customTheme';
import useCookie from '../../context/useCookie';
import { HiOutlineThumbUp, HiOutlineThumbDown, HiX } from 'react-icons/hi';

const NPSDetector = ({ workflowTools, stepResults, userActivity, onSurveyComplete }) => {
  const [showNPSModal, setShowNPSModal] = useState(false);
  const [npsScore, setNPSScore] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [surveyId, setSurveyId] = useState(null);

  const userInfo = useCookie('user');
  const user = userInfo ? JSON.parse(userInfo) : null;
  const npsCookie = useCookie('nps_triggered');

  // console.log("NPSDetector: Initial render");
  // console.log("NPSDetector: userActivity:", userActivity);
  // console.log("NPSDetector: npsCookie:", npsCookie);
  // console.log("NPSDetector: user:", user);

  useEffect(() => {
    // console.log("NPSDetector: useEffect triggered");
    // console.log("NPSDetector: userActivity changed", userActivity);
    // console.log("NPSDetector: npsCookie value:", npsCookie);

    if (userActivity.npsTriggerEvent && !npsCookie) {
      // console.log("NPSDetector: Conditions met to show NPS survey");
      logNPSTrigger(userActivity.npsTriggerEvent);
      setShowNPSModal(true);
      // console.log("NPSDetector: Setting showNPSModal to true");
      
      // Set cookie using js-cookie or a similar library for better cross-browser support
      document.cookie = 'nps_triggered=true; path=/; max-age=86400'; // Set cookie for 24 hours
      // console.log("NPSDetector: Set nps_triggered cookie");
    } else {
      // console.log("NPSDetector: Conditions not met to show NPS survey");
      // if (!userActivity.npsTriggerEvent) console.log("NPSDetector: No trigger event");
      // if (npsCookie) console.log("NPSDetector: NPS cookie already set");
    }
  }, [userActivity.npsTriggerEvent, npsCookie]);

  const logNPSTrigger = async (event) => {
    if (!user || !user.CustomerID) {
      // console.log("NPSDetector: No user logged in, skipping NPS trigger logging");
      return;
    }

    // console.log("NPSDetector: Logging NPS trigger", event);
    const payload = {
      customer_id: user.CustomerID,
      trigger_event: event,
      workflow_id: workflowTools.length > 0 ? workflowTools[0].id : null,
      is_completed: false
    };

    try {
      const response = await axios.post(process.env.REACT_APP_INSERT_NPS, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
      });
      // console.log("NPSDetector: NPS trigger logged successfully");
      setSurveyId(response.data.survey_id);
    } catch (error) {
      console.error("NPSDetector: Error logging NPS trigger:", error);
    }
  };

  const handleNPSSubmit = async () => {
    // console.log("NPSDetector: Submitting NPS survey", { npsScore, feedback });
    
    if (!user || !user.CustomerID) {
      console.log("NPSDetector: No user logged in, skipping NPS submission");
      return;
    }

    const payload = {
      survey_id: surveyId,
      customer_id: user.CustomerID,
      score: npsScore,
      feedback: feedback,
      trigger_event: userActivity.npsTriggerEvent,
      workflow_id: workflowTools.length > 0 ? workflowTools[0].id : null,
      is_completed: true
    };

    try {
      await axios.post(process.env.REACT_APP_INSERT_NPS, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
      });
      // console.log("NPSDetector: NPS survey submitted successfully");
      onSurveyComplete({ score: npsScore, feedback });
    } catch (error) {
      console.error("NPSDetector: Error submitting NPS survey:", error);
    }

    setShowNPSModal(false);
  };

  const handleCancel = async () => {
    // console.log("NPSDetector: Survey cancelled by user");

    if (!user || !user.CustomerID) {
      // console.log("NPSDetector: No user logged in, skipping NPS cancellation logging");
      return;
    }

    const payload = {
      survey_id: surveyId,
      customer_id: user.CustomerID,
      trigger_event: userActivity.npsTriggerEvent,
      workflow_id: workflowTools.length > 0 ? workflowTools[0].id : null,
      is_completed: false
    };

    try {
      await axios.post(process.env.REACT_APP_INSERT_NPS, payload, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PASS,
        },
      });
      // console.log("NPSDetector: NPS survey cancellation logged successfully");
    } catch (error) {
      console.error("NPSDetector: Error logging NPS survey cancellation:", error);
    }

    setShowNPSModal(false);
  };

  // console.log("NPSDetector: Rendering, showNPSModal:", showNPSModal);

  return (
    <>
      {showNPSModal ? (
        <Modal 
          show={showNPSModal} 
          onClose={handleCancel}
          className="mt-20"
        >
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 mt-16">
          <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-4xl w-full mx-4 flex flex-col">
          <Modal.Header className="border-b border-gray-200 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
            <h3 className="text-xl font-semibold text-white">We'd love your feedback!</h3>
          </Modal.Header>
          <Modal.Body className="bg-white text-gray-800">
            <div className="space-y-6">
              <div>
                <Label htmlFor="nps-score" className="mb-2 block text-lg font-medium text-gray-700">
                  How likely are you to recommend LLMazing to a friend or colleague?
                </Label>
                <div className="flex flex-wrap justify-between items-center mt-4">
                  <span className="text-red-500">Not likely</span>
                  <div className="flex space-x-2">
                    {[...Array(11)].map((_, i) => (
                      <Button
                        key={i}
                        color={npsScore === i ? "primary" : "light"}
                        onClick={() => setNPSScore(i)}
                        className={`${customTheme.button.base} ${npsScore === i ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-indigo-500 hover:text-white transition-colors duration-200`}
                      >
                        {i}
                      </Button>
                    ))}
                  </div>
                  <span className="text-green-500">Very likely</span>
                </div>
              </div>
              <div>
                <Label htmlFor="feedback" className="mb-2 block text-lg font-medium text-gray-700">
                  What's the primary reason for your score?
                </Label>
                <TextInput
                  id="feedback"
                  placeholder="Your feedback helps us improve"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-gray-50">
            <Button
              onClick={handleNPSSubmit}
              className={`${customTheme.button.base} ${customTheme.button.primary} flex items-center`}
            >
              <HiOutlineThumbUp className="mr-2" /> Submit
            </Button>
            <Button
              onClick={handleCancel}
              className={`${customTheme.button.base} ${customTheme.button.secondary} flex items-center`}
            >
              <HiOutlineThumbDown className="mr-2" /> Cancel
            </Button>
          </Modal.Footer>
          </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default NPSDetector;