import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { gapi } from 'gapi-script';
import verifyToken from './verifyToken';
import { Card, Button, Modal } from 'flowbite-react';
import { FaThumbsUp, FaComments, FaUserCheck } from 'react-icons/fa';
import useCookie from '../../context/useCookie';
import axios from 'axios';

const LoginPage = () => {
  // console.log('LoginPage component rendered');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');

  const navigate = useNavigate();
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const userInfo = useCookie('user');
  const user = userInfo ? JSON.parse(userInfo) : null;

  useEffect(() => {
    // console.log('useEffect triggered, user:', user);
    if (user) {
      // console.log('User already signed in, redirecting to dashboard');
      setWelcomeMessage('You are already signed in!');
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    }
  }, [user, navigate]);

  const checkUserExists = async (userInfo) => {
    // console.log('checkUserExists called with userInfo:', userInfo);
    const apiUrl = process.env.REACT_APP_CHECK_USER_EXISTS_URI;
    const username = process.env.REACT_APP_API_USER;
    const password = process.env.REACT_APP_API_PASS;
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);

    // console.log('Attempting API call to:', apiUrl);
    try {
      const response = await axios.post(apiUrl, userInfo, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader,
        },
      });

      // console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      // console.error('Error in checkUserExists:', error);
      throw error;
    }
  };

  const handleLoginSuccess = async (response) => {
    // console.log('Google login successful, response:', response);
    const { credential } = response;

    if (!credential) {
      // console.error('No credential received from Google');
      return;
    }

    try {
      const decoded = verifyToken(credential);
      // console.log('Decoded Google token:', decoded);

      const userInfo = {
        firstName: decoded.given_name || 'None',
        lastName: decoded.family_name || 'None',
        email: decoded.email || 'None',
        phone: 'None',
        address: 'None',
        birthdate: 'None',
        gender: 'None',
        country: 'None',
        city: 'None',
        postalCode: 'None',
        picture: decoded.picture || 'None'
      };

      // console.log('Checking user existence with backend');
      const userData = await checkUserExists(userInfo);
      // console.log('Backend response:', userData);
      
      if (userData.status === 'subscribed') {
        // console.log('User is subscribed, setting welcome message');
        setWelcomeMessage('Welcome back!');
        setTimeout(() => {
          // console.log('Setting cookies and redirecting to dashboard');
          Cookies.set('token', credential);
          Cookies.set('user', JSON.stringify(decoded));
          navigate('/dashboard');
        }, 2000);
      } else if (userData.status === 'new') {
        // console.log('New user, showing registration modal');
        setShowRegistrationModal(true);
      } else {
        // console.error('Unexpected status from backend:', userData.status);
        setErrorMessage('Failed to login. Please try again.');
      }
    } catch (error) {
      // console.error('Error in handleLoginSuccess:', error);
      setErrorMessage('Failed to login. Please try again.');
    }
  };

  const handleLoginFailure = (error) => {
    // console.error('Google login failed:', error);
    if (error.error === 'popup_blocked_by_browser') {
      alert('Popup blocked by the browser. Please allow popups for this site.');
    } else {
      setErrorMessage('Failed to login. Please try again.');
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // console.log('Form submitted with email:', email);

    try {
      // console.log('Checking user existence');
      const userData = await checkUserExists({ email });
      // console.log('User data received:', userData);

      if (userData && userData.status === 'subscribed') {
        // console.log('User is subscribed, setting cookies and redirecting');
        Cookies.set('user', JSON.stringify({ email }));
        Cookies.set('prompts_today', parseInt(userData.prompts_today));
        Cookies.set('prompts_left', parseInt(userData.prompts_left));
        navigate('/dashboard');
      } else {
        // console.log('User not found or not subscribed');
        setErrorMessage('User not found. Please register.');
      }
    } catch (error) {
      // console.error('Error in handleFormSubmit:', error);
      setErrorMessage('Failed to login. Please try again.');
    }
  };

  const fetchTasksAndEvents = (token) => {
    // console.log('Fetching tasks and events');
    gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: process.env.REACT_APP_API_KEY,
        clientId: process.env.REACT_APP_CLIENT_ID,
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/tasks/v1/rest',
          'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
        ],
        scope: 'https://www.googleapis.com/auth/tasks.readonly https://www.googleapis.com/auth/calendar.readonly',
      });

      gapi.client.setToken({ 'access_token': token });

      // Fetch tasks
      // console.log('Fetching tasks');
      const taskList = await gapi.client.tasks.tasklists.list();
      const tasks = await Promise.all(taskList.result.items.map(async (taskList) => {
        const tasks = await gapi.client.tasks.tasks.list({ tasklist: taskList.id });
        return tasks.result.items.filter(task => task.due && new Date(task.due).toDateString() === new Date().toDateString());
      }));

      // Fetch events
      // console.log('Fetching events');
      const today = new Date().toISOString().split('T')[0];
      const events = await gapi.client.calendar.events.list({
        calendarId: 'primary',
        timeMin: new Date(today).toISOString(),
        timeMax: new Date(today + 'T23:59:59').toISOString(),
        singleEvents: true,
        orderBy: 'startTime'
      });

      // console.log('Tasks fetched:', tasks.flat());
      // console.log('Events fetched:', events.result.items);
      setTasks(tasks.flat());
      setEvents(events.result.items);
    });
  };

  const RegistrationModal = ({ show, onClose }) => {
    // console.log('Rendering RegistrationModal, show:', show);
    return (
      <Modal show={show} onClose={onClose}>
        <Modal.Header>Thank you for your registration!</Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <p className="text-center">
              <FaThumbsUp className="inline-block mr-2 text-green-500" />
              We're excited to have you on board!
            </p>
            <p>
              Our web app is designed to help you boost your productivity. Don't hesitate to explore all the tools we offer.
            </p>
            <p>
              <FaComments className="inline-block mr-2 text-blue-500" />
              Your feedback is valuable to us. Please let us know if there are any tools you'd like us to develop.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Let's get started!</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (user) {
    // console.log('User already signed in, rendering signed-in message');
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md p-6">
          <div className="text-center">
            <FaUserCheck className="mx-auto text-6xl text-green-500 mb-4" />
            <h2 className="text-2xl font-semibold mb-4">You are already signed in!</h2>
            <p className="mb-4">Redirecting to dashboard...</p>
            <Button onClick={() => navigate('/dashboard')}>Go to Dashboard</Button>
          </div>
        </Card>
      </div>
    );
  }

  // console.log('Rendering login form');
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md p-6">
          <h2 className="text-2xl font-semibold text-center mb-4">Login to LLMazing.online</h2>
          <form onSubmit={handleFormSubmit} className="space-y-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="block w-full p-2.5 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="block w-full p-2.5 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
            />
            <Button type="submit" className="w-full">Login</Button>
            {errorMessage && <p className="text-red-600 text-center mt-2">{errorMessage}</p>}
            <p className="text-center">
              Don't have an account? <Link to="/register" className="text-blue-600">Register here</Link>
            </p>
          </form>
          <div className="flex justify-center mt-4">
            <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} />
          </div>
          <p className="text-center mt-4">
            For demonstration purposes, you can <Link to="/dashboard" className="text-blue-600">go to the dashboard</Link> without logging in.
          </p>
        </Card>
        <div className="mt-8">
          <h3 className="text-xl font-bold text-center">Today's Tasks</h3>
          <ul className="list-disc list-inside">
            {tasks.map((task, index) => (
              <li key={index}>{task.title}</li>
            ))}
          </ul>
          <h3 className="text-xl font-bold text-center mt-4">Today's Events</h3>
          <ul className="list-disc list-inside">
            {events.map((event, index) => (
              <li key={index}>{event.summary} at {event.start.dateTime || event.start.date}</li>
            ))}
          </ul>
        </div>
        {welcomeMessage && (
          <div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center">
            {welcomeMessage}
          </div>
        )}
        <RegistrationModal 
          show={showRegistrationModal} 
          onClose={() => {
            // console.log('Closing registration modal and redirecting to dashboard');
            setShowRegistrationModal(false);
            navigate('/dashboard');
          }} 
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;