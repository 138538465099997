import React, { useState, useEffect } from 'react';
import { Card, Button, TextInput, Textarea, Avatar, Badge } from 'flowbite-react';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import { HiPencil, HiSave, HiX } from 'react-icons/hi';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [editing, setEditing] = useState(false);
  const [toast, setToast] = useState(null);

  useEffect(() => {
    const userInfo = Cookies.get('user');
    if (userInfo) {
      setUser(JSON.parse(userInfo));
    }
  }, []);

  const handleSave = () => {
    // Here you would typically send the updated user data to your backend
    Cookies.set('user', JSON.stringify(user));
    setEditing(false);
    showToast('Profile updated successfully!');
  };

  const showToast = (message) => {
    setToast(message);
    setTimeout(() => setToast(null), 3000);
  };

  if (!user) return <div className="text-white">Loading...</div>;

  return (
    <motion.div 
      className="p-6 min-h-screen text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold mb-6">User Profile</h1>
      
      <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
        <div className="flex items-center mb-4">
          <Avatar img={user.picture} size="xl" rounded />
          <div className="ml-4">
            <h2 className="text-2xl font-bold">{user.name}</h2>
            <p className="text-gray-300">{user.email}</p>
          </div>
        </div>
      </Card>

      <Card className="mt-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
        <h2 className="text-xl font-bold mb-4">Preferences</h2>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span>Receive email updates</span>
            <input 
              type="checkbox" 
              checked={user.receiveEmails} 
              onChange={(e) => setUser({...user, receiveEmails: e.target.checked})}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </div>
          <div className="flex justify-between items-center">
            <span>Participate in research studies</span>
            <input 
              type="checkbox" 
              checked={user.participateInStudies} 
              onChange={(e) => setUser({...user, participateInStudies: e.target.checked})}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </div>
        </div>
      </Card>

      {toast && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded shadow-lg"
        >
          {toast}
        </motion.div>
      )}
    </motion.div>
  );
};

export default Profile;