import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogList from './BlogList';
import BlogPost from './BlogPost';
import BlogTagList from './BlogTagList';

const BlogRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BlogList />} />
      <Route path="/:slug" element={<BlogPost />} />
      <Route path="/tag/:tag" element={<BlogTagList />} />
    </Routes>
  );
};

export default BlogRoutes;