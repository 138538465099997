import React, { useState, useMemo, useContext, useRef, useEffect } from 'react';
import { useSessionLogsContext } from '../../context/SessionLogsContext';
import { ToolsContext } from '../../context/ToolsContext';
import { motion } from 'framer-motion';
import { customTheme } from '../../customTheme';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { Card, Button, Badge } from 'flowbite-react';
import { HiOutlineClipboardCopy, HiOutlineDocumentDownload } from 'react-icons/hi';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import mermaid from 'mermaid';

const Mermaid = ({ chart }) => {
  const mermaidRef = useRef(null);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
      flowchart: { useMaxWidth: false },
    });
    mermaid.contentLoaded();
  }, []);

  useEffect(() => {
    if (chart && mermaidRef.current) {
      mermaid.render('mermaid', chart, (svgCode) => {
        mermaidRef.current.innerHTML = svgCode;
      });
    }
  }, [chart]);

  return <div ref={mermaidRef} className="mermaid"></div>;
};

const UsageHistoryDashboard = () => {
  const { sessionLogs, loading, error } = useSessionLogsContext();
  const { tools } = useContext(ToolsContext);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [editedResults, setEditedResults] = useState({});
  const location = useLocation();
  const logoUrl = `${window.location.origin}/images/logo-dots-no-back.png`;
  const coverLogoUrl = `${window.location.origin}/images/logo_word_cover.png`;
  const footerLogoUrl = `${window.location.origin}/images/logo_word_footer.png`;

  const groupedLogs = useMemo(() => {
    if (!Array.isArray(sessionLogs) || sessionLogs.length === 0) {
      return {};
    }
    const grouped = sessionLogs.reduce((acc, log) => {
      if (!acc[log.usecase]) {
        acc[log.usecase] = [];
      }
      acc[log.usecase].push(log);
      return acc;
    }, {});
    Object.keys(grouped).forEach(usecase => {
      grouped[usecase].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    });
    return grouped;
  }, [sessionLogs]);

  const sortedUseCases = useMemo(() => {
    return Object.keys(groupedLogs).sort((a, b) => a.localeCompare(b));
  }, [groupedLogs]);

  const totalRecords = useMemo(() => {
    return Object.values(groupedLogs).reduce((total, logs) => total + logs.length, 0);
  }, [groupedLogs]);

  const handleContentChange = (logId, newContent) => {
    setEditedResults(prev => ({
      ...prev,
      [logId]: newContent
    }));
  };

  const handleCopyToClipboard = (content, logId) => {
    const contentToCopy = editedResults[logId] || content;
    navigator.clipboard.writeText(contentToCopy)
      .then(() => {
        // Successfully copied
      })
      .catch(err => {
        // Handle error
      });
  };

  const handleSaveAsWord = (content, useCase, logId) => {
    const editedContent = editedResults[logId] || content;
    const html = DOMPurify.sanitize(marked.parse(editedContent));
    const coverPage = `
      <div style="height: 100vh; position: relative; background-color: #130f40;">
        <img src="${coverLogoUrl}" alt="Logo" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 80%; max-width: 400px;">
      </div>
    `;
    const secondPage = `
      <div style="height: 100vh; position: relative; padding: 40px;">
        <h1 style="font-size: 36px; color: #FF1493; margin-top: 60px; margin-bottom: 10px;">${useCase.toUpperCase()}</h1>
        <p style="font-size: 18px; color: #333; margin-bottom: 20px;">RESULT TAILORED FOR YOU</p>
      </div>
    `;
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <style>
          @page { margin: 1in; }
          body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
          h1, h2, h3, h4, h5, h6 { margin-top: 20px; margin-bottom: 10px; }
          p { margin-bottom: 10px; }
          ul, ol { margin-bottom: 10px; padding-left: 20px; }
          table { border-collapse: collapse; margin-bottom: 10px; }
          th, td { border: 1px solid #ddd; padding: 8px; }
          pre { background-color: #f4f4f4; padding: 10px; border-radius: 4px; white-space: pre-wrap; }
          code { background-color: #f4f4f4; padding: 2px 4px; border-radius: 4px; }
          .page-break { page-break-before: always; }
          .content { padding: 40px; }
        </style>
      </head>
      <body>
        ${coverPage}
        <div class="page-break"></div>
        ${secondPage}
        <div class="page-break"></div>
        <div class="content">
          ${html}
        </div>
      </body>
      </html>
    `;
    const footer = `
      <div style="display: flex; justify-content: space-between; align-items: center; font-size: 10px;">
        <div>
          <img src="${footerLogoUrl}" alt="Logo" style="height: 15px; margin-right: 5px; vertical-align: middle;">
          Powered by LLMazing - Your productivity pal, www.llmazing.online
        </div>
        <div>Page <span style="mso-field-code: PAGE"></span> of <span style="mso-field-code: NUMPAGES"></span></div>
      </div>
    `;
    const blob = htmlDocx.asBlob(htmlContent, { footer: footer });
    saveAs(blob, `${useCase}_result.docx`);
  };

  const countWordsAndCharacters = (text) => {
    const words = text.trim().split(/\s+/).length;
    const characters = text.length;
    return { words, characters };
  };

  const renderContent = (content, useCase) => {
    const match = content.match(/^{{(.+?),(.+)}}$/s);
    if (match) {
      const [, model, result] = match;
      const trimmedResult = result.trim().replace(/^(\d+\.\s*)?(\*\*[^*]+\*\*:)?\s*/, '');
      const tool = tools.find(t => t.name === useCase);
      
      if (tool && tool.template === 'graph_creator') {
        try {
          const mermaidScript = trimmedResult.match(/```mermaid\n([\s\S]*?)```/)[1];
          return (
            <>
              <Badge color="indigo" style={{color: customTheme.colors.darkblue}} className="mb-2">Model: {model}</Badge>
              <div className="bg-white bg-opacity-20 p-4 rounded-lg mb-4">
                <Mermaid chart={mermaidScript} />
              </div>
            </>
          );
        } catch (error) {
          console.error('Failed to render Mermaid diagram:', error);
        }
      }
      
      return (
        <>
          <Badge color="indigo" style={{color: customTheme.colors.darkblue}} className="mb-2">Model: {model}</Badge>
          <div className="bg-white bg-opacity-20 p-4 rounded-lg mb-4">
            <ReactMarkdown className="prose prose-sm max-w-none text-gray-800">
              {trimmedResult}
            </ReactMarkdown>
          </div>
        </>
      );
    } else {
      const trimmedContent = content.trim().replace(/^(\d+\.\s*)?(\*\*[^*]+\*\*:)?\s*/, '');
      return (
        <div className="bg-white bg-opacity-20 p-4 rounded-lg mb-4">
          <ReactMarkdown className="prose prose-sm max-w-none text-gray-800">
            {trimmedContent}
          </ReactMarkdown>
        </div>
      );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        <p className="ml-4 text-white">Loading session logs...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500">
        <p>Error: {error}</p>
      </div>
    );
  }

  if (!Array.isArray(sessionLogs) || sessionLogs.length === 0) {
    return (
      <div className={`${customTheme.gradients.coolVibes} min-h-screen p-8`}>
        <h1 className="text-4xl font-bold text-white mb-8 text-center">Usage History</h1>
        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 shadow-lg">
          <p className="text-white text-center">You don't have any usage history yet. Start using our tools to see your history here!</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`${customTheme.gradients.coolVibes} min-h-screen p-8`}>
      <h1 className="text-4xl font-bold text-white mb-2 text-center">Usage History</h1>
      <h2 className="text-2xl text-white mb-8 text-center">Your last {totalRecords} results</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <motion.div
          className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-2xl font-semibold text-white mb-4">Use Cases</h2>
          {sortedUseCases.length > 0 ? (
            <ul className="space-y-2">
              {sortedUseCases.map((useCase, index) => (
                <motion.li
                  key={useCase}
                  className={`cursor-pointer p-2 rounded-md transition-colors duration-200 ${
                    selectedUseCase === useCase ? 'bg-purple-600 text-white' : 'text-gray-200 hover:bg-purple-500 hover:bg-opacity-50'
                  }`}
                  onClick={() => setSelectedUseCase(useCase)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span className="mr-2 text-sm">{index + 1}.</span>
                  {useCase}
                  <span className="ml-2 text-sm">({groupedLogs[useCase].length})</span>
                </motion.li>
              ))}
            </ul>
          ) : (
            <p className="text-white">No use cases found.</p>
          )}
        </motion.div>
        <motion.div
          className="md:col-span-2 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-2xl font-semibold text-white mb-4">Session Logs</h2>
          {selectedUseCase ? (
            <div className="space-y-4">
              {groupedLogs[selectedUseCase].map((log) => (
                <Card key={log.id} className="mb-4 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg">
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-black">{new Date(log.timestamp).toLocaleString()}</span>
                  </div>
                  {renderContent(log.generated_texts, log.usecase)}
                  <div className="flex flex-wrap gap-2 mb-4">
                    <Button color="light" onClick={() => handleCopyToClipboard(log.generated_texts, log.id)}>
                      <HiOutlineClipboardCopy className="mr-2" /> Copy to Clipboard
                    </Button>
                    <Button color="light" onClick={() => handleSaveAsWord(log.generated_texts, log.usecase, log.id)}>
                      <HiOutlineDocumentDownload className="mr-2" /> Save as Word
                    </Button>
                  </div>
                  <p className="text-sm text-gray-600 mb-4">
                    Word count: {countWordsAndCharacters(log.generated_texts).words},
                    Character count: {countWordsAndCharacters(log.generated_texts).characters}
                  </p>
                </Card>
              ))}
            </div>
          ) : (
            <p className="text-gray-300">Select a use case to view session logs</p>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default UsageHistoryDashboard;