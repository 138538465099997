import React, { useState } from 'react';
import { Card, Button, TextInput, Textarea, Badge, Modal, Label } from 'flowbite-react';
import { HiPlus, HiPencil, HiTrash, HiCheck, HiX } from 'react-icons/hi';
import { useContexts } from '../../context/ContextsContext';

const ContextManager = () => {
  const { 
    contexts, 
    groups, 
    associations,
    loading, 
    error, 
    createContext, 
    updateContext, 
    deleteContext,
    createGroup,
    updateGroup,
    deleteGroup,
    associateContextWithGroup,
    disassociateContextFromGroup,
  } = useContexts();

  const [showContextModal, setShowContextModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [currentContext, setCurrentContext] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  if (loading) return <div className="text-white">Loading...</div>;

  if (error) {
    return (
      <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg mb-6 p-6 rounded-lg shadow-lg">
        <p className="text-white mb-4">{error}</p>
        <Button onClick={() => setShowContextModal(true)} className="bg-blue-500 hover:bg-blue-600 text-white">
          <HiPlus className="mr-2" /> Add New Context
        </Button>
        <Button onClick={() => setShowGroupModal(true)} className="bg-blue-500 hover:bg-blue-600 text-white ml-4">
          <HiPlus className="mr-2" /> Add New Group
        </Button>
      </Card>
    );
  }

  const associatedGroupIds = new Set(associations.map(a => a.group_id));
  const associatedGroups = groups.filter(g => associatedGroupIds.has(g.id));

  return (
    <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg mb-6 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-white">Context Manager</h2>
      
      {contexts.length === 0 ? (
        <div className="text-white mb-4">
          <p>You haven't added any contexts yet. Start by adding one!</p>
          <Button onClick={() => { setCurrentContext(null); setShowContextModal(true); }} className="mt-2 bg-blue-500 hover:bg-blue-600 text-white">
            <HiPlus className="mr-2" /> Add New Context
          </Button>
        </div>
      ) : (
        <>
          <h3 className="text-xl font-bold mt-8 mb-4 text-white">Contexts</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {contexts.map(context => (
              <Card key={context.id} className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-3 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-white mb-1">{context.name}</h3>
                <p className="text-sm text-gray-300 mb-2">{context.description}</p>
                <p className="text-sm text-white mb-2 overflow-hidden text-ellipsis" style={{display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical'}}>
                  {context.content}
                </p>
                <div className="flex justify-end space-x-2">
                  <Button size="xs" onClick={() => { setCurrentContext(context); setShowContextModal(true); }} className="bg-blue-500 hover:bg-blue-600 text-white">
                    <HiPencil className="mr-1" /> Edit
                  </Button>
                  <Button size="xs" color="failure" onClick={() => deleteContext(context.id)}>
                    <HiTrash className="mr-1" /> Delete
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        </>
      )}

      {groups.length === 0 ? (
        <div className="text-white mt-8 mb-4">
          <p>You haven't added any context groups yet. Start by adding one!</p>
          <Button onClick={() => { setCurrentGroup(null); setShowGroupModal(true); }} className="mt-2 bg-blue-500 hover:bg-blue-600 text-white">
            <HiPlus className="mr-2" /> Add New Group
          </Button>
        </div>
      ) : (
        <>
          <h3 className="text-xl font-bold mt-8 mb-4 text-white">Context Groups</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {groups.map(group => (
              <Card key={group.id} className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-3 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-white mb-1">{group.name}</h3>
                <p className="text-sm text-gray-300 mb-2">{group.description}</p>
                <div className="mb-2">
                  <h4 className="text-white font-semibold text-sm">Associated Contexts:</h4>
                  <div className="flex flex-wrap">
                    {associations
                      .filter(assoc => assoc.group_id === group.id)
                      .map(assoc => {
                        const context = contexts.find(c => c.id === assoc.context_id);
                        return context ? (
                          <Badge key={context.id} className="mr-1 mb-1 bg-blue-500">
                            {context.name}
                            <button onClick={() => disassociateContextFromGroup(context.id, group.id)} className="ml-1 text-xs">×</button>
                          </Badge>
                        ) : null;
                      })}
                  </div>
                </div>
                <div className="flex justify-end space-x-2">
                  <Button size="xs" onClick={() => { setCurrentGroup(group); setShowGroupModal(true); }} className="bg-blue-500 hover:bg-blue-600 text-white">
                    <HiPencil className="mr-1" /> Edit
                  </Button>
                  <Button size="xs" color="failure" onClick={() => deleteGroup(group.id)}>
                    <HiTrash className="mr-1" /> Delete
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        </>
      )}

      <div className="mt-6 flex space-x-4">
        <Button onClick={() => { setCurrentContext(null); setShowContextModal(true); }} className="bg-blue-500 hover:bg-blue-600 text-white">
          <HiPlus className="mr-2" /> Add New Context
        </Button>
        <Button onClick={() => { setCurrentGroup(null); setShowGroupModal(true); }} className="bg-blue-500 hover:bg-blue-600 text-white">
          <HiPlus className="mr-2" /> Add New Group
        </Button>
      </div>

      <ContextModal
        show={showContextModal}
        onClose={() => setShowContextModal(false)}
        onSave={(contextData) => {
          if (contextData.id) {
            updateContext(contextData.id, contextData);
          } else {
            createContext(contextData);
          }
          setShowContextModal(false);
        }}
        context={currentContext}
        groups={groups}
        associations={associations}
      />

      <GroupModal
        show={showGroupModal}
        onClose={() => setShowGroupModal(false)}
        onSave={(groupData) => {
          if (groupData.id) {
            updateGroup(groupData.id, groupData);
          } else {
            createGroup(groupData);
          }
          setShowGroupModal(false);
        }}
        group={currentGroup}
        contexts={contexts}
        associations={associations}
        associateContextWithGroup={associateContextWithGroup}
        disassociateContextFromGroup={disassociateContextFromGroup}
      />
    </Card>
  );
};

const ContextModal = ({ show, onClose, onSave, context, groups, associations }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');

  React.useEffect(() => {
    if (context) {
      setName(context.name);
      setDescription(context.description);
      setContent(context.content);
    } else {
      setName('');
      setDescription('');
      setContent('');
    }
  }, [context]);

  const handleSave = (e) => {
    e.preventDefault();
    onSave({
      id: context ? context.id : null,
      name,
      description,
      content,
    });
  };

  const isAssociated = (groupId) => {
    return associations.some(a => a.context_id === context?.id && a.group_id === groupId);
  };

  return (
    <Modal show={show} onClose={onClose} size="md">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-md w-full mx-4">
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-4 px-6">
            <h3 className="text-xl font-semibold">{context ? 'Edit Context' : 'Add New Context'}</h3>
          </div>
          <div className="p-6">
            <form onSubmit={handleSave} className="space-y-4">
              <div>
                <Label htmlFor="name" className="text-sm font-medium text-gray-700 mb-1 block">Name</Label>
                <TextInput
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                />
              </div>
              <div>
                <Label htmlFor="description" className="text-sm font-medium text-gray-700 mb-1 block">Description</Label>
                <TextInput
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                />
              </div>
              <div>
                <Label htmlFor="content" className="text-sm font-medium text-gray-700 mb-1 block">Content</Label>
                <Textarea
                  id="content"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                  rows={4}
                />
              </div>
              {context && (
                <div>
                  <Label className="text-sm font-medium text-gray-700 mb-1 block">Associated Groups</Label>
                  <div className="max-h-[30vh] overflow-y-auto">
                    {groups.map(group => (
                      <div key={group.id} className="flex items-center justify-between mb-2 p-2 bg-gray-100 rounded">
                        <span className="text-sm text-gray-700">{group.name}</span>
                        {isAssociated(group.id) ? (
                          <HiCheck className="text-green-500" />
                        ) : (
                          <HiX className="text-gray-400" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex justify-end space-x-2">
                <Button onClick={onClose} className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                  Cancel
                </Button>
                <Button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const GroupModal = ({ show, onClose, onSave, group, contexts, associations, associateContextWithGroup, disassociateContextFromGroup }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  React.useEffect(() => {
    if (group) {
      setName(group.name);
      setDescription(group.description);
    } else {
      setName('');
      setDescription('');
    }
  }, [group]);

  const handleSave = (e) => {
    e.preventDefault();
    onSave({
      id: group ? group.id : null,
      name,
      description
    });
  };

  const isAssociated = (contextId) => {
    return associations.some(a => a.group_id === group?.id && a.context_id === contextId);
  };

  const toggleAssociation = (contextId) => {
    if (isAssociated(contextId)) {
      disassociateContextFromGroup(contextId, group.id);
    } else {
      associateContextWithGroup(contextId, group.id);
    }
  };

  return (
    <Modal show={show} onClose={onClose} size="md">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-md w-full mx-4">
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-4 px-6">
            <h3 className="text-xl font-semibold">{group ? 'Edit Group' : 'Add New Group'}</h3>
          </div>
          <div className="p-6">
            <form onSubmit={handleSave} className="space-y-4">
              <div>
                <Label htmlFor="name" className="text-sm font-medium text-gray-700 mb-1 block">Name</Label>
                <TextInput
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                />
              </div>
              <div>
                <Label htmlFor="description" className="text-sm font-medium text-gray-700 mb-1 block">Description</Label>
                <TextInput
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                />
              </div>
              {group && (
                <div>
                  <Label className="text-sm font-medium text-gray-700 mb-1 block">Associated Contexts</Label>
                  <div className="max-h-[40vh] overflow-y-auto">
                    {contexts.map(context => (
                      <div key={context.id} className="flex items-center justify-between mb-2 p-2 bg-gray-100 rounded">
                        <span className="text-sm text-gray-700">{context.name}</span>
                        <Button
                          size="xs"
                          onClick={() => toggleAssociation(context.id)}
                          className={isAssociated(context.id) ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"}
                        >
                          {isAssociated(context.id) ? 'Remove' : 'Add'}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex justify-end space-x-2">
                <Button onClick={onClose} className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                  Cancel
                </Button>
                <Button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContextManager;