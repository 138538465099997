// src/components/common/DataPrivacy.js

import React from 'react';

const DataPrivacy = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div style={{ textAlign: 'left' }}>
      <h1>Data Privacy Policy for LLMazing.online</h1>
      <p>Effective Date: {currentYear}, Last Updated: 8th of May 2024</p>
      <h2>Introduction</h2>
      <p>At LLMazing.online, we are committed to protecting your personal data and ensuring your privacy is safeguarded. This Data Privacy Policy outlines our practices regarding the collection, use, and sharing of your personal information, how we protect your data, and your rights in relation to your personal data. Please read this policy carefully to understand our practices and how we handle data.</p>
      
      <h2>1. Introduction</h2>
      <p>We collect, store, and process personal data when you visit or interact with LLMazing.online ("Service"). By using our Service, you consent to the collection and use of information in accordance with this policy.</p>
      
      <h2>2. Information We Collect</h2>
      <p>We may collect and process the following data about you:</p>
      <ul>
        <li><strong>Identity Data:</strong> Includes your name, username, or similar identifier.</li>
        <li><strong>Contact Data:</strong> Includes email address and telephone number.</li>
        <li><strong>Technical Data:</strong> Includes internet protocol (IP) address, your login data, browser type and version, time zone setting, and operating system and platform.</li>
        <li><strong>Usage Data:</strong> Includes information about how you use our Service, products, and services.</li>
        <li><strong>Marketing and Communications Data:</strong> Includes your preferences in receiving marketing from us and your communication preferences.</li>
      </ul>
      <p>We do not collect any Special Categories of Personal Data about you (this includes details about race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offenses.</p>
      
      <h2>3. How We Use Your Data</h2>
      <p>We will only use your personal data when the law allows us to. Most commonly, we will use your data:</p>
      <ul>
        <li>To register you as a new user.</li>
        <li>To manage our Service, including identifying you when you sign in.</li>
        <li>To ensure content from our Service is presented most effectively for you and your device.</li>
        <li>To notify you about changes to our Service.</li>
        <li>To provide customer support or respond to your requests.</li>
        <li>To evaluate the effectiveness of our marketing and consider ways (including new products, services, and options) to improve your corner of the internet.</li>
      </ul>
      
      <h2>4. Data Security</h2>
      <p>We have implemented appropriate technical and organizational measures to ensure a level of security appropriate to the risk of loss or unauthorized processing of personal data. All information you provide to us is stored on our secure servers. We limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will process your personal data on our instructions and are subject to a duty of confidentiality.</p>
      
      <h2>5. Data Retention</h2>
      <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Once the retention period has expired, your data will be securely deleted.</p>
      
      <h2>6. Your Legal Rights</h2>
      <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data:</p>
      <ul>
        <li><strong>Access:</strong> Request access to your personal data.</li>
        <li><strong>Correction:</strong> Request correction of your personal data that we hold.</li>
        <li><strong>Erasure:</strong> Ask us to delete or remove your personal data.</li>
        <li><strong>Object:</strong> Object to processing of your personal data.</li>
        <li><strong>Restrict:</strong> Request the restriction of processing your personal data.</li>
        <li><strong>Transfer:</strong> Request the transfer of your personal data to another party.</li>
        <li><strong>Withdraw Consent:</strong> Withdraw consent at any time where we are relying on consent to process your personal data.</li>
      </ul>
      <p>If you wish to exercise any of the rights set out above, please contact us at arsen.apostolov@gmail.com.</p>
      
      <h2>7. Data Protection Officer (DPO)</h2>
      <p>Our Data Protection Officer is responsible for overseeing questions in relation to this privacy notice. If you have any concerns about this policy or our data protection practices, please contact our DPO at data@llmazing.online.</p>
      
      <h2>8. Changes to This Privacy Policy</h2>
      <p>We reserve the right to update this privacy policy at any time. We will provide you with notice of any significant changes by stating the effective date of any modifications and where those changes can be reviewed. Your continued use of the Service after any such changes constitutes your acceptance of the new terms.</p>
      
      <h2>9. Contact Us</h2>
      <p>If you have any questions regarding this Data Privacy Policy or if you have any concerns about your personal data, please contact us using the following details:</p>
      <p>Email: hello@llmazing.online</p>
      
      <h2>10. Compliance with Laws</h2>
      <p>LLMazing.online complies with the laws of the European Union and other jurisdictions from which you may access our Service. We adhere to the principles of the General Data Protection Regulation (GDPR) where applicable.</p>
      
      <p>This Data Privacy Policy was last updated on {new Date().toLocaleDateString()}.</p>
    </div>
  );
};

export default DataPrivacy;
