// src/components/common/TermsAndConditions.js

import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <h1>Terms and Conditions of Use for LLMazing.online</h1>
      <p>Last Updated: 8th of May 2024</p>
      <h2>Introduction</h2>
      <p>Welcome to LLMazing.online (the 'Service'). The Service is an online platform designed to explore the use cases of Large Language Models (LLMs) in business scenarios. By accessing or using the Service, you agree to be bound by these Terms and Conditions of Use ('Terms'), as well as our Privacy Policy, which is hereby incorporated by reference. If you do not agree to these Terms, please do not use the Service.</p>
      <h2>1. Acceptance of Terms</h2>
      <p>a. By accessing or using the Service, you confirm your acceptance of these Terms and our Privacy Policy.</p>
      <p>b. We reserve the right, at our sole discretion, to modify or replace these Terms at any time. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
      <h2>2. Description of Service</h2>
      <p>The Service allows users to explore LLM use cases and create system prompts for business applications. Users can utilize pre-defined prompts or generate their own. The Service is intended for informational and educational purposes only.</p>
      <h2>3. User Accounts</h2>
      <p>a. To access certain features of the Service, you must register for an account by providing your email address.</p>
      <p>b. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device. We will not be liable for any loss or damage arising from your failure to comply with this obligation.</p>
      <h2>4. Use of Service</h2>
      <p>a. The Service is provided for free, but registration is required.</p>
      <p>b. You may use the Service only as permitted by these Terms and applicable laws. Any other use of the Service is prohibited.</p>
      <p>c. You agree not to: (i) modify, copy, or distribute the Service or its content; (ii) use the Service for any commercial purpose; (iii) upload any malicious software or harmful data; or (iv) otherwise engage in any activity that interferes with the performance of the Service or infringes the rights of others.</p>
      <h2>5. Limitations on Use</h2>
      <p>a. You may receive a limited number of free credits/prompts each day and each month, up to 5 per day and 50 per month. These limits are in place to manage our server capacity and ensure service availability.</p>
      <p>b. We reserve the right to modify or discontinue, temporarily or permanently, the Service or any part thereof without liability to you.</p>
      <h2>6. Intellectual Property Rights</h2>
      <p>a. The Service and its original content, features, and functionality (including but not limited to software, text, graphics, images, logos, and other intellectual property) are and will remain the exclusive property of LLMazing.online and its licensors.</p>
      <p>b. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any aspect of the Service without express written permission from LLMazing.online.</p>
      <h2>7. User-Generated Content</h2>
      <p>a. You retain all rights in and to your content (including your prompts). By submitting, posting, or displaying content on or through the Service, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in connection with the Service.</p>
      <h2>8. Termination</h2>
      <p>a. We may terminate or suspend your account and bar access to the Service if you fail to comply with these Terms.</p>
      <p>b. Upon termination, your right to use the Service will immediately cease.</p>
      <h2>9. Disclaimer of Warranties</h2>
      <p>a. Your use of the Service is at your sole risk. The service is provided on an 'as is' and 'as available' basis.</p>
      <p>b. We do not warrant that the Service will be uninterrupted, secure, or free from errors.</p>
      <h2>10. Limitation of Liability</h2>
      <p>a. To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses.</p>
      <h2>11. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of European Union, without regard to its conflict of law provisions.</p>
      <h2>12. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will make reasonable efforts to notify users of significant changes, but your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
      <h2>13. Contact Us</h2>
      <p>If you have any questions about these Terms or the Service, please contact us at hello@llmazing.online.</p>
      <p>By using the Service, you acknowledge that you have read these Terms and agree to be bound by them.</p>
    </div>
  );
};

export default TermsAndConditions;
