import React from 'react';
import { motion } from 'framer-motion';
import { HiLightningBolt, HiBriefcase, HiAcademicCap, HiChip } from 'react-icons/hi';
import { customTheme } from '../customTheme';

const AboutUsPage = () => {
  return (
    <div className={`min-h-screen ${customTheme.gradients.coolVibes} text-gray-800 py-16`}>
      <div className="container mx-auto px-4">
        <motion.h1 
          className="text-4xl md:text-5xl font-bold mb-8 text-center text-white"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          About LLMazing
        </motion.h1>
        
        <motion.div 
          className="max-w-3xl mx-auto bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h2 className="text-2xl font-semibold mb-4 flex items-center text-gray-900">
            <HiLightningBolt className="mr-2 text-yellow-500" />
            Our Mission
          </h2>
          <p className="mb-6">
            At LLMazing, we're on a mission to revolutionize productivity through cutting-edge AI technology. We believe in harnessing the power of large language models to create intuitive, efficient tools that empower businesses and individuals to achieve more.
          </p>
          
          <h2 className="text-2xl font-semibold mb-4 flex items-center text-gray-900">
            <HiAcademicCap className="mr-2 text-green-500" />
            Our Expertise
          </h2>
          <ul className="list-disc list-inside mb-6">
            <li>Advanced AI and Machine Learning</li>
            <li>Data Analytics and Insights</li>
            <li>Financial Technology Solutions</li>
            <li>Productivity Tool Development</li>
          </ul>
          
          <h2 className="text-2xl font-semibold mb-4 flex items-center text-gray-900">
            <HiChip className="mr-2 text-purple-500" />
            Our Approach
          </h2>
          <p>
            We combine deep industry knowledge with state-of-the-art AI capabilities to deliver tools that are not just powerful, but also intuitive and user-friendly. Our focus is on creating practical solutions that drive tangible results for our users.
          </p>
        </motion.div>
        
        <motion.div 
          className="text-center mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <p className="text-lg text-white">
            Join us on our journey to redefine productivity in the AI era.
          </p>
          <a 
            href="/contact" 
            className={`inline-block mt-4 px-6 py-3 ${customTheme.button.primary} rounded-full text-lg font-semibold transition-transform duration-300 hover:scale-105`}
          >
            Get in Touch
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUsPage;